import moment, { ManipulateType } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from "dayjs/plugin/utc";
import duration from 'dayjs/plugin/duration';

moment.extend(relativeTime);
moment.extend(utc);
moment.extend(advancedFormat);
moment.extend(duration);

export const DATETIMEFORMAT = "YYYY-MM-DD HH:mm";
export const DATEFORMAT = "YYYY-MM-DD";
export const TIMEFORMAT = "HH:mm:ss";
const isTimeInValid = (time: any) => time === "Invalid Date";

export class TimeHelper {
  static toUTC = (time: string): string => {
    if (!time) {
      return "";
    }
    try {
      const newTime = moment(time)
        .subtract(7, "hour")
        .format("YYYY-MM-DD HH:mm:ss");
      return isTimeInValid(newTime) ? time : newTime;
    } catch (e) {
      return time.toString();
    }
  };

  static getTimeNoOffset = (
    time: string
  ): number => {
    if (!time) {
      return 0;
    }
    const offset = TimeHelper.getOffsetHour();
    return +moment(time).add(-1 * (offset * 2), "hour").format('x');
  };
  static offsetHour : number = 0;
  static getOffsetHour() : number {
    if(this.offsetHour <= 0){
      this.offsetHour = new Date().getTimezoneOffset() / 60;
    }
    return this.offsetHour;
  }

  static toLocal = (
    time: string,
    format: string = "YYYY-MM-DD HH:mm:ss"
  ): string => {
    if (!time) {
      return "";
    }
    try {
      const newTime = moment(time).add(7, "hour").format(format);
      return isTimeInValid(newTime) ? time : newTime;
    } catch (e) {
      return time;
    }
  };

  static addSecondToDateTime = (
    time: string,
    seconds: number,
    format: string = "YYYY-MM-DD HH:mm:ss"
  ): string => {
    if (!time) {
      return "";
    }
    try {
      const newTime = moment(time).add(seconds, "seconds").format(format);
      return isTimeInValid(newTime) ? time : newTime;
    } catch (e) {
      return time;
    }
  };

  static getDateTimeDistanceInSecond = (
    time1: string,
    time2: string
  ): number => {
    try {
      const dateTime1 = moment(time1, 'YYYY-MM-DD HH:mm:ss');
      const dateTime2 = moment(time2, 'YYYY-MM-DD HH:mm:ss');
      return moment.duration(dateTime2.diff(dateTime1)).asSeconds()
    } catch(e) {
      return 0;
    }
    return 0
  }

  static getDay = (time: string): number => {
    if (!time) {
      return 1;
    }
    try {
      return +moment(time, "YYYY-MM-DD HH:mm:ss").format("D");
    } catch (e) {
      return 1;
    }
  };

  static getMonth = (time: string): number => {
    if (!time) {
      return 1;
    }
    try {
      return +moment(time, "YYYY-MM-DD HH:mm:ss").format("M");
    } catch (e) {
      return 1;
    }
  };

  static getShortMonth = (time: string): string => {
    if (!time) {
      return "";
    }
    try {
      return moment(time, "YYYY-MM-DD HH:mm:ss").format("MMM");
    } catch (e) {
      return "";
    }
  };

  static getYear = (time: string): number => {
    if (!time) {
      return 1;
    }
    try {
      return +moment(time, "YYYY-MM-DD HH:mm:ss").format("YYYY");
    } catch (e) {
      return 2563;
    }
  };

  static getShortTimeForTable = (time: string): string => {
    if (!time) {
      return "";
    }
    try {
      const newTime = moment(time).fromNow();
      return isTimeInValid(newTime) ? time : newTime;
    } catch (e) {
      return time;
    }
  };

  static getFullTextFormatDate = (time: string): string => {
    if (!time) {
      return "";
    }
    try {
      const newTime = moment(time).format("MMMM D, YYYY");
      return isTimeInValid(newTime) ? time : newTime;
    } catch (e) {
      return time;
    }
  };

  static getCurrentDate = (): string => {
    const newTime = moment().format("YYYY-MM-DD");
    return isTimeInValid(newTime) ? "" : newTime;
  };

  static getDateFromTime = (
    time: string,
    format: string = "DD/MM/YYYY"
  ): string => {
    if (!time) {
      return "";
    }
    const newTime = moment(time).format(format);
    return isTimeInValid(newTime) ? time : newTime;
  };

  static getDateFormTimeWithLocal = (
    time: string,
    format: string = "DD/MM/YYYY"
  ): string => {
    if (!time) {
      return "";
    }
    const newTime = moment(TimeHelper.toLocal(time)).format(format);
    return isTimeInValid(newTime) ? time : newTime;
  };

  static getISODateTimeFormTime = (time: string): string => {
    if (!time) {
      return "";
    }

    const testTime = moment(time).format("YYYY-MM-DD HH:mm:ss");
    if (isTimeInValid(testTime)) {
      return time;
    }

    const newTime = moment(time).toISOString();
    return isTimeInValid(newTime) || !newTime ? time : newTime;
  };

  static getDateTimeFromDateTime = (time: string): string => {
    if (!time) {
      return "";
    }
    const newTime = moment(time).format("YYYY-MM-DD HH:mm:ss");
    return isTimeInValid(newTime) ? time : newTime;
  };

  static getDateTimeFromTime = (time: string): string => {
    if (!time) {
      return "";
    }
    const dateCurrent = moment().format(DATEFORMAT);
    const newTime = moment(`${dateCurrent} ${time}`).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    return isTimeInValid(newTime) ? time : newTime;
  };

  static getTimeFromTime = (time: string, format: string = "HH:mm"): string => {
    if (!time) {
      return "";
    }
    const newTime = moment(time).format(format);
    return isTimeInValid(newTime) ? time : newTime;
  };

  static isDateTimeValid = (time: string): boolean => {
    if (!time) {
      return false;
    }
    return /^(\d{4})\-(\d{2})\-(\d{2}) (\d{2}):(\d{2})(:\d{2})?$/.test(time);
  };

  static getCurrentDateTime = (): string => {
    const newTime = moment().format("YYYY-MM-DD HH:mm:ss");
    return isTimeInValid(newTime) ? "" : newTime;
  };

  static startDateFillTime = (date): string => {
    if (!date) {
      return date;
    }
    const newTime = moment(date).format(DATEFORMAT);
    return isTimeInValid(newTime) ? date : `${newTime} 17:00:00`;
  };

  static endDateFillTime = (date): string => {
    if (!date) {
      return date;
    }
    const newTime = moment(date).format(DATEFORMAT);
    return isTimeInValid(newTime) ? date : `${newTime} 16:59:59`;
  };

  static isBefore = (date: string, date2: string): boolean =>
    moment(date).isBefore(date2, "minute");

  static isAfter = (date: string, date2: string): boolean =>
    moment(date).isAfter(date2, "minute");

  static isTimeBefore = (time: string, time2: string): boolean =>
    moment(`2022-01-01 ${time}`, TIMEFORMAT).isBefore(
      moment(`2022-01-01 ${time2}`, TIMEFORMAT)
    );

  static isTimeAfter = (time: string, time2: string): boolean =>
    moment(`2022-01-01 ${time}`, TIMEFORMAT).isAfter(
      moment(`2022-01-01 ${time2}`, TIMEFORMAT)
    );

  static getTimeDiffInHour = (date: string, date2: string): number =>
    moment(date).diff(moment(date2), "hour");

  static monthsShortName = (): string[] => [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  static subtractTime = (
    time: string,
    subtrahend: number,
    subtractUnit: ManipulateType,
    resultFormat = "YYYY-MM-DD HH:mm:ss"
  ): string => {
    if (!time) {
      return "";
    }
    if (!subtractUnit) {
      return time.toString();
    }
    try {
      const newTime = moment(time)
        .subtract(subtrahend, subtractUnit)
        .format(resultFormat);
      return isTimeInValid(newTime) ? time : newTime;
    } catch (e) {
      return time.toString();
    }
  };

  static getTimeFromMilis = (millis: number): string => {
    if (!millis) {
      return "";
    }
    const newTime = moment.duration(millis).format("mm:ss");
    var hours = Math.trunc(moment.duration(millis).asHours());
    return isTimeInValid(newTime) ? "" : hours + ':' + newTime;
  }
}
