import {commonObjectState} from "~/state/object";
import {IObjectState} from "~/state/types";
import {Vue} from 'vue-property-decorator'
import ObjectHelper from "~/utils/ObjectHelper";
import {NewRequester} from "~/core/Requester";

interface ICustomerJourneyStatState {
    customerJourneyStatDetail: IObjectState<any>
}

export const prefix = 'customerJourney/customerJourneyStat'

export const state = (): ICustomerJourneyStatState => ({
    customerJourneyStatDetail: commonObjectState(),
})

export const getters = {
    getStatDetail: (state: any) => (): any => {
        return { ...(state.customerJourneyStatDetail.data || {}) }
    },
}

export const mutations = {
    updateStatDetail: (state: ICustomerJourneyStatState, data) => {
        Vue.set(state, 'customerJourneyStatDetail', data)
    },
}

export const actions = {
    getStatDetail: async ({ commit, state }, data) => {
        const path = `updateStatDetail`
        try {
            commit(path, ObjectHelper.toLoadingStatus(state.customerJourneyStatDetail))
            const response = await NewRequester.get<any>(data.url, { ...data })
            commit(path, ObjectHelper.toObjSuccessStatus(state.customerJourneyStatDetail, response.data))
        } catch (e) {
            commit(path, ObjectHelper.toErrorStatus(state.customerJourneyStatDetail, e.response?.data || e))
        }
    },
}
