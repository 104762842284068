
import { Component, Prop, Watch } from "vue-property-decorator";
import BaseInput from "~/core/BaseInput";
import { _isEmpty, _isUndefined } from "~/utils/lodash";
import { TIMEFORMAT, TimeHelper } from "~/utils/TimeHelper";

const DEFAULT_TIME = "00:00:00";

@Component
export default class TimePicker extends BaseInput {
  @Prop({ default: false }) noIcon!: boolean;
  @Prop({ default: undefined }) max!: string;
  @Prop({ default: undefined }) min!: string;
  menu = false;
  time = this.value ? this.toDateTime(this.value) : this.defaultDateTime;
  displayText = this.value;

  get defaultDateTime() {
    return TimeHelper.getDateTimeFromTime(DEFAULT_TIME);
  }

  toDateTime(time) {
    if (_isUndefined(time) || _isEmpty(time)) return this.defaultDateTime;
    return TimeHelper.getDateTimeFromTime(time);
  }

  async onBlur(e, updateF) {
    this.time = await updateF(this.toDateTime(e.target.value));
    this.displayText = this.displayTime(this.time);
    this.onFormatInput(this.time);
  }

  displayTime(time) {
    return TimeHelper.getTimeFromTime(time, TIMEFORMAT);
  }

  onFormatInput(time) {
    const timeFormat = this.displayTime(time);
    this.displayText = timeFormat;
    this.onInput(timeFormat);
  }

  onKeydown(e, updateF) {
    if (e.keyCode === 13) {
      this.onBlur(e, updateF);
    }
  }

  @Watch("value")
  onValueChange() {
    if (!this.value) {
      this.time = this.defaultDateTime;
      this.displayText = "";
    }
  }
}
