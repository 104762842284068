
import { Base } from "~/core/Base";
import { Component, Prop, Provide } from "vue-property-decorator";
import { IFormOption } from "~/components/form/FormTypes";
import { _get, _isUndefined, _isNil } from "~/utils/lodash";
import FieldCondition from "~/components/form/FieldCondition.vue";

@Component({
  components: { FieldCondition },
})
export default class Field extends Base {
  @Prop({ default: [], required: true, type: Array }) readonly options!: IFormOption[];
  @Prop({ type: Object, default: () => ({}) }) readonly value!: any;

  key(idx): any {
    // If props.key is specify, we use key from props.key
    if (this.options && this.options.length > idx && this.options[idx].props?.key) {
      const key = this.options[idx].props?.key
      if (key && key.length > 0) {
        return this.options[idx].props?.key
      }
    }

    return idx
  }

  componentValues(): any {
    const data = {};
    this.options.forEach((item) => {
      if (item.props) {
        // 1. set value to _get(this.value, item.props.name) OR (item.props.defaultValue) or null
        // 2. return data[field_name] = value
        data[item.props.name] = _isUndefined(
          _get(this.value, item.props.name, undefined)
        )
          ? _isUndefined(item.props.defaultValue)
            ? null
            : item.props.defaultValue
          : _get(this.value, item.props.name, null);
      }
    });

    return data;
  }
}
