
import { Component, Prop, Vue } from "vue-property-decorator";
import { _isEmpty, _isUndefined } from "~/utils/lodash";
import { StringHelper } from "~/utils/StringHelper";
import BaseInput from "~/core/BaseInput";

@Component
export default class RandomTokenGenerator extends BaseInput {
  @Prop(String) readonly value!: string;
  @Prop(String) readonly label!: string;
  @Prop({ type: Number, default: 40 }) readonly height!: number;
  @Prop({ type: String, default: "" }) readonly className!: string;

  copyAlert = false;
  alertTimeout = 1000;
  token = "";

  mounted() {
    if (this.value && this.value !== "") {
      this.token = this.value;
    }
  }

  genToken() {
    let regen = true;
    if (this.token && this.token !== "") {
      regen = confirm("Do you want to generate new access token.");
    }

    if (regen) {
      const char =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890@#%!".split(
          ""
        );
      let tokenStr = "";
      let length = 64;

      for (let i = 0; i <= length; i++) {
        const idx = Math.floor(Math.random() * char.length);
        tokenStr += char[idx];
      }

      this.token = tokenStr;
      this.onInput(tokenStr);
    }
  }

  async clickCopy() {
    await StringHelper.copyToClipBoard(this.$props.value);
    this.copyAlert = true;
  }
}
