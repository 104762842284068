
import { Component, Prop, Provide, Vue } from "vue-property-decorator";
import { NoCache } from "~/core/Decorators";
import { IPageOption } from "~/state/types";
import { ITableCardOptions } from "./TableTypes";

@Component
export default class TableCard extends Vue {
  @Prop({}) readonly table!: ITableCardOptions;

  get showSearch(): boolean {
    return this.table?.showSearch || false;
  }

  emitSearch(value) {
    this.$emit("search", value);
  }

  get showExpand(): boolean {
    return this.table.showExpand || false;
  }

  get isSuccess(): boolean {
    return this.table.status.isLoaded && this.table.status.isSuccess;
  }

  get items(): any {
    return this.table.items;
  }

  get options(): IPageOption {
    return this.table.options;
  }

  get itemInRow(): number {
    return this.table.itemInRow ? this.table.itemInRow : 4;
  }

  get itemInRowForLoading(): number {
    return +(this.itemInRow + this.itemInRow / 2).toFixed();
  }

  get cardCols(): string {
    return (12 / this.itemInRow).toFixed();
  }

  get isDataNotAvalible(): boolean {
    const items = this.items || [];
    return this.table.status.isLoaded && items.length === 0;
  }

  get styles(): object {
    const itemInRow = this.itemInRow;
    return {
      "--card-width": `${100 / itemInRow}%`,
    };
  }
}
