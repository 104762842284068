
import { Component, Prop, Provide, Vue } from "vue-property-decorator";
import { IPageOption } from "~/state/types";
import { ITableOptions, ITableHeader } from "./TableTypes";
import { CONFIG } from "~/constants/config";

@Component
export default class Table extends Vue {
  @Prop({}) readonly table!: ITableOptions;

  limitPerPage = CONFIG.LIMIT_PER_PAGE;

  get showSearch(): any {
    return this.table.showSearch && true;
  }

  emitSearch(value) {
    this.$emit("search", value);
  }

  get showExpand(): boolean {
    return this.table.showExpand || false;
  }

  get isLoading(): boolean {
    return this.table.status.isLoading;
  }

  get headers(): ITableHeader[] {
    return this.table.headers;
  }

  get items(): any {
    return this.table.items;
  }

  get options(): IPageOption | undefined {
    return this.table.options;
  }

  get customSlots(): ITableHeader[] {
    return this.headers.filter((h) => h.isCustomSlot);
  }
}
