
import {Component, Prop} from 'vue-property-decorator'
import {Base} from '~/core/Base'
import {IBreadcrumbItemState} from '~/store/types';

@Component
export default class PageHeader extends Base {
  @Prop(String) readonly feature!: string
  @Prop(String) readonly title!: string
  @Prop(String) readonly icon!: string
  @Prop(Array) readonly breadcrumbs!: IBreadcrumbItemState[]
  @Prop(Boolean) readonly backButton!: boolean
  @Prop(String) readonly customBackURL!: string

  get featureName(): string {
    return this.feature ? this.feature : this.$app.state.app.page.feature!
  }

  get titleName(): string {
    return this.title ? this.title : this.$app.state.app.page.title!
  }

  get iconName(): string {
    return this.icon ? this.icon : this.$app.state.app.page.icon!
  }

  get breadcrumbList(): IBreadcrumbItemState[] {
    return this.breadcrumbs ?? this.$app.state.app.page.breadcrumb
  }

  get hideBreadcrumb(): boolean {
    return this.$array.toArray(this.breadcrumbs ?? this.$app.state.app.page.breadcrumb).length <= 1
  }

  back() {
    if (this.customBackURL) {
      this.$router.push(this.customBackURL)
    } else {
      this.$router.go(-1)
    }
  }

  getUniqKeyIndexBreadcrumb(index: number, to: string) {
    return index + (to ?? '')
  }
}
