import {BaseRepository} from '~/repositories/BaseRepository';
import {CookieHelper} from '~/utils/CookieHelper';
import {_get} from '~/utils/lodash';
import {PermissionAction, PermissionPrefix, PermissionType} from '~/repositories/types';
import {IMeState} from '~/store/types';
import PermissionHelper from '~/utils/PermissionHelper';
import {CustomerDatabaseFormPermissionOption} from '~/features/marketing/permission/types';
import {Core} from "~/core/Core"
import Msal from "~/plugins/msal"
import {LocalStorageHelper} from '~/utils/LocalStorageHelper'
import { ObjectLoader } from '~/loader/object';
import { API_TYPE } from '~/constants/apiTypes';
import { IContext } from 'pam4-tracker';
import {IAuth} from "~/features/user/types";
import { TimeHelper } from "~/utils/TimeHelper";
import { SeamanHTTP } from "~/http/SeamanHTTP";
import {ILoaderOption, IFetchOption} from '~/loader/types'

export default class AuthRepository extends BaseRepository {
  get seaman(): SeamanHTTP {
    return new SeamanHTTP();
  }

  get isAuth(): boolean {
    return _get(this.state, 'app.me.token', false)
  }

  get isNotAuth(): boolean {
    return !this.isAuth
  }

  get me(): IMeState | null {
    return this.state?.app?.me || null
  }

  get sidebarPermission(): string[] {
    let perms: string[] = []
    for (const [key,] of Object.entries(this.me?.permissions || {})) {
      const length = key.split('_').length
      if (length === 4) {
        perms = [
          ...perms,
          key
        ]
      }
    }
    return perms
  }

  get customerDatabasePermission(): string[] {
    let perms: string[] = []
    for (const [key,] of Object.entries(this.me?.permissions || {})) {
      const length = key.split('_').length
      if (length === 5) {
        perms = [
          ...perms,
          key
        ]
      }
    }
    return perms
  }

  logout(_vm: IContext | any) {
    if (Core.config.ENABLED_FEATURE_LOGIN_AZURE_AD) {
      Msal.signOut()
    }
    const logoutLoader = new ObjectLoader(_vm, (data: any) => ({
      apiType: API_TYPE.ECOM,
      baseURL: Core.config.AUTHENTICATION_PATH == "ecom"
        ? Core.ECOMCMSAPI("/logout")
        : Core.MKTBACKENDAPI("/logout"),

      onAddSuccess: (newValue) => {
      },
    }))
    
    logoutLoader.$add({})
    CookieHelper.remove('me')
    LocalStorageHelper.set('me', null)

    this.commit('app/replaceState', {key: 'me', data: null});

    this.router.push({
      path: '/login',
      query: {
        ...this.route.query,
        redirect: this.route.path,
      }
    })
  }

  get hasGrantAll(): boolean {
    for (const [key, value] of Object.entries(this.me?.permissions || {})) {
      if (key === 'grant_all' && value) {
        return true
      }
    }

    return false
  }

  hasPermission(key: string, prefix: PermissionPrefix, type?: PermissionType, action?: PermissionAction): boolean {
    if (prefix === PermissionPrefix.CUSTOMER_DATABASE) {

    } else if (prefix === PermissionPrefix.SIDEBAR_PERMISSION) {
      // key in sidebar context mean menu
      const [feature, menu] = PermissionHelper.getSidebarKeys('')
      // PermissionHelper.getSidebarKeys(key)
    }

    return false
  }

  isHideField(key?: string): boolean {
    if (!key) {
      return true
    }
    return !this.canViewField(key) && !this.canEditField(key)
  }

  canViewField(key?: string): boolean {
    if (!key) {
      return true
    }
    const [view, edit] = this.isHasPermission(key)
    return (view || edit) || this.hasGrantAll
  }

  canEditField(key?: string): boolean {
    if (!key) {
      return true
    }
    const [, edit] = this.isHasPermission(key)
    return edit || this.hasGrantAll
  }

  isHasPermission(key: string): [boolean, boolean] /* [view,edit] */ {
    let canView = false
    let canEdit = false

    for (let i = 0; i < this.customerDatabasePermission.length; i++) {
      const perm = this.customerDatabasePermission[i]
      const keys = perm.split('_')
      const attribute = keys[3]
      const action = keys[4]

      if (attribute === key) {
        if (action === CustomerDatabaseFormPermissionOption.VIEW) {
          canView = true
        } else if (action === CustomerDatabaseFormPermissionOption.EDIT) {
          canEdit = true
        }
      }
    }

    return [canView, canEdit]
  }

  loginAD = async (
    _vm: IContext | any,
    data: any
  ): Promise<IAuth> => {
    const headers: { [key: string]: any } = {
      "x-timestamp": TimeHelper.toUTC(TimeHelper.getCurrentDateTime()),
      "x-device": "browser",
    };

    

    const response = await this.seaman.postAndResolveRef(
      Core.MKTBACKENDAPI("/login-by-ad-token"),
      data ?? {},
      headers
    )

    if (response.error) {
      // throw new ResponseError(response.errorCode, response.errorMessage);
    }
    else {
      let tokenData = {
        token: response.data?.token || null,
      }
      // this.commit('app/replaceState', {key: 'me', data: null});
      _vm.$app.replaceState("me", response.data)
      // LocalStorageHelper.set("me", response.data);

      let cookie = JSON.stringify(tokenData);
      _vm.app.$cookies.set("me", cookie)
    }

    return response.data;
  }

  async loginADTokenLoader(_vm: IContext | any,  adToken: string, appID: string) {
    const loginLoader = new ObjectLoader(
      _vm,
      (data?: any): ILoaderOption => ({
        apiType: "MKT",
        baseURL: Core.MKTBACKENDAPI(`/login-by-ad-token`),
        stateKey: "me",

        onAddSuccess: (newValue) => {
          this.commit('app/replaceState', {key: 'me', data: loginLoader.addItem});
          LocalStorageHelper.set("me", loginLoader.addItem);
          let tokenData = {
            token: loginLoader.addItem.token || null,
          };
          let cookie = JSON.stringify(tokenData);
          CookieHelper.set("me", cookie, { expires: 30 });
          console.log('_vm.$route.query.redirect', _vm.$route.query.redirect)
          console.log("path:", _vm.$route.path)
          if (Core.config.ENABLED_SKIP_PAGE_PLATFORM_SELECTION) {
            if (_vm.$route.path === "/") {
              _vm.$router.push({
                path: "/marketing"
              })
            }
          }
          // _vm.$router.push({
          //   path: _vm.$route.query.redirect ? _vm.$route.query.redirect : "/marketing",
          // });
        },

        onAddError: (newValue) => {
          // this.$swal(
          //   UnexpectedError(newValue.errorData?.message) as SweetAlertOptions
          // );
        },
      })
    );

    // console.log('loginLoader', loginLoader)

    // await loginLoader.$get("", {  params: { 
    //   app_id: appID,
    //   ad_token: adToken,
    // }})

    await loginLoader.$add(
      {
        app_id: appID,
        ad_token: adToken,
      }
    );
  }

  get enabledFeatureLoginADToken() {
    let isEnableFeature = Core.config.ENABLED_FEATURE_LOGIN_AD_TOKEN;
    return isEnableFeature;
  }

  
}
