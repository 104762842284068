import { ParamHelper } from "~/utils/ParamHelper";
export const CONFIG = {
  TITLE: "PAM Management System",
  LIMIT_PER_PAGE: 20,
  CMS_API_TOKEN_PREFIX: "pam",
  DEFAULT_ERROR_MSG: "Something went wrong",
  DEFAULT_PRIMARY: "id",
  API_SECRET: process.env.API_SECRET,
  MKT_API: process.env.MKT_API,
  MKT_CMS_API: process.env.MKT_CMS_API,
  MKT_CONNECT_API: process.env.MKT_CONNECT_API,
  ECOM_API: process.env.ECOM_API,
  ECOM_CMS_API: process.env.ECOM_CMS_API,
  CRM_API: process.env.CRM_API,
  PLATFORM_SWITCHER: process.env.PLATFORM_SWITCHER,
  ENABLED_NEW_PLATFORM: process.env.ENABLED_NEW_PLATFORM,
  MKT_BACKEND_API: process.env.MKT_BACKEND_API,
  AUTHENTICATION_PATH: process.env.AUTHENTICATION_PATH,
  PLATFORM_CRM: process.env.PLATFORM_CRM,
  PLATFORM_CHAT_CENTER: process.env.PLATFORM_CHAT_CENTER,
  PLATFORM_SURVEY: process.env.PLATFORM_SURVEY,
  PLATFORM_SAI: process.env.PLATFORM_SAI,
  CRM_FRONT: process.env.CRM_FRONT,
  CUSTOMER_JOURNEY_V2_ENABLED: ParamHelper.getBoolTrue(
    process.env.CUSTOMER_JOURNEY_V2_ENABLED
  ),
  REALTIME_CUSTOMER_JOURNEY_ENABLED: ParamHelper.getBoolTrue(
    process.env.REALTIME_CUSTOMER_JOURNEY_ENABLED
  ),
  MARKETING_THEME: process.env.MARKETING_THEME,
  MARKETING_THEME_FAVICON: process.env.MARKETING_THEME_FAVICON,
  ENABLED_FEATURE_LOGIN_AD_TOKEN: ParamHelper.getBoolTrue(
    process.env.ENABLED_FEATURE_LOGIN_AD_TOKEN
  ),
  ENABLED_SKIP_PAGE_PLATFORM_SELECTION: ParamHelper.getBoolTrue(
    process.env.ENABLED_SKIP_PAGE_PLATFORM_SELECTION
  ),
  ENABLED_FEATURE_DASHBOARD: false,
  ENABLED_FEATURE_PRODUCT: false,
  ENABLED_FEATURE_ORDER: false,
  ENABLED_FEATURE_MEMBER: false,
  ENABLED_FEATURE_OLD_MEMBER: false,
  ENABLED_FEATURE_PROMOTION: false,
  ENABLED_FEATURE_VOUCHER: false,
  ENABLED_FEATURE_BANNER: false,
  ENABLED_FEATURE_CAMPAIGN: false,
  ENABLED_FEATURE_PAGE: false,
  ENABLED_FEATURE_GALLERIES: false,
  ENABLED_FEATURE_PUSH_CAMPAIGN: false,
  ENABLED_FEATURE_REPORT: false,
  ENABLED_FEATURE_SETTING: false,
  ENABLED_FEATURE_ROLE_AND_PERMISSION_SETTINGS: false,
  ENABLED_FEATURE_ORDER_SETTINGS: false,
  ENABLED_FEATURE_FAQ: false,
  ENABLED_FEATURE_STORE: false,
  ENABLED_FEATURE_LOGIN_AZURE_AD: ParamHelper.getBoolTrue(
    process.env.ENABLED_FEATURE_LOGIN_AZURE_AD
  ),
  ENABLED_FEATURE_LOGIN: ParamHelper.getBoolTrue(
    process.env.ENABLED_FEATURE_LOGIN
  ),
  ENABLED_FEATURE_FORGOT_PASSWORD: ParamHelper.getBoolTrue(
    process.env.ENABLED_FEATURE_FORGOT_PASSWORD
  ),
  MSAL_CLIENT_ID: process.env.MSAL_CLIENT_ID,
  MSAL_AUTHORITY: process.env.MSAL_AUTHORITY,
  MSAL_REDIRECT_URI: process.env.MSAL_REDIRECT_URI,
  ENABLED_FEATURE_VISUALIZATIONS_FUNNELS:
    process.env.ENABLED_FEATURE_VISUALIZATIONS_FUNNELS,
  ENABLED_FEATURE_AFFILIATE: ParamHelper.getBoolFalse(
    process.env.ENABLED_FEATURE_AFFILIATE
  ),
  ENABLED_FEATURE_LANDINGPAGE: ParamHelper.getBoolFalse(
    process.env.ENABLED_FEATURE_LANDINGPAGE
  ),
  ENABLED_FEATURE_SMS_PROVIDER_BY_EXTERNAL_API: ParamHelper.getBoolTrue(
    process.env.ENABLED_FEATURE_SMS_PROVIDER_BY_EXTERNAL_API
  ),
  ECOMMERCE_MEMBER_DB: process.env.ECOMMERCE_MEMBER_DB,
  ECOMMERCE_MEMBER_IMPORT_TEMPLATE_URL:
    process.env.ECOMMERCE_MEMBER_IMPORT_TEMPLATE_URL,
  MULTI_LANG_SMS_LENGTH_NOTICE_LIMIT: ParamHelper.getNumberFromString(
    process.env.MULTI_LANG_SMS_LENGTH_NOTICE_LIMIT,
    70
  ),
  MULTI_LANG_SMS_LENGTH_MAX_LIMIT: ParamHelper.getNumberFromString(
    process.env.MULTI_LANG_SMS_LENGTH_MAX_LIMIT,
    268
  ),
  ENGLISH_LANG_SMS_LENGTH_NOTICE_LIMIT: ParamHelper.getNumberFromString(
    process.env.ENGLISH_LANG_SMS_LENGTH_NOTICE_LIMIT,
    160
  ),
  ENGLISH_LANG_SMS_LENGTH_MAX_LIMIT: ParamHelper.getNumberFromString(
    process.env.ENGLISH_LANG_SMS_LENGTH_MAX_LIMIT,
    612
  ),
  SMS_SHORTEN_URL_ENABLED: ParamHelper.getBoolTrue(
    process.env.SMS_SHORTEN_URL_ENABLED
  ),
  SMS_SHORTEN_URL_LENGTH: ParamHelper.getNumberFromString(
    process.env.SMS_SHORTEN_URL_LENGTH,
    26
  ),
  ENABLED_FEATURE_DATAFLOW_15MIN: ParamHelper.getBoolTrue(
    process.env.ENABLED_FEATURE_DATAFLOW_15MIN
  ),
};

export const LANGOptions = [
  {
    name: "English",
    value: "en",
    isRequired: true,
  },
  {
    name: "Thai",
    value: "th",
    isRequired: true,
  },
  {
    name: "Japanese",
    value: "jp",
  },
  {
    name: "Chinese",
    value: "cn",
  },
];
