
import {Component, Prop} from 'vue-property-decorator'
import BaseInput from '~/core/BaseInput'

@Component
export default class InputFile extends BaseInput {
  @Prop({default: '.csv'}) accept!: string

  sourceFile: any = null

  onSelfInput(value) {
    this.onInput(value?.name)
    if (!this.sourceFile) return
    var reader = new FileReader()
    reader.readAsText(this.sourceFile)
    reader.onload = () => {
      this.$emit('source', reader.result)
    }
  }
}
