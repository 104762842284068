
import {Component, Prop} from 'vue-property-decorator'
import {Base} from '~/core/Base'
import {IBreadcrumbItemState} from '~/store/types'

@Component
export default class Breadcrumbs extends Base {
  @Prop() readonly items!: IBreadcrumbItemState[]
  
  get breadcrumbItems() {
    interface BreadcrumbItem {
      text: string
      to: string
      exact: boolean
    }
    let breadcrumbItems: BreadcrumbItem[] = []
    if (this.items === undefined) {
      return []
    }
    this.items.forEach((item) => {
      breadcrumbItems.push({
        text: item.name,
        to: item.to || '',
        exact: true
      })
    })
    return breadcrumbItems
  }
}
