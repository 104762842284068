
import { parseScript } from "~/transform-script-engine";

import { Component, Prop, Watch } from "vue-property-decorator";
import BaseInput from "~/core/BaseInput";
import draggable from "vuedraggable";
import { v4 as uuidv4 } from "uuid";

import types from "./types";

interface FunctionParam {
  label: string;
  type: string;
  value?: string;
  useRef?: boolean;
}

interface FunctionItem {
  name: string;
  color: string;
  params?: FunctionParam[];
  id?: string;
  enableDefault?: boolean;
  default?: string;
  defaultUseRef: boolean;
}

interface ArrtibuteMappingItem {
  value: string;
  label: string;
  type: string;
}

@Component({ components: { draggable } })
export default class TransformScript extends BaseInput {
  @Prop({ type: Array, default: () => [] }) readonly keys!: string[];
  @Prop({ type: Array, default: () => [] }) readonly staticKeys!: string[];
  @Prop(Boolean) readonly disabled!: boolean;
  codeView = false;

  showDialog = false;
  script = "";
  functions: FunctionItem[] = [];
  functionItemSelectedIndex = -1;
  functionItemSelected: FunctionItem = {
    name: "",
    color: "",
    defaultUseRef: false,
  };

  get arrtibutesKeyList() {
    let r: ArrtibuteMappingItem[] = [];
    this.staticKeys.forEach((t) => {
      r.push({
        value: t,
        label: t,
        type: "static",
      });
    });

    this.keys.forEach((t) => {
      r.push({
        value: t,
        label: t,
        type: "attribute",
      });
    });

    return r;
  }

  availableFunctions: FunctionItem[] = [
    {
      name: "concat",
      color: "#9AE3B7",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [
        { label: "Concat with", type: "string", useRef: false, value: "" },
        {
          label: "Seperate With Space",
          type: "boolean",
          useRef: false,
          value: "false",
        },
      ],
    },
    {
      name: "exit_if_equal",
      color: "#ff758c",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [
        { label: "Compare With", type: "string", useRef: false, value: "" },
        {
          label: "Fallback Value",
          type: "string",
          useRef: false,
          value: "",
        },
      ],
    },
    {
      name: "explode_by_pipe",
      color: "#E6CDA3",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [
        { label: "Select position", type: "int", useRef: false, value: "0" },
      ],
    },
    {
      name: "overwrite_value",
      color: "#CA9E54",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [{ label: "Value", type: "string", useRef: false, value: "" }],
    },
    {
      name: "prefix_with",
      color: "#F2EAC4",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [{ label: "Prefix", type: "string", useRef: false, value: "" }],
    },
    {
      name: "repeat_string",
      color: "#F0A8A8",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [
        { label: "Repeat times", type: "int", useRef: false, value: "0" },
      ],
    },
    {
      name: "split_string",
      color: "#EAB0E3",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [
        { label: "Seperator", type: "string", useRef: false, value: "" },
        { label: "Select position", type: "int", useRef: false, value: "0" },
      ],
    },
    {
      name: "subfix_with",
      color: "#95DBDA",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [{ label: "Subfix", type: "string", useRef: false, value: "" }],
    },
    {
      name: "trim_space",
      color: "#9BAADD",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [],
    },
    {
      name: "datetime",
      color: "#FD7BED",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [
        {
          label: "DateTime layout",
          type: "string",
          useRef: false,
          value: "yyyy-MM-dd HH:mm:ss",
        },
      ],
    },
    {
      name: "format_date_string",
      color: "#FD7BED",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [
        {
          label: "Format Date layout",
          type: "string",
          useRef: false,
          value: "yyyy-MM-ddTHH:mm:ss.000000Z",
        },
      ],
    },
    {
      name: "math_expressions",
      color: "#4DC0BF",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [
        {
          label: "Math expressions",
          type: "string",
          useRef: false,
          value: "",
        },
      ],
    },
    {
      name: "read_from_json",
      color: "#4DC0BF",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [
        {
          label: "Read from json",
          type: "string",
          useRef: false,
          value: "",
        },
      ],
    },
    {
      name: "read_from_array",
      color: "#4DC0BF",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [
        {
          label: "Read from array",
          type: "string",
          useRef: false,
          value: "",
        },
      ],
    },
    {
      name: "build_column",
      color: "#d6d445",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [
        {
          label: "Column Name",
          type: "string",
          useRef: false,
          value: "",
        },
        {
          label: "Column Value",
          type: "string",
          useRef: false,
          value: "",
        },
      ],
    },
    {
      name: "add_region_phone_number",
      color: "#F2EAC4",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [{ label: "Region", type: "string", useRef: false, value: "" }],
    },
    {
      name: "hex_utf16",
      color: "#9BAADD",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [],
    },
    {
      name: "replace_string",
      color: "#349A89",
      enableDefault: false,
      default: "",
      defaultUseRef: false,
      params: [
        { label: "Find", type: "string", useRef: false, value: "" },
        { label: "Replace With", type: "string", useRef: false, value: "" },
      ],
    },
  ];

  itemClass(index: number) {
    return {
      active: this.functionItemSelectedIndex === index,
    };
  }

  removeStatrment(statementID?: string) {
    if (!statementID) {
      return;
    }
    const res = this.functions.filter((t) => {
      return t.id != statementID;
    });
    this.functions = res;
  }

  get code() {
    let code = "";
    for (let i in this.functions) {
      code += `${this.functions[i].name}${this.paramBracket(
        this.functions[i]
      )}`;

      if (Number(i) < this.functions.length - 1) {
        code += " |\n";
      }
    }

    return code;
  }

  @Watch("showDialog")
  onShowDialog(newValue) {
    if (newValue) {
      this.renderScriptString();
    }
  }

  resetDefaultValue() {
    if (this.functionItemSelected) {
      this.functionItemSelected!.default = "";
    }
  }

  selectItem(index: number) {
    this.functionItemSelected = this.functions[index];
    this.functionItemSelectedIndex = index;
  }

  isFloat(n: number) {
    return Number(n) === n && n % 1 !== 0;
  }

  paramBracket(func: FunctionItem) {
    const params = func.params ?? [];
    if (params.length == 0) {
      return "()";
    }
    let p: string[] = [];
    for (let i in params) {
      const t = params[i];

      if (t.value != undefined) {
        if (t.useRef) {
          p.push(`$${t.value}`);
        } else if (t.type == "string") {
          const escaped = t.value.replaceAll(`"`, `\\"`);
          p.push(`"${escaped}"`);
        } else if (t.type == "boolean") {
          let r = t.value;
          if (t.value !== "true" && t.value !== "false") {
            r = "false";
          }
          p.push(r);
        } else {
          let num = Number(t.value);
          let r = "";
          if (isNaN(num)) {
            num = 0;
          }

          if (t.type === "int") {
            if (this.isFloat(num)) {
              r = String(Math.floor(num));
            } else {
              r = num.toString();
            }
          }

          if (t.type === "float") {
            if (!this.isFloat(num)) {
              r = `${num}.0`;
            } else {
              r = t.value;
            }
          }

          p.push(r);
        }
      }
      // else {
      //   p += `<${t.type}>`;
      // }
    }

    let result = `(${p.join(", ")})`;

    if (func.enableDefault) {
      const defaultVal = func.default ?? "";
      if (func.defaultUseRef) {
        result += `?:$${defaultVal}`;
      } else {
        result += `?:"${defaultVal}"`;
      }
    }
    return result;
  }

  addFunction(func: FunctionItem) {
    let item = JSON.parse(JSON.stringify(func));
    item.id = uuidv4();
    this.functions.push(item);
  }

  clickDiscard() {
    this.showDialog = false;
    this.script = this.value;
  }

  clickSave() {
    this.showDialog = false;
    this.script = this.code;
    this.onInput(this.code);
  }

  openEditor() {
    this.showDialog = true;
  }

  renderScriptString() {
    const statements = parseScript(this.value);
    let functionsStack: FunctionItem[] = [];

    for (let i = 0; i < statements.length; i++) {
      const stmt = statements[i];

      let fnTemplate = this.availableFunctions.filter((t) => {
        return t.name === stmt.functionName;
      });

      if (fnTemplate.length == 1) {
        let fn: FunctionItem = JSON.parse(JSON.stringify(fnTemplate[0]));
        fn.id = uuidv4();

        if (stmt.defaultValue?.value) {
          fn.defaultUseRef = stmt.defaultValue?.type === "reference";
          fn.enableDefault = true;
          fn.default = stmt.defaultValue?.value;
        }

        for (let k = 0; k < stmt.param.length; k++) {
          let p = stmt.param[k];
          fn.params![k].useRef = p.type === "reference";
          fn.params![k].value = p.value;
        }
        functionsStack.push(fn);
      }
    }

    this.functionItemSelected = {
      name: "",
      color: "",
      defaultUseRef: false,
    };
    this.functions = functionsStack;
    console.log(JSON.stringify(this.functions));
    this.script = this.value;
  }

  orderFunctions() {
    //Order function name from alphabet
    this.availableFunctions = this.availableFunctions.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  }

  mounted() {
    this.orderFunctions();
    this.renderScriptString();
  }
}
