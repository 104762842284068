
  import { Component, Prop, Watch } from "vue-property-decorator";
  import BaseInput from "~/core/BaseInput";
import { _isEmpty, _isUndefined, _isEqual, _isObject, _isArray } from "~/utils/lodash";
  
@Component
export default class JSONEditor extends BaseInput {
  @Prop({ default: false }) isOptional!: boolean;

  objectValue: any = {}

  created() {
    if (this.innerValue) {
      try {
        const vObj = JSON.parse(this.innerValue)
        if (vObj) {
          this.objectValue = vObj
        }
      } catch(e) {
        this.objectValue = {}
      }
    }
  }

  @Watch("objectValue", {deep:true})
  onObjectValueChanged() {
    // This function will transform this.objectValue into JSON string and keep it in this.innerValue only if it is valid JSON
    const isJSON = _isObject(this.objectValue) || _isArray(this.objectValue)
    if (isJSON) {
      this.innerValue = JSON.stringify(this.objectValue)
    } else {
      const js = this.isValidJSON(this.objectValue)
      if (js.isValid) {
        this.innerValue = js.str
      }
    }
  }

  isValidJSON(val:string) :{isValid: boolean, str: string} {
    try {
      const v = JSON.parse(val)
      return {isValid: true, str: JSON.stringify(v)}
    } catch(e) {
      return {isValid: false, str: ''}
    }
  }
}
