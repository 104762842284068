
import {Component, Prop} from 'vue-property-decorator'
import BaseInput from '~/core/BaseInput'
import {DATEFORMAT, TimeHelper} from '~/utils/TimeHelper'

@Component
export default class DateRangePicker extends BaseInput {
  @Prop({default: undefined}) max!: string
  @Prop({default: undefined}) min!: string

  menu = false
  date: any = this.value
  displayText = this.displayDate(this.date)

  get masks() {
    return {
      date: new Date(),
      masks: {
        input: 'HH:mm',
      },
    }
  }

  get attributes() {
    return [
      {
        key: 'today',
        bar: true,
        dates: new Date(),
      }
    ]
  }

  get modelConfig() {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
    }
  }

  async onBlur(e, updateF) {
    this.date = await updateF({
      start: e.target?.value?.replaceAll(/\s[~].*$/g, ''),
      end: e.target?.value?.replaceAll(/^[0-9\-]{10}\s[~]\s/g, ''),
    })
    this.displayText = this.displayDate(this.date)
  }

  displayDate(date) {
    if (date === null) {
      return '-'
    }
    return `${TimeHelper.getDateFromTime(date?.start, DATEFORMAT)} ~ ${TimeHelper.getDateFromTime(date?.end, DATEFORMAT)}`
  }

  onFormatInput(date) {
    this.displayText = this.displayDate(date)
    this.onInput({
      start: this.$time.getDateFromTime(date?.start, 'YYYY-MM-DD'),
      end: this.$time.getDateFromTime(date?.end, 'YYYY-MM-DD'),
    })
  }


  onKeydown(e, updateF) {
    if (e.keyCode === 13) {
      this.onBlur(e, updateF)
    }
  }
}
