import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1a889f55 = () => interopDefault(import('../pages/changePassword.vue' /* webpackChunkName: "pages/changePassword" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _e7d1c7a2 = () => interopDefault(import('../pages/marketing/index.vue' /* webpackChunkName: "pages/marketing/index" */))
const _01b1f57c = () => interopDefault(import('../pages/marketing/affiliates/index.vue' /* webpackChunkName: "pages/marketing/affiliates/index" */))
const _c1aae4b2 = () => interopDefault(import('../pages/marketing/caches/index.vue' /* webpackChunkName: "pages/marketing/caches/index" */))
const _b8f6a78e = () => interopDefault(import('../pages/marketing/campaign-journey/index.vue' /* webpackChunkName: "pages/marketing/campaign-journey/index" */))
const _48603043 = () => interopDefault(import('../pages/marketing/campaigns/index.vue' /* webpackChunkName: "pages/marketing/campaigns/index" */))
const _059758f2 = () => interopDefault(import('../pages/marketing/customer-journey/index.vue' /* webpackChunkName: "pages/marketing/customer-journey/index" */))
const _7d7ff696 = () => interopDefault(import('../pages/marketing/customers/index.vue' /* webpackChunkName: "pages/marketing/customers/index" */))
const _bd60049a = () => interopDefault(import('../pages/marketing/data-plugins/index.vue' /* webpackChunkName: "pages/marketing/data-plugins/index" */))
const _c346a6e4 = () => interopDefault(import('../pages/marketing/external-api/index.vue' /* webpackChunkName: "pages/marketing/external-api/index" */))
const _aa15957a = () => interopDefault(import('../pages/marketing/landingpages/index.vue' /* webpackChunkName: "pages/marketing/landingpages/index" */))
const _664a7755 = () => interopDefault(import('../pages/marketing/master-data/index.vue' /* webpackChunkName: "pages/marketing/master-data/index" */))
const _087034e3 = () => interopDefault(import('../pages/marketing/metrics/index.vue' /* webpackChunkName: "pages/marketing/metrics/index" */))
const _0a3bec98 = () => interopDefault(import('../pages/marketing/pamtag/index.vue' /* webpackChunkName: "pages/marketing/pamtag/index" */))
const _6d98bf9d = () => interopDefault(import('../pages/marketing/roles/index.vue' /* webpackChunkName: "pages/marketing/roles/index" */))
const _3b649706 = () => interopDefault(import('../pages/marketing/script-gallery/index.vue' /* webpackChunkName: "pages/marketing/script-gallery/index" */))
const _375d5a96 = () => interopDefault(import('../pages/marketing/segments/index.vue' /* webpackChunkName: "pages/marketing/segments/index" */))
const _44374759 = () => interopDefault(import('../pages/marketing/settings/index.vue' /* webpackChunkName: "pages/marketing/settings/index" */))
const _7aed9b2e = () => interopDefault(import('../pages/marketing/tasks/index.vue' /* webpackChunkName: "pages/marketing/tasks/index" */))
const _1ba2f8ef = () => interopDefault(import('../pages/marketing/tasks-graph/index.vue' /* webpackChunkName: "pages/marketing/tasks-graph/index" */))
const _3ddb45b0 = () => interopDefault(import('../pages/marketing/users/index.vue' /* webpackChunkName: "pages/marketing/users/index" */))
const _9c9c6846 = () => interopDefault(import('../pages/test-components/jsoneditor.vue' /* webpackChunkName: "pages/test-components/jsoneditor" */))
const _5c4104c2 = () => interopDefault(import('../pages/marketing/affiliates/create.vue' /* webpackChunkName: "pages/marketing/affiliates/create" */))
const _4cf3dde8 = () => interopDefault(import('../pages/marketing/attention/templates/index.vue' /* webpackChunkName: "pages/marketing/attention/templates/index" */))
const _1675b4b6 = () => interopDefault(import('../pages/marketing/campaign-journey/create.vue' /* webpackChunkName: "pages/marketing/campaign-journey/create" */))
const _294bba4a = () => interopDefault(import('../pages/marketing/campaigns/create.vue' /* webpackChunkName: "pages/marketing/campaigns/create" */))
const _ce8fc358 = () => interopDefault(import('../pages/marketing/consents/consent-message/index.vue' /* webpackChunkName: "pages/marketing/consents/consent-message/index" */))
const _5deb2fd2 = () => interopDefault(import('../pages/marketing/customer-journey/create.vue' /* webpackChunkName: "pages/marketing/customer-journey/create" */))
const _4dd0a5f8 = () => interopDefault(import('../pages/marketing/customers/attributes.vue' /* webpackChunkName: "pages/marketing/customers/attributes" */))
const _44a21ceb = () => interopDefault(import('../pages/marketing/customers/database/index.vue' /* webpackChunkName: "pages/marketing/customers/database/index" */))
const _b1620ac4 = () => interopDefault(import('../pages/marketing/customers/demographics/index.vue' /* webpackChunkName: "pages/marketing/customers/demographics/index" */))
const _88f59a5c = () => interopDefault(import('../pages/marketing/customers/import.vue' /* webpackChunkName: "pages/marketing/customers/import" */))
const _e637e974 = () => interopDefault(import('../pages/marketing/customers/linked.vue' /* webpackChunkName: "pages/marketing/customers/linked" */))
const _07754af4 = () => interopDefault(import('../pages/marketing/customers/tags.vue' /* webpackChunkName: "pages/marketing/customers/tags" */))
const _9f37f92a = () => interopDefault(import('../pages/marketing/data-plugins/create.vue' /* webpackChunkName: "pages/marketing/data-plugins/create" */))
const _50f37034 = () => interopDefault(import('../pages/marketing/email/templates/index.vue' /* webpackChunkName: "pages/marketing/email/templates/index" */))
const _246cca40 = () => interopDefault(import('../pages/marketing/events/parameters/index.vue' /* webpackChunkName: "pages/marketing/events/parameters/index" */))
const _54ed2ff0 = () => interopDefault(import('../pages/marketing/external-api/create.vue' /* webpackChunkName: "pages/marketing/external-api/create" */))
const _4934844a = () => interopDefault(import('../pages/marketing/landingpages/create.vue' /* webpackChunkName: "pages/marketing/landingpages/create" */))
const _bcffa528 = () => interopDefault(import('../pages/marketing/landingpages/themes/index.vue' /* webpackChunkName: "pages/marketing/landingpages/themes/index" */))
const _63cbde64 = () => interopDefault(import('../pages/marketing/master-data/attributes.vue' /* webpackChunkName: "pages/marketing/master-data/attributes" */))
const _0d1e9e4b = () => interopDefault(import('../pages/marketing/master-data/database/index.vue' /* webpackChunkName: "pages/marketing/master-data/database/index" */))
const _1e286804 = () => interopDefault(import('../pages/marketing/master-data/demographics/index.vue' /* webpackChunkName: "pages/marketing/master-data/demographics/index" */))
const _906dd79c = () => interopDefault(import('../pages/marketing/master-data/import.vue' /* webpackChunkName: "pages/marketing/master-data/import" */))
const _edb026b4 = () => interopDefault(import('../pages/marketing/master-data/linked.vue' /* webpackChunkName: "pages/marketing/master-data/linked" */))
const _2e5bd834 = () => interopDefault(import('../pages/marketing/master-data/tags.vue' /* webpackChunkName: "pages/marketing/master-data/tags" */))
const _2c9c4324 = () => interopDefault(import('../pages/marketing/master/languages/index.vue' /* webpackChunkName: "pages/marketing/master/languages/index" */))
const _8a776c64 = () => interopDefault(import('../pages/marketing/media/email.vue' /* webpackChunkName: "pages/marketing/media/email" */))
const _341f98ea = () => interopDefault(import('../pages/marketing/media/sms.vue' /* webpackChunkName: "pages/marketing/media/sms" */))
const _0c768ca4 = () => interopDefault(import('../pages/marketing/media/testMedia.vue' /* webpackChunkName: "pages/marketing/media/testMedia" */))
const _315a9b36 = () => interopDefault(import('../pages/marketing/migration/consent.vue' /* webpackChunkName: "pages/marketing/migration/consent" */))
const _35ca0660 = () => interopDefault(import('../pages/marketing/migration/data-flow/index.vue' /* webpackChunkName: "pages/marketing/migration/data-flow/index" */))
const _7b70c97e = () => interopDefault(import('../pages/marketing/migration/demographic.vue' /* webpackChunkName: "pages/marketing/migration/demographic" */))
const _19c7f354 = () => interopDefault(import('../pages/marketing/migration/tracker.vue' /* webpackChunkName: "pages/marketing/migration/tracker" */))
const _71e81e9c = () => interopDefault(import('../pages/marketing/roles/create-role.vue' /* webpackChunkName: "pages/marketing/roles/create-role" */))
const _58e29478 = () => interopDefault(import('../pages/marketing/script-gallery/create.vue' /* webpackChunkName: "pages/marketing/script-gallery/create" */))
const _47fb7a30 = () => interopDefault(import('../pages/marketing/segments/create.vue' /* webpackChunkName: "pages/marketing/segments/create" */))
const _692a1462 = () => interopDefault(import('../pages/marketing/visualizations/dynamic-report/index.vue' /* webpackChunkName: "pages/marketing/visualizations/dynamic-report/index" */))
const _6afd1139 = () => interopDefault(import('../pages/marketing/visualizations/segments-funnel/index.vue' /* webpackChunkName: "pages/marketing/visualizations/segments-funnel/index" */))
const _793c29d6 = () => interopDefault(import('../pages/marketing/attention/templates/create.vue' /* webpackChunkName: "pages/marketing/attention/templates/create" */))
const _b400122c = () => interopDefault(import('../pages/marketing/consents/consent-message/create.vue' /* webpackChunkName: "pages/marketing/consents/consent-message/create" */))
const _7755cb33 = () => interopDefault(import('../pages/marketing/customers/database/create.vue' /* webpackChunkName: "pages/marketing/customers/database/create" */))
const _40f6ff98 = () => interopDefault(import('../pages/marketing/email/templates/create.vue' /* webpackChunkName: "pages/marketing/email/templates/create" */))
const _938c6a5c = () => interopDefault(import('../pages/marketing/landingpages/themes/create.vue' /* webpackChunkName: "pages/marketing/landingpages/themes/create" */))
const _832d145a = () => interopDefault(import('../pages/marketing/master-data/database/create.vue' /* webpackChunkName: "pages/marketing/master-data/database/create" */))
const _e2bf27cc = () => interopDefault(import('../pages/marketing/master/languages/create.vue' /* webpackChunkName: "pages/marketing/master/languages/create" */))
const _1c5960a5 = () => interopDefault(import('../pages/marketing/visualizations/segments-funnel/create.vue' /* webpackChunkName: "pages/marketing/visualizations/segments-funnel/create" */))
const _013b612b = () => interopDefault(import('../pages/marketing/migration/data-flow/create/_adapter_type/index.vue' /* webpackChunkName: "pages/marketing/migration/data-flow/create/_adapter_type/index" */))
const _5a3e2e4d = () => interopDefault(import('../pages/marketing/visualizations/dynamic-report/_id/index.vue' /* webpackChunkName: "pages/marketing/visualizations/dynamic-report/_id/index" */))
const _208a0fce = () => interopDefault(import('../pages/marketing/attention/templates/_id/edit.vue' /* webpackChunkName: "pages/marketing/attention/templates/_id/edit" */))
const _0a805fa6 = () => interopDefault(import('../pages/marketing/consents/consent-message/_id/edit.vue' /* webpackChunkName: "pages/marketing/consents/consent-message/_id/edit" */))
const _873a0862 = () => interopDefault(import('../pages/marketing/consents/consent-message/_id/version-history/index.vue' /* webpackChunkName: "pages/marketing/consents/consent-message/_id/version-history/index" */))
const _5a2190cc = () => interopDefault(import('../pages/marketing/customers/database/_id/demographics.vue' /* webpackChunkName: "pages/marketing/customers/database/_id/demographics" */))
const _641c9394 = () => interopDefault(import('../pages/marketing/customers/database/_id/edit.vue' /* webpackChunkName: "pages/marketing/customers/database/_id/edit" */))
const _97d1354a = () => interopDefault(import('../pages/marketing/email/templates/_id/edit.vue' /* webpackChunkName: "pages/marketing/email/templates/_id/edit" */))
const _38576bd6 = () => interopDefault(import('../pages/marketing/landingpages/themes/_id/edit.vue' /* webpackChunkName: "pages/marketing/landingpages/themes/_id/edit" */))
const _e833cb8c = () => interopDefault(import('../pages/marketing/master-data/database/_id/demographics.vue' /* webpackChunkName: "pages/marketing/master-data/database/_id/demographics" */))
const _c2758e54 = () => interopDefault(import('../pages/marketing/master-data/database/_id/edit.vue' /* webpackChunkName: "pages/marketing/master-data/database/_id/edit" */))
const _3d17b95d = () => interopDefault(import('../pages/marketing/master/languages/_id/edit.vue' /* webpackChunkName: "pages/marketing/master/languages/_id/edit" */))
const _6ddc3b59 = () => interopDefault(import('../pages/marketing/migration/data-flow/_id/logs/index.vue' /* webpackChunkName: "pages/marketing/migration/data-flow/_id/logs/index" */))
const _4065b728 = () => interopDefault(import('../pages/marketing/visualizations/segments-funnel/_id/edit.vue' /* webpackChunkName: "pages/marketing/visualizations/segments-funnel/_id/edit" */))
const _4201c279 = () => interopDefault(import('../pages/marketing/consents/consent-message/_id/version-history/_version/revision/_revision.vue' /* webpackChunkName: "pages/marketing/consents/consent-message/_id/version-history/_version/revision/_revision" */))
const _2d303916 = () => interopDefault(import('../pages/marketing/migration/data-flow/_id/_adapter_type/index.vue' /* webpackChunkName: "pages/marketing/migration/data-flow/_id/_adapter_type/index" */))
const _74af4bc9 = () => interopDefault(import('../pages/marketing/caches/_prefix/index.vue' /* webpackChunkName: "pages/marketing/caches/_prefix/index" */))
const _4b2485c0 = () => interopDefault(import('../pages/marketing/customers/_id/index.vue' /* webpackChunkName: "pages/marketing/customers/_id/index" */))
const _471f0569 = () => interopDefault(import('../pages/marketing/download/_id/index.vue' /* webpackChunkName: "pages/marketing/download/_id/index" */))
const _39bddfc0 = () => interopDefault(import('../pages/marketing/master-data/_id/index.vue' /* webpackChunkName: "pages/marketing/master-data/_id/index" */))
const _9ee3b364 = () => interopDefault(import('../pages/marketing/metrics/_id/index.vue' /* webpackChunkName: "pages/marketing/metrics/_id/index" */))
const _3e096f19 = () => interopDefault(import('../pages/marketing/tasks/_id/index.vue' /* webpackChunkName: "pages/marketing/tasks/_id/index" */))
const _24f4c805 = () => interopDefault(import('../pages/marketing/affiliates/_id/edit.vue' /* webpackChunkName: "pages/marketing/affiliates/_id/edit" */))
const _a61787b0 = () => interopDefault(import('../pages/marketing/campaign-journey/_id/edit.vue' /* webpackChunkName: "pages/marketing/campaign-journey/_id/edit" */))
const _88e1c82c = () => interopDefault(import('../pages/marketing/campaign-journey/_id/insight.vue' /* webpackChunkName: "pages/marketing/campaign-journey/_id/insight" */))
const _523ec3de = () => interopDefault(import('../pages/marketing/campaigns/_id/edit.vue' /* webpackChunkName: "pages/marketing/campaigns/_id/edit" */))
const _50941f18 = () => interopDefault(import('../pages/marketing/campaigns/_id/insight.vue' /* webpackChunkName: "pages/marketing/campaigns/_id/insight" */))
const _e61aabcc = () => interopDefault(import('../pages/marketing/customer-journey/_id/edit.vue' /* webpackChunkName: "pages/marketing/customer-journey/_id/edit" */))
const _24cb90b8 = () => interopDefault(import('../pages/marketing/customer-journey/_id/insight.vue' /* webpackChunkName: "pages/marketing/customer-journey/_id/insight" */))
const _075a7f24 = () => interopDefault(import('../pages/marketing/data-plugins/_id/edit.vue' /* webpackChunkName: "pages/marketing/data-plugins/_id/edit" */))
const _b97a409a = () => interopDefault(import('../pages/marketing/external-api/_id/edit.vue' /* webpackChunkName: "pages/marketing/external-api/_id/edit" */))
const _6dcf9ede = () => interopDefault(import('../pages/marketing/landingpages/_id/edit.vue' /* webpackChunkName: "pages/marketing/landingpages/_id/edit" */))
const _cc4025c2 = () => interopDefault(import('../pages/marketing/metrics/_id/errors.vue' /* webpackChunkName: "pages/marketing/metrics/_id/errors" */))
const _26f889ec = () => interopDefault(import('../pages/marketing/metrics/_id/tasks.vue' /* webpackChunkName: "pages/marketing/metrics/_id/tasks" */))
const _6be4d728 = () => interopDefault(import('../pages/marketing/roles/_id/edit-permission.vue' /* webpackChunkName: "pages/marketing/roles/_id/edit-permission" */))
const _0808eb0f = () => interopDefault(import('../pages/marketing/roles/_id/edit-role.vue' /* webpackChunkName: "pages/marketing/roles/_id/edit-role" */))
const _7f71423b = () => interopDefault(import('../pages/marketing/script-gallery/_id/edit.vue' /* webpackChunkName: "pages/marketing/script-gallery/_id/edit" */))
const _741f72d6 = () => interopDefault(import('../pages/marketing/segments/_alias/contacts.vue' /* webpackChunkName: "pages/marketing/segments/_alias/contacts" */))
const _8d41d6aa = () => interopDefault(import('../pages/marketing/segments/_id/edit.vue' /* webpackChunkName: "pages/marketing/segments/_id/edit" */))
const _7d5e5ef2 = () => interopDefault(import('../pages/marketing/segments/_id/insight.vue' /* webpackChunkName: "pages/marketing/segments/_id/insight" */))
const _8e959e4e = () => interopDefault(import('../pages/marketing/users/_id/edit.vue' /* webpackChunkName: "pages/marketing/users/_id/edit" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/changePassword",
    component: _1a889f55,
    name: "changePassword"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/marketing",
    component: _e7d1c7a2,
    name: "marketing"
  }, {
    path: "/marketing/affiliates",
    component: _01b1f57c,
    name: "marketing-affiliates"
  }, {
    path: "/marketing/caches",
    component: _c1aae4b2,
    name: "marketing-caches"
  }, {
    path: "/marketing/campaign-journey",
    component: _b8f6a78e,
    name: "marketing-campaign-journey"
  }, {
    path: "/marketing/campaigns",
    component: _48603043,
    name: "marketing-campaigns"
  }, {
    path: "/marketing/customer-journey",
    component: _059758f2,
    name: "marketing-customer-journey"
  }, {
    path: "/marketing/customers",
    component: _7d7ff696,
    name: "marketing-customers"
  }, {
    path: "/marketing/data-plugins",
    component: _bd60049a,
    name: "marketing-data-plugins"
  }, {
    path: "/marketing/external-api",
    component: _c346a6e4,
    name: "marketing-external-api"
  }, {
    path: "/marketing/landingpages",
    component: _aa15957a,
    name: "marketing-landingpages"
  }, {
    path: "/marketing/master-data",
    component: _664a7755,
    name: "marketing-master-data"
  }, {
    path: "/marketing/metrics",
    component: _087034e3,
    name: "marketing-metrics"
  }, {
    path: "/marketing/pamtag",
    component: _0a3bec98,
    name: "marketing-pamtag"
  }, {
    path: "/marketing/roles",
    component: _6d98bf9d,
    name: "marketing-roles"
  }, {
    path: "/marketing/script-gallery",
    component: _3b649706,
    name: "marketing-script-gallery"
  }, {
    path: "/marketing/segments",
    component: _375d5a96,
    name: "marketing-segments"
  }, {
    path: "/marketing/settings",
    component: _44374759,
    name: "marketing-settings"
  }, {
    path: "/marketing/tasks",
    component: _7aed9b2e,
    name: "marketing-tasks"
  }, {
    path: "/marketing/tasks-graph",
    component: _1ba2f8ef,
    name: "marketing-tasks-graph"
  }, {
    path: "/marketing/users",
    component: _3ddb45b0,
    name: "marketing-users"
  }, {
    path: "/test-components/jsoneditor",
    component: _9c9c6846,
    name: "test-components-jsoneditor"
  }, {
    path: "/marketing/affiliates/create",
    component: _5c4104c2,
    name: "marketing-affiliates-create"
  }, {
    path: "/marketing/attention/templates",
    component: _4cf3dde8,
    name: "marketing-attention-templates"
  }, {
    path: "/marketing/campaign-journey/create",
    component: _1675b4b6,
    name: "marketing-campaign-journey-create"
  }, {
    path: "/marketing/campaigns/create",
    component: _294bba4a,
    name: "marketing-campaigns-create"
  }, {
    path: "/marketing/consents/consent-message",
    component: _ce8fc358,
    name: "marketing-consents-consent-message"
  }, {
    path: "/marketing/customer-journey/create",
    component: _5deb2fd2,
    name: "marketing-customer-journey-create"
  }, {
    path: "/marketing/customers/attributes",
    component: _4dd0a5f8,
    name: "marketing-customers-attributes"
  }, {
    path: "/marketing/customers/database",
    component: _44a21ceb,
    name: "marketing-customers-database"
  }, {
    path: "/marketing/customers/demographics",
    component: _b1620ac4,
    name: "marketing-customers-demographics"
  }, {
    path: "/marketing/customers/import",
    component: _88f59a5c,
    name: "marketing-customers-import"
  }, {
    path: "/marketing/customers/linked",
    component: _e637e974,
    name: "marketing-customers-linked"
  }, {
    path: "/marketing/customers/tags",
    component: _07754af4,
    name: "marketing-customers-tags"
  }, {
    path: "/marketing/data-plugins/create",
    component: _9f37f92a,
    name: "marketing-data-plugins-create"
  }, {
    path: "/marketing/email/templates",
    component: _50f37034,
    name: "marketing-email-templates"
  }, {
    path: "/marketing/events/parameters",
    component: _246cca40,
    name: "marketing-events-parameters"
  }, {
    path: "/marketing/external-api/create",
    component: _54ed2ff0,
    name: "marketing-external-api-create"
  }, {
    path: "/marketing/landingpages/create",
    component: _4934844a,
    name: "marketing-landingpages-create"
  }, {
    path: "/marketing/landingpages/themes",
    component: _bcffa528,
    name: "marketing-landingpages-themes"
  }, {
    path: "/marketing/master-data/attributes",
    component: _63cbde64,
    name: "marketing-master-data-attributes"
  }, {
    path: "/marketing/master-data/database",
    component: _0d1e9e4b,
    name: "marketing-master-data-database"
  }, {
    path: "/marketing/master-data/demographics",
    component: _1e286804,
    name: "marketing-master-data-demographics"
  }, {
    path: "/marketing/master-data/import",
    component: _906dd79c,
    name: "marketing-master-data-import"
  }, {
    path: "/marketing/master-data/linked",
    component: _edb026b4,
    name: "marketing-master-data-linked"
  }, {
    path: "/marketing/master-data/tags",
    component: _2e5bd834,
    name: "marketing-master-data-tags"
  }, {
    path: "/marketing/master/languages",
    component: _2c9c4324,
    name: "marketing-master-languages"
  }, {
    path: "/marketing/media/email",
    component: _8a776c64,
    name: "marketing-media-email"
  }, {
    path: "/marketing/media/sms",
    component: _341f98ea,
    name: "marketing-media-sms"
  }, {
    path: "/marketing/media/testMedia",
    component: _0c768ca4,
    name: "marketing-media-testMedia"
  }, {
    path: "/marketing/migration/consent",
    component: _315a9b36,
    name: "marketing-migration-consent"
  }, {
    path: "/marketing/migration/data-flow",
    component: _35ca0660,
    name: "marketing-migration-data-flow"
  }, {
    path: "/marketing/migration/demographic",
    component: _7b70c97e,
    name: "marketing-migration-demographic"
  }, {
    path: "/marketing/migration/tracker",
    component: _19c7f354,
    name: "marketing-migration-tracker"
  }, {
    path: "/marketing/roles/create-role",
    component: _71e81e9c,
    name: "marketing-roles-create-role"
  }, {
    path: "/marketing/script-gallery/create",
    component: _58e29478,
    name: "marketing-script-gallery-create"
  }, {
    path: "/marketing/segments/create",
    component: _47fb7a30,
    name: "marketing-segments-create"
  }, {
    path: "/marketing/visualizations/dynamic-report",
    component: _692a1462,
    name: "marketing-visualizations-dynamic-report"
  }, {
    path: "/marketing/visualizations/segments-funnel",
    component: _6afd1139,
    name: "marketing-visualizations-segments-funnel"
  }, {
    path: "/marketing/attention/templates/create",
    component: _793c29d6,
    name: "marketing-attention-templates-create"
  }, {
    path: "/marketing/consents/consent-message/create",
    component: _b400122c,
    name: "marketing-consents-consent-message-create"
  }, {
    path: "/marketing/customers/database/create",
    component: _7755cb33,
    name: "marketing-customers-database-create"
  }, {
    path: "/marketing/email/templates/create",
    component: _40f6ff98,
    name: "marketing-email-templates-create"
  }, {
    path: "/marketing/landingpages/themes/create",
    component: _938c6a5c,
    name: "marketing-landingpages-themes-create"
  }, {
    path: "/marketing/master-data/database/create",
    component: _832d145a,
    name: "marketing-master-data-database-create"
  }, {
    path: "/marketing/master/languages/create",
    component: _e2bf27cc,
    name: "marketing-master-languages-create"
  }, {
    path: "/marketing/visualizations/segments-funnel/create",
    component: _1c5960a5,
    name: "marketing-visualizations-segments-funnel-create"
  }, {
    path: "/marketing/migration/data-flow/create/:adapter_type",
    component: _013b612b,
    name: "marketing-migration-data-flow-create-adapter_type"
  }, {
    path: "/marketing/visualizations/dynamic-report/:id",
    component: _5a3e2e4d,
    name: "marketing-visualizations-dynamic-report-id"
  }, {
    path: "/marketing/attention/templates/:id/edit",
    component: _208a0fce,
    name: "marketing-attention-templates-id-edit"
  }, {
    path: "/marketing/consents/consent-message/:id?/edit",
    component: _0a805fa6,
    name: "marketing-consents-consent-message-id-edit"
  }, {
    path: "/marketing/consents/consent-message/:id?/version-history",
    component: _873a0862,
    name: "marketing-consents-consent-message-id-version-history"
  }, {
    path: "/marketing/customers/database/:id/demographics",
    component: _5a2190cc,
    name: "marketing-customers-database-id-demographics"
  }, {
    path: "/marketing/customers/database/:id/edit",
    component: _641c9394,
    name: "marketing-customers-database-id-edit"
  }, {
    path: "/marketing/email/templates/:id/edit",
    component: _97d1354a,
    name: "marketing-email-templates-id-edit"
  }, {
    path: "/marketing/landingpages/themes/:id/edit",
    component: _38576bd6,
    name: "marketing-landingpages-themes-id-edit"
  }, {
    path: "/marketing/master-data/database/:id/demographics",
    component: _e833cb8c,
    name: "marketing-master-data-database-id-demographics"
  }, {
    path: "/marketing/master-data/database/:id/edit",
    component: _c2758e54,
    name: "marketing-master-data-database-id-edit"
  }, {
    path: "/marketing/master/languages/:id/edit",
    component: _3d17b95d,
    name: "marketing-master-languages-id-edit"
  }, {
    path: "/marketing/migration/data-flow/:id?/logs",
    component: _6ddc3b59,
    name: "marketing-migration-data-flow-id-logs"
  }, {
    path: "/marketing/visualizations/segments-funnel/:id?/edit",
    component: _4065b728,
    name: "marketing-visualizations-segments-funnel-id-edit"
  }, {
    path: "/marketing/consents/consent-message/:id?/version-history/:version?/revision/:revision",
    component: _4201c279,
    name: "marketing-consents-consent-message-id-version-history-version-revision-revision"
  }, {
    path: "/marketing/migration/data-flow/:id?/:adapter_type",
    component: _2d303916,
    name: "marketing-migration-data-flow-id-adapter_type"
  }, {
    path: "/marketing/caches/:prefix",
    component: _74af4bc9,
    name: "marketing-caches-prefix"
  }, {
    path: "/marketing/customers/:id",
    component: _4b2485c0,
    name: "marketing-customers-id"
  }, {
    path: "/marketing/download/:id",
    component: _471f0569,
    name: "marketing-download-id"
  }, {
    path: "/marketing/master-data/:id",
    component: _39bddfc0,
    name: "marketing-master-data-id"
  }, {
    path: "/marketing/metrics/:id",
    component: _9ee3b364,
    name: "marketing-metrics-id"
  }, {
    path: "/marketing/tasks/:id",
    component: _3e096f19,
    name: "marketing-tasks-id"
  }, {
    path: "/marketing/affiliates/:id/edit",
    component: _24f4c805,
    name: "marketing-affiliates-id-edit"
  }, {
    path: "/marketing/campaign-journey/:id?/edit",
    component: _a61787b0,
    name: "marketing-campaign-journey-id-edit"
  }, {
    path: "/marketing/campaign-journey/:id?/insight",
    component: _88e1c82c,
    name: "marketing-campaign-journey-id-insight"
  }, {
    path: "/marketing/campaigns/:id/edit",
    component: _523ec3de,
    name: "marketing-campaigns-id-edit"
  }, {
    path: "/marketing/campaigns/:id/insight",
    component: _50941f18,
    name: "marketing-campaigns-id-insight"
  }, {
    path: "/marketing/customer-journey/:id?/edit",
    component: _e61aabcc,
    name: "marketing-customer-journey-id-edit"
  }, {
    path: "/marketing/customer-journey/:id?/insight",
    component: _24cb90b8,
    name: "marketing-customer-journey-id-insight"
  }, {
    path: "/marketing/data-plugins/:id?/edit",
    component: _075a7f24,
    name: "marketing-data-plugins-id-edit"
  }, {
    path: "/marketing/external-api/:id?/edit",
    component: _b97a409a,
    name: "marketing-external-api-id-edit"
  }, {
    path: "/marketing/landingpages/:id/edit",
    component: _6dcf9ede,
    name: "marketing-landingpages-id-edit"
  }, {
    path: "/marketing/metrics/:id/errors",
    component: _cc4025c2,
    name: "marketing-metrics-id-errors"
  }, {
    path: "/marketing/metrics/:id/tasks",
    component: _26f889ec,
    name: "marketing-metrics-id-tasks"
  }, {
    path: "/marketing/roles/:id/edit-permission",
    component: _6be4d728,
    name: "marketing-roles-id-edit-permission"
  }, {
    path: "/marketing/roles/:id/edit-role",
    component: _0808eb0f,
    name: "marketing-roles-id-edit-role"
  }, {
    path: "/marketing/script-gallery/:id?/edit",
    component: _7f71423b,
    name: "marketing-script-gallery-id-edit"
  }, {
    path: "/marketing/segments/:alias/contacts",
    component: _741f72d6,
    name: "marketing-segments-alias-contacts"
  }, {
    path: "/marketing/segments/:id/edit",
    component: _8d41d6aa,
    name: "marketing-segments-id-edit"
  }, {
    path: "/marketing/segments/:id/insight",
    component: _7d5e5ef2,
    name: "marketing-segments-id-insight"
  }, {
    path: "/marketing/users/:id/edit",
    component: _8e959e4e,
    name: "marketing-users-id-edit"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
