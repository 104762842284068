// Copyright © PushAndMotion 2018 All Rights Reserved.
// PAM Engine & Library is proprietary and confidential.
// Un-authorize using, editing, copying, adapting, distributing, of this file or some part of this file without
// the prior written consent of PushAndMotion, via any medium is strictly prohibited.
// If not expressively specify in the document, the authorisation to use this library will be granted per application.
// Any question regarding this copyright notice please contact contact@pushandmotion.com
// This copyright notice must be included in the header of every distribution of all the source code.

import {
  types,
  TransformFunctionParam,
  TransformFunctionStatement,
  TransformFunctionExecutor,
} from "./types";

function removeAllSpace(code: string): string {
  let buff: string = "";
  let foundQuote = false;

  for (let i = 0; i < code.length; i++) {
    const char = code.charAt(i);

    if (!foundQuote && char == '"') {
      foundQuote = true;
      buff += char;
    } else if (foundQuote) {
      if (char != '"') {
        buff += char;
      } else {
        foundQuote = false;
        buff += char;
      }
    } else if (char != " " && char != "\n" && char != "\t") {
      buff += char;
    }
  }
  return buff;
}

function guessParamType(str: string): string {
  if (str === "true" || str === "false") {
    return "boolean";
  } else if (!isNaN(parseFloat(str)) && isFinite(+str)) {
    if (Number.isInteger(+str)) {
      return "number";
    } else {
      return "float";
    }
  } else {
    return "string";
  }
}

export function parseScript(code: string): TransformFunctionStatement[] {
  if (!code) {
    return [];
  }

  code = removeAllSpace(code);
  let buff = "";
  let state = "SCAN_FUNCTION_NAME";
  let functionStack: TransformFunctionStatement[] = [];

  let functionObject: TransformFunctionStatement = {
    functionName: "",
    param: [],
  };
  let paramType: string = types.TypeUnknow;
  let startQoute = false;

  for (let pos = 0; pos < code.length; pos++) {
    const char = code.charAt(pos);
    if (state == "SCAN_FUNCTION_NAME") {
      if (char == "(") {
        state = "SCAN_FUNCTION_PARAMS";
        startQoute = false;
        paramType = types.TypeUnknow;
        functionObject = {
          functionName: buff,
          param: [],
        };
        buff = "";
      } else {
        buff += char;
      }
    } else if (state == "SCAN_FUNCTION_PARAMS") {
      if (!startQoute && char == ")") {
        if (buff != "") {
          if (paramType == types.TypeUnknow) {
            paramType = guessParamType(buff);
          }
          let param: TransformFunctionParam = {
            value: buff,
            type: paramType,
          };
          functionObject.param.push(param);
        }

        functionStack.push(functionObject);

        //Reset
        paramType = types.TypeUnknow;
        buff = "";
        state = "END_FUNCTION";
      } else if (!startQoute && char == '"') {
        paramType = types.TypeString;
        startQoute = true;
      } else if (startQoute && char == '"') {
        let lastChar = buff[buff.length - 1];
        if (lastChar === "\\") {
          //Last character is a backslash
          buff = buff.slice(0, -1) + char;
        } else {
          //Last character is not a backslash
          startQoute = false;
        }
      } else if (!startQoute && char == "$") {
        paramType = types.TypeReference;
      } else if (!startQoute && char == ",") {
        if (paramType == types.TypeUnknow) {
          paramType = guessParamType(buff);
        }
        let param: TransformFunctionParam = {
          value: buff,
          type: paramType,
        };

        functionObject.param.push(param);

        //Reset
        paramType = types.TypeUnknow;
        buff = "";
      } else {
        buff += char;
      }
    } else if (state == "SCAN_DEFAULT_VALUE") {
      if (!startQoute && char == '"') {
        paramType = types.TypeString;
        startQoute = true;
      } else if (startQoute && char == '"') {
        startQoute = false;

        if (paramType == types.TypeUnknow) {
          paramType = guessParamType(buff);
        }
        let param: TransformFunctionParam = {
          value: buff,
          type: paramType,
        };

        functionStack[functionStack.length - 1].defaultValue = param;
        buff = "";
      } else if (!startQoute && char == "$") {
        paramType = types.TypeReference;
      } else if (!startQoute && char == "|") {
        paramType = types.TypeReference;
        if (buff != "") {
          if (paramType == types.TypeUnknow) {
            paramType = guessParamType(buff);
          }
          let param: TransformFunctionParam = {
            value: buff,
            type: paramType,
          };

          functionStack[functionStack.length - 1].defaultValue = param;
        }

        //Reset
        paramType = types.TypeUnknow;
        state = "SCAN_FUNCTION_NAME";
        buff = "";
      } else {
        buff += char;
      }

      if (buff == "|" || pos == code.length - 1) {
        if (buff != "") {
          if (paramType == types.TypeUnknow) {
            paramType = guessParamType(buff);
          }
          let param: TransformFunctionParam = {
            value: buff,
            type: paramType,
          };

          functionStack[functionStack.length - 1].defaultValue = param;
        }

        //Reset
        paramType = types.TypeUnknow;
        state = "SCAN_FUNCTION_NAME";
        buff = "";
      }
    } else if (state == "END_FUNCTION") {
      buff += char;

      if (buff == "?:") {
        state = "SCAN_DEFAULT_VALUE";
        buff = "";
      }

      if (buff == "|") {
        state = "SCAN_FUNCTION_NAME";
        buff = "";
      }
    }
  }

  return functionStack;
}
