import { _isEmpty, _isNil, _isUndefined } from "~/utils/lodash";
import { TimeHelper, DATEFORMAT } from "~/utils/TimeHelper";

export default class RuleHelper {
  static email = (value: string) => {
    return (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      ) || "Email is not valid"
    );
  };

  static alias = (value: string) => {
    return isAlias(value) || "Alias is invalid";
  };

  static required = (value: any) => {
    return (
      ((!!value || value === 0) && !_isUndefined(value) && !_isNil(value)) ||
      "This field is required"
    );
  };

  static multipleRequired = (value: Array<any>) => {
    return (
      (!!value && !_isUndefined(value) && !_isNil(value) && value.length > 0) ||
      "This field is required"
    );
  };

  static fileRequired = (value: File) => {
    return (
      (!!value?.name &&
        !_isUndefined(value.name) &&
        !_isNil(value.name) &&
        !_isEmpty(value.name)) ||
      "This field is required"
    );
  };

  static url = (value: any) => {
    return (
      (!!value && !_isUndefined(value) && isURL(value)) ||
      "This field must be URL"
    );
  };

  static smtp = (value: any) => {
    return (
      (!!value && !_isUndefined(value) && isSMTP(value)) ||
      "This field must be URL"
    );
  };

  static lineUri = (value: any) => {
    return (
      (!!value && !_isUndefined(value) && isURL(value)) ||
      "This field must be URL"
    );
  };

  static decimal = (value: any) => {
    return (
      (!!value && !_isUndefined(value) && isDemical(value)) ||
      "This field must be an integer or decimal number."
    );
  };

  static numRange = (value: any) => {
    return (
      (!!value && !_isUndefined(value) && isNumRange(value)) ||
      "This field must be an integer number."
    );
  };

  static validateDynamicDateRange = (dynamicDateType: string) => {
    let min = -365;
    let max = 365;
    console.log(dynamicDateType);
    switch (dynamicDateType) {
      // TODO : 12 X
      case "MONTH":
        min = -12;
        max = 12;
        break;
      case "YEAR":
        min = -100;
        max = 100;
        break;
    }
    return (value: any) =>
      (!!value && value >= min && value <= max) ||
      `This field value must in range between ${min} to ${max}`;
  };

  static min = (min: number) => {
    return (value: any) =>
      (!!value && value.length >= min) ||
      `This field value must more than ${min}`;
  };

  static max = (max: number) => {
    return (value: any) =>
      (!!value && value.length <= max) ||
      `This field value must less than ${max}`;
  };

  static confirm = (pass: string) => {
    return (value: any) =>
      (!!value && pass === value) || "This field confirmation does not match";
  };

  static num = (value: any) => {
    return (
      ((!!value || value === 0) && !_isUndefined(value) && isNum(value)) ||
      "This field must be numeric"
    );
  };

  static numMin = (min: number) => {
    return (value: number) =>
      !(value < min) || `This field value must more than ${min}`;
  };

  static numMax = (max: number) => {
    return (value: number) =>
      !(value > max) || `This field value must less than ${max}`;
  };

  static numMinIf = (condition: boolean, min1: number, min2: number) => {
    let min;
    condition ? (min = min1) : (min = min2);
    return (value: number) =>
      !(value < min) || `This field value must more than ${min}`;
  };

  static json = (value: string) => {
    try {
      JSON.parse(value);
    } catch (e) {
      return "JSON format is invalid";
    }
    return true;
  };

  static getLangRules = (lang: string) => {
    // return (value: any) => {
    //   // if(lang === 'th' || lang === 'en') {
    //   //   (!!value && !_isUndefined(value) && !_isNil(value)) || 'This field is required'
    //   // }
    // }
    // return (value: any) => (!!value && !_isUndefined(value) && !_isNil(value)) || 'This field is required'
    return (value: any) =>
      lang === "th" || lang === "en"
        ? (!!value && !_isUndefined(value) && !_isNil(value)) ||
          "This field is required"
        : "";
  };

  // Compare only time format HH:mm:ss
  static ltTime = (lTime: string) => {
    if (!lTime) {
      return () => true || "";
    }

    return (value: string) =>
      TimeHelper.isTimeBefore(value, lTime) ||
      `This time must be before ${lTime}`;
  };

  // Compare only time format HH:mm:ss
  static gtTime = (gTime: string) => {
    if (!gTime) {
      return () => true || "";
    }

    return (value: string) =>
      TimeHelper.isTimeAfter(value, gTime) ||
      `This time must be after ${gTime}`;
  };

  static requiredPrefix = (prefix: string) => {
    return (value: any) =>
      (!!value &&
        !_isUndefined(value) &&
        !_isNil(value) &&
        value.toLowerCase().startsWith(prefix.toLowerCase())) ||
      `This field is required start with ${prefix}`;
  };

  static some = (errMsg, ...rules) => {
    return function (value) {
      if (rules.some((rule) => rule(value) === true)) {
        return true;
      } else {
        return errMsg;
      }
    };
  };
}

const isAlias = (text: any): boolean => {
  return /^[a-z0-9][a-z0-9\-\_]*$[^\s]*/.test(text);
};

const isSMTP = (text: any): boolean => {
  const regex = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?::\d{1,5})?$/;
  return regex.test(text);
};

const isURL = (text: any): boolean => {
  const regex = /[\S]+[:][\/\/]*[-_0-9a-zA-Z.\/@?=%&+ก-๙]+$/;
  return regex.test(text);
};

const isNum = (text: any): boolean => {
  return /^[0-9]*$/.test(text);
};

const isNumRange = (text: any): boolean => {
  return /^-?\d*?\d+$/.test(text);
};

const isDemical = (test: any): boolean => {
  return /^\d*\.?\d*$/.test(test);
};
