import Vue from 'vue'
import VuePapaParse from 'vue-papa-parse'
import DatetimePicker from 'vuetify-datetime-picker'
import editor from 'vue2-ace-editor'
import VCalendar from 'v-calendar'

Vue.use(VuePapaParse)
Vue.use(DatetimePicker)
Vue.use(VCalendar, {
  componentPrefix: 'vc',
})

Vue.component('editor', editor)