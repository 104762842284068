
import { Component, Prop, Watch } from "vue-property-decorator";
import BaseInput from "~/core/BaseInput";
import { _isEmpty, _isUndefined } from "~/utils/lodash";

@Component
export default class AutoComplete extends BaseInput {
  @Prop() isLoading?: boolean;
  @Prop({ default: "" }) type?: "icon-header-sub" | "";
  @Prop({ default: "" }) displayValue?: string;
  @Prop({ default: "" }) searchProp!: string;
  @Prop({ default: true }) clearable!: boolean;
  @Prop() subText?: string;
  @Prop() filterF?: Function;
  @Prop({ type: Object }) tooltip?: {
    text?: string;
    alignment?: number;
    iconColor?: string;
    tooltipColor?: string;
  };

  search = null;

  @Watch("search")
  onSearch(newValue) {
    this.$emit("onSearch", newValue);
  }

  @Watch("searchProp")
  onSearchPropChange(newValue) {
    this.search = newValue;
  }

  onEmitKeycode(key: any) {
    this.$emit("keycode", key.keyCode);
  }

  get isTooltip(): boolean {
    return (
      this.tooltip !== undefined &&
      this.tooltip.text != undefined &&
      this.tooltip.text.length > 0
    );
  }
}
