import { Vue } from "vue-property-decorator";
import { commonObjectState } from "~/state/object";
import { IObjectState } from "~/state/types";
import { NewRequester } from "~/core/Requester";
import ObjectHelper from "~/utils/ObjectHelper";

interface IScriptGalleryState {
  scriptGalleryDetail: IObjectState<any>;
}

export const prefix = "script-gallery/script-gallery";

export const state = (): IScriptGalleryState => ({
  scriptGalleryDetail: commonObjectState(),
});
export const getters = {
  getDetail: (state: any) => (): any => {
    return { ...(state.scriptGalleryDetail.data || {}) };
  },
};

export const mutations = {
  updateDetail: (state: IScriptGalleryState, data) => {
    Vue.set(state, "scriptGalleryDetail", data);
  },
};

export const actions = {
  getDetail: async ({ commit, state }, data) => {
    const path = `updateDetail`;
    try {
      commit(path, ObjectHelper.toLoadingStatus(state.scriptGalleryDetail));
      const response = await NewRequester.get<any>(data.url, { ...data });
      commit(
        path,
        ObjectHelper.toObjSuccessStatus(state.scriptGalleryDetail, response.data)
      );
    } catch (e:any) {
      commit(
        path,
        ObjectHelper.toErrorStatus(
          state.scriptGalleryDetail,
          e.response?.data || e
        )
      );
    }
  },
};
