export type xPositionType = "CENTER" | "LEFT" | "RIGHT";

export const XPositionType: {
  [key in xPositionType]: xPositionType;
} = {
  CENTER: "CENTER",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
};

export type yPositionType = "CENTER" | "TOP" | "BOTTOM";

export const YPositionType: {
  [key in yPositionType]: yPositionType;
} = {
  CENTER: "CENTER",
  TOP: "TOP",
  BOTTOM: "BOTTOM",
};

export type size = "x-lg" | "lg" | "md" | "sm" | "x-sm";

export const Size: {
  [key in string]: size;
} = {
  EXLG: "x-lg",
  LG: "lg",
  MD: "md",
  SM: "sm",
  EXSM: "x-sm",
};

export default {};
