
import { Component, Prop, Vue } from "vue-property-decorator";
import { _isEmpty, _isUndefined } from "~/utils/lodash";
import { StringHelper } from "~/utils/StringHelper";

@Component
export default class CopyTextField extends Vue {
  @Prop(String) readonly value!: string;
  @Prop(String) readonly label!: string;
  @Prop({ type: Number, default: 40 }) readonly height!: number;
  @Prop({ type: String, default: "" }) readonly className!: string;

  copyAlert = false;
  alertTimeout = 1000;

  async clickCopy() {
    await StringHelper.copyToClipBoard(this.$props.value);
    this.copyAlert = true;
  }
}
