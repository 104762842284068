const prefix = '/coupon'

export const MenuCoupon = {
  home: {
    name: 'Home',
    icon: 'mdi-home',
    to: prefix
  },
  coupons: {
    name: 'Coupons',
    icon: 'mdi-ticket',
    to: prefix+'/coupons'
  }
}