var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.label !== undefined)?_c('h5',{staticClass:"grey--text text--darken-2 text-capitalize mb-1"},[_vm._v("\n    "+_vm._s(_vm.label)+"\n    "),(_vm.isRequired && _vm.label)?_c('span',{staticClass:"red--text"},[_vm._v(" *")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('vc-date-picker',{attrs:{"mode":"dateTime","attributes":_vm.attributes,"model-config":_vm.modelConfig,"max-date":_vm.max,"min-date":_vm.min,"is24hr":""},on:{"input":_vm.onFormatInput},scopedSlots:_vm._u([{key:"default",fn:function({ inputEvents, updateValue }){return [_c('v-text-field',_vm._g({staticClass:"datepicker-custom",attrs:{"rules":_vm.rules,"placeholder":_vm.placeholder ? _vm.placeholder : _vm.label,"append-icon":"mdi-calendar","color":"primary","outlined":"","dense":"","disabled":_vm.disabled},model:{value:(_vm.displayText),callback:function ($$v) {_vm.displayText=$$v},expression:"displayText"}},{
          ...inputEvents,
          input: () => {},
          change: () => {},
          blur: (e) => _vm.onBlur(e, updateValue),
          keydown: (e) => _vm.onKeydown(e, updateValue),
        }))]}}]),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }