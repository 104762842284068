
import { Component, Prop } from "vue-property-decorator";
import BaseInput from "~/core/BaseInput";
import { _isEmpty, _isUndefined } from "~/utils/lodash";

@Component
export default class Textarea extends BaseInput {
  @Prop({ default: "transparent" }) readonly backgroundColor!: string;
  @Prop({ default: "4" }) readonly rowsAmount!: string;
  @Prop() hint?: string;
  @Prop({ type: Object }) personalize?: {
    text?: string;
    pos?: number;
    disabled?: boolean;
    databases?: string[];
  };
  @Prop({ default: false }) isOptional!: boolean;
  @Prop({ default: false }) isDeletable?: boolean;
  @Prop({ default: false }) alwaysShowHint?: boolean;
  @Prop({ default: false }) warningHint?: boolean;
  @Prop({ default: false }) resizable?: boolean;
  @Prop({ default: 'default'}) density?: string;
  

  get isNoResize(): boolean {
    return !this.resizable
  }
  get isPersonalize(): boolean {
    return !(_isUndefined(this.personalize) || _isEmpty(this.personalize));
  }

  onEmitEvent(ev) {
    this.$emit("event", ev);
  }

  get textAreaClass(): Object {
    return {
      "textarea-custom": true,
      "warning-hint": this.warningHint,
    };
  }
}
