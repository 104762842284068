
import { Component, Prop, Watch } from 'vue-property-decorator'
import BaseInput from '~/core/BaseInput'

@Component
export default class Radio extends BaseInput {
  @Prop({default: true}) headerDisplayStatus!: boolean
  @Prop({default: ''}) customClass!: string
  // Component style will change, if (isCustomLabelEnabled) is true. 
  // Developer should send option.description for the proper displaying.
  @Prop({default: false}) isCustomLabelEnabled!: boolean
  @Prop({default: false}) isBackgroundEnabled!: boolean
  @Prop({default: '#FFFFFF'}) customHighlightBackgroundColor!: string
  @Prop({default: ''}) subLabel!: string
  @Prop({default: 13}) iconSize!: number
  @Prop({default: ''}) customIconStyle!: string
  @Prop({default: ''}) customLabelStyle!: string
  @Prop({default: ''}) customOptionContainerClass!: string
  
  selected = this.value || ''

  @Watch('selected')
  onSelected () {
    this.innerValue = this.selected
  }

  mounted() {
    this.innerValue = this.selected
  }
  
  get highlightBackground(): string {
    return ``
  }
  
  selectRadioContainer(selectedValue) {
    if (this.isBackgroundEnabled) {
          this.selected = selectedValue
          this.innerValue = selectedValue
    }
  }
}
