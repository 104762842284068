import Vue from 'vue'
import PageHeader from '~/features/PageHeader.vue'
import FormDialog from '~/features/FormDialog.vue'
import ListHeader from '~/features/ListHeader.vue'
import CSVPreview from '~/features/CSVPreview.vue'
import JSONPreview from '~/features/JSONPreview.vue'
import JSONBlock from '~/features/JSONBlock.vue'

Vue.component('PageHeader', PageHeader)
Vue.component('FormDialog', FormDialog)
Vue.component('ListHeader', ListHeader)
Vue.component('CSVPreview', CSVPreview)
Vue.component('JSONPreview', JSONPreview)
Vue.component('JSONBlock', JSONBlock)
