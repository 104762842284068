import Vue from "vue";
import { Core } from "~/core/Core";

async function loginADToken(ctx: any) {
  let adToken = ctx.$route?.query?.token;
  let appID = ctx.$route?.query?.application_id;
  console.log('application_id', appID)
  console.log('adToken', adToken)
  if (adToken) {
    try {
      const res = await ctx.$auth.loginADTokenLoader(ctx, adToken, appID)

      // tokenCookie.value = tokenQueryString;
      return;
    }
    catch (err) {
      console.error(err);
    }
  }
}

export default {
  loginADToken,
};
