
import {Component, Prop, Watch} from 'vue-property-decorator'
import { PageLoader } from '~/loader/page'
import { API_TYPE } from '~/constants/apiTypes'
import { Core } from '~/core/Core'
import { BaseApp } from '~/core/Base'
import { NoCache } from '~/core/Decorators'
import { keyvalue } from '~/utils/utils'
import { _isEmpty } from '~/utils/lodash'

@Component
export default class Personalize extends BaseApp {
  @Prop() text!: string
  @Prop() pos!: number
  @Prop() databases!: string[]
  @Prop({ default: false }) disabled!: boolean
  search = ''
  menu = false

  created() {
    this.contactLoader.initial()
  }

  mounted() {
    this.contactLoader.$fetch('', {
      params: {
        is_all: true, 
        filter: this.databases ? this.databases.join(",") : ''
      }
    })
  }

  @NoCache
  get tags() {
    return this.contactLoader.fetchItems || []
  }

  onTags(value) {
    if(_isEmpty(value)) return this.tags
    return this.tags.filter(item => {
      return item.name.includes(value)
    })
  }

  onTagSearch(value) {
    this.search = value
  }

  onInput(value) {
    this.$emit('input', value)
  }

  onClick(tag) {
    this.search = ''
    this.menu = false
    let res = this.text
    if(this.text?.length > this.pos && this.pos >= 0) {
      res = `${this.text.slice(0, this.pos)}{{${tag.alias}}}${this.text.slice(this.pos)}`
    }else {
      res = `${this.text ? this.text : ''}{{${tag.alias}}}`
    }
    this.$emit('personalized', res)
  }

  get contactLoader(): PageLoader {
    return new PageLoader(this, (data: any) => ({
      apiType: API_TYPE.MKT,
      baseURL: Core.MKTAPI("/customers/attributes"),
    }))
  }
}
