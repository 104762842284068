
import { Component, Watch, Prop } from "vue-property-decorator";
import BaseInput from "~/core/BaseInput";
import { v4 as uuidV4 } from "uuid";

@Component
export default class ImageFile extends BaseInput {
  @Prop(Boolean) readonly disabled!: boolean;

  progress = 0;
  dialog = false;
  imageURL = this.value;
  id = uuidV4();

  get heightStyle() {
    if (this.height) {
      return `${this.height}px`;
    }
    return false;
  }

  get classes() {
    return {
      "image-showing": !!this.imageURL,
      disabled: this.disabled,
      "active-box-border": !this.disabled,
    };
  }

  get boxClasses() {
    return {
      "box-inside-border": this.isDisplayInput,
    };
  }

  get theme() {
    return {
      "--primary": this.$vuetify.theme.themes.light.primary,
      height: this.heightStyle,
    };
  }

  onClick(e) {
    this.progress = 0;
  }

  onUploadProgress(e: any) {
    this.progress = Math.round((100 * e.loaded) / e.total);
  }

  get isDisplayInput() {
    return !this.value;
  }

  onSelectImage(url) {
    this.dialog = false;
    this.imageURL = url;
    this.onInput(url);
  }

  onSelfInput(file) {
    this.$nuxt.$emit(`on-upload-image-${this.id}`, file);
  }

  onInputClick() {
    this.dialog = true;
  }

  @Watch("value")
  onUpdateImageURL() {
    this.imageURL = this.value;
  }
}
