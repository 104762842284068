import { _get } from "~/utils/lodash";

export class ParamHelper {
  static getHomePath = (path: any): string => {
    if (!path) {
      return "/";
    }

    return path;
  };

  static getBoolTrue = (bool: any): boolean => {
    if (bool === "false") {
      return false;
    }

    if (bool === "true") {
      return true;
    }

    return bool === null ? true : !!bool;
  };

  static getBoolFalse = (bool: any): boolean => {
    if (bool === "false") {
      return false;
    }

    if (bool === "true") {
      return true;
    }

    return bool === null ? false : !!bool;
  };

  static isNotFoundError = (error: any): boolean => {
    return _get(error, `code`, true) === "NOT_FOUND";
  };

  static getNumberFromString = (text: any, defaultVal = 0): number => {
    if (text) {
      const parsed = parseInt(text);
      if (!isNaN(parsed)) {
        return parsed;
      }
    }
    return defaultVal;
  };
}
