
import {Component, Prop} from 'vue-property-decorator'
import {Base} from '~/core/Base'

@Component
export default class BoxButton extends Base {
  @Prop() title!: string
  @Prop() desc?: string
  @Prop() icon?: string
  @Prop({ default: false }) disabled?: boolean

  get theme () {
    return {
      '--primary': this.$vuetify.theme.themes.light.primary,
    }
  }
  
  get boxClass(): string {
    let baseClass = "box-border";
    if (this.disabled) {
      baseClass += ` disabled`;
    } else {
      baseClass += ` active-box-border`;
    }
    return baseClass;
  }
}
