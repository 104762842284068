
import { Component, Prop } from "vue-property-decorator";
import SweetAlertOptions from "vue-sweetalert2";
import { DeleteItemConfirm } from "~/constants/alertMsg";
import BaseInput from "~/core/BaseInput";
import RuleHelper from "~/utils/RuleHelper";
import { _isEmpty, _isUndefined } from "~/utils/lodash";

@Component
export default class Select extends BaseInput {
  @Prop({ default: true }) headerDisplayStatus!: boolean;
  @Prop(String) readonly selectorWidth!: string;
  @Prop({ type: Object }) tooltip?: {
    text?: string;
    alignment?: number;
    iconColor?: string;
    tooltipColor?: string;
  };
  @Prop({ default: false }) clearable!: boolean;

  get isHeaderShow(): any {
    return {
      display: this.headerDisplayStatus ? "initial" : "none",
    };
  }

  get isTooltip(): boolean {
    return (
      this.tooltip !== undefined &&
      this.tooltip.text != undefined &&
      this.tooltip.text.length > 0
    );
  }

  onSelfInput(value) {
    this.onInput(value);
  }

  get getLabel() {
    return this.isRulesHasRequired
      ? `${this.label} <span class="red--text">&nbsp;*</span>`
      : this.label;
  }

  get isRulesHasRequired(): boolean {
    return this.rules?.find((r) => r === RuleHelper.required);
  }
}
