import AppRepository from '~/repositories/AppRepository'
import {_get} from '~/utils/lodash'
import {Vue} from "vue-property-decorator"
import AuthRepository from "~/repositories/AuthRepository"

export default (ctx: any, inject: any) => {
  inject('app', new AppRepository(ctx))
  inject('auth', new AuthRepository(ctx))

  ctx.app.pageNumber = +_get(ctx, 'query.page', 1)
  ctx.app.query = _get(ctx, 'query', {})
}
