import { Vue } from "vue-property-decorator";
import { commonObjectState } from "~/state/object";
import { IObjectState } from "~/state/types";
import { NewRequester } from "~/core/Requester";
import ObjectHelper from "~/utils/ObjectHelper";

interface IExternalApiState {
  externalApiDetail: IObjectState<any>;
}

export const prefix = "external-api/external-api";

export const state = (): IExternalApiState => ({
  externalApiDetail: commonObjectState(),
});
export const getters = {
  getDetail: (state: any) => (): any => {
    return { ...(state.externalApiDetail.data || {}) };
  },
};

export const mutations = {
  updateDetail: (state: IExternalApiState, data) => {
    Vue.set(state, "externalApiDetail", data);
  },
};

export const actions = {
  getDetail: async ({ commit, state }, data) => {
    const path = `updateDetail`;
    try {
      commit(path, ObjectHelper.toLoadingStatus(state.externalApiDetail));
      const response = await NewRequester.get<any>(data.url, { ...data });
      commit(
        path,
        ObjectHelper.toObjSuccessStatus(state.externalApiDetail, response.data)
      );
    } catch (e) {
      commit(
        path,
        ObjectHelper.toErrorStatus(
          state.externalApiDetail,
          e.response?.data || e
        )
      );
    }
  },
};
