
import {Component, Prop} from 'vue-property-decorator'
import {Base} from '~/core/Base'

@Component
export default class ButtonVerticalDots extends Base {
  @Prop(Boolean) readonly disabled!: boolean
  @Prop({ default: 'primary' }) readonly dotsColor!: string
  @Prop({ default: 'background' }) readonly listBgColor!: string
  @Prop(Array) readonly items!: any[]
}
