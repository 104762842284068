
import {Component, Prop, Watch} from 'vue-property-decorator'
import { API_TYPE } from '~/constants/apiTypes'
import { Core } from '~/core/Core'
import { _debounce, _isEmpty, _isUndefined } from '~/utils/lodash'
import InfiniteLoading from 'vue-infinite-loading'
import { PageInfiniteLoader } from '~/loader/pageInfinite'
import { PageLoader } from '~/loader/page'
import { Base } from '~/core/Base'

@Component({components: {
  InfiniteLoading
}})
export default class ImageAssetDialog extends Base {
  @Prop() readonly inputID!: string
  @Prop({default: false}) readonly value!: boolean
  @Prop() readonly image?: string

  dialog = this.value

  page = 1
  search = ''
  imageURL: string | undefined = ''
  progress = 0
  localImageURL = ''
  tempState: any = undefined
  onSearch!: (txt) => void

  created() {
    this.onSearch = _debounce((txt) => {
      this.galleryLoader.fetchClear()
      this.page = 1
      this.galleryLoader.$fetch('', '', { params: { page : 1, q: txt}})
    }, 300)

    this.$nuxt.$on(`on-upload-image-${this.inputID}`, (file) => {
      this.localImageURL = URL.createObjectURL(file)
      this.onUploadFile(file)
    });
  }

  mounted() {
    this.galleryLoader.initial()
    this.imageLoader.initial()
    this.onSearch('')
  }

  beforeDestroy() {
    this.$nuxt.$off(`on-upload-image-${this.inputID}`)
    if(!_isUndefined(this.tempState) && !_isEmpty(this.tempState)){
      this.tempState?.reset()
      }
    this.galleryLoader.destroyLoader()
    this.imageLoader.destroyLoader()
  }

  get galleries() {
    return this.galleryLoader.fetchItems || []
  }

  get total() {
    return this.galleryLoader.fetchOptions.totalItem || 0
  }

  onClick(gal) {
    this.imageURL = gal.url
  }

  get theme () {
    return {
      '--primaryLighter': this.$vuetify.theme.themes.light.primaryLighter
    }
  }

  get isShowEmptyItem(): boolean {
    return !this.galleryLoader.fetchStatus.isLoading && _isEmpty(this.galleries)
  }

  displaySize(gal): string {
    let unit = 'Bytes'
    let size: any = gal.size
    if (size >= 1000000) {
      unit = 'MB'
      size = (+size / 1000000).toFixed(2)
    } else if (size >= 10000) {
      unit = 'KB'
      size = (+size / 1000).toFixed(2)
    }

    return `${size} ${unit}`
  }

  infiniteHandler($state) {
    this.galleryLoader.$fetch('', '', { params: { page : this.page++, q: this.search}})
    this.tempState = $state
  }

  onUploadFile(file) {
    let formData = new FormData()
    formData.append('file', file, file?.name)
    this.imageLoader.$add(formData, {onUploadProgress: this.onUploadProgress})
  }

  onUploadProgress(e: any) {
    this.progress = Math.round(100 * e.loaded / e.total)
  }

  onSelectImage() {
    this.$emit('select', this.imageURL)
  }

  onOpenBrowse() {
    document.getElementById(this.inputID)?.click()
  }

  get galleryLoader(): PageInfiniteLoader {
    return new PageInfiniteLoader(this, (data: any) => ({
      stateKey: 'gallery',
      apiType: API_TYPE.MKT,
      baseURL: Core.MKTAPI("/galleries"),

      onFetchSuccess: (_) => {
        if(!_isUndefined(this.tempState) && !_isEmpty(this.tempState)){
          this.tempState?.loaded()
          if(this.galleries.length === this.total) {
            this.tempState?.complete()
          }
        }
      },
      onFetchError: (_) => {
        if(!_isUndefined(this.tempState) && !_isEmpty(this.tempState)){
          this.tempState?.loaded()
        }
      }
    }))
  }

  get imageLoader(): PageLoader {
    return new PageLoader(this, (data: any) => ({
      stateKey: 'image-upload',
      appendKey: 'gallery',
      apiType: API_TYPE.MKT,
      baseURL: Core.MKTAPI(`/`),
      baseAdd: Core.MKTAPI(`/uploader/public`),

      callback: {
        onUploadProgress: data?.options?.onUploadProgress
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      },

      onAddSuccess: (_) => {
        this.imageURL = this.imageLoader.addItem.data.url
      },
    }))
  }

  @Watch('value')
  public onValueChanged (val: boolean) {
    this.dialog = val
  }

  @Watch('image')
  onUpdateImageURL() {
    this.imageURL = this.image
  }
}
