
import { Component, Prop } from "vue-property-decorator";
import BaseInput from "~/core/BaseInput";

@Component
export default class InputPassword extends BaseInput {
  @Prop({ type: Object }) tooltip?: {
    text?: string;
    alignment?: number;
    iconColor?: string;
    tooltipColor?: string;
  };

  passwordShow = false;

  get isTooltip(): boolean {
    return (
      this.tooltip !== undefined &&
      this.tooltip.text != undefined &&
      this.tooltip.text.length > 0
    );
  }
}
