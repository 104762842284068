
import {Component, Prop} from 'vue-property-decorator'
import {Base} from '~/core/Base'

@Component
export default class ButtonError extends Base {
  @Prop(String) readonly icon!: string
  @Prop(Boolean) readonly fullWidth!: boolean
  @Prop(Boolean) readonly iconOnly!: boolean
  @Prop({type: String, default: 'submit'}) readonly type!: string
  @Prop(Boolean) readonly disabled!: boolean
}
