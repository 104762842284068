
import {Component, Prop, Watch} from 'vue-property-decorator'
import BaseInput from '~/core/BaseInput'
import {_isEmpty, _isUndefined} from '~/utils/lodash'

@Component
export default class InputButtonGroup extends BaseInput {
  @Prop({default: ''}) subLabel!: string
  @Prop({default: false}) isFab!: boolean

  opts: {label: string, value: string, selected: boolean}[] = []

  mounted() {
    this.updateOpts()
  }

  onToggle(item) {
    item.selected = !item.selected
  }

  updateOpts() {
    this.opts = this.options?.map(opt => {
      const found = this.innerValue?.find(v => v === opt.value)
      return {label: opt.label || '', value: opt.value, selected: !!found}
    })
  }

  get theme () {
    return {
      '--primary': this.$vuetify.theme.themes.light.primary,
      '--border': this.$vuetify.theme.themes.light.border,
      '--background': this.$vuetify.theme.themes.light.background,
    }
  }

  @Watch('value')
  onUpdateValue() {
    this.updateOpts()
  }

  @Watch('opts', {deep: true})
  onUpdateInput() {
    this.onInput(this.opts?.flatMap(opt => {
      return opt.selected ? [opt.value] : []
    }))
  }
}
