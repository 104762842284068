import {Vue} from 'vue-property-decorator'
import {commonObjectState} from '~/state/object'
import {IObjectState, IStatus} from '~/state/types'
import {NewRequester} from '~/core/Requester'
import ObjectHelper from '~/utils/ObjectHelper'

interface ICampaignState {
  campaignDetail: IObjectState<any>
}

export const prefix = 'campaign/campaign'

export const state = (): ICampaignState => ({
  campaignDetail: commonObjectState(),
})
export const getters = {
  getDetail: (state: any) => (): any => {
    return { ...(state.campaignDetail.data || {}) }
  },
  getDetailStatus: (state: any) => (): IStatus => {
    return {
      isError: state.campaignDetail.isError,
      isSuccess: state.campaignDetail.isSuccess,
      isLoading: state.campaignDetail.isLoading,
      isLoaded: state.campaignDetail.isLoaded,
      errorData: state.campaignDetail.errorData,
    }
  },
}

export const mutations = {
  updateDetail: (state: ICampaignState, data) => {
    Vue.set(state, 'campaignDetail', data)
  },
}

export const actions = {
  getDetail: async ({ commit, state }, data) => {
    const path = `updateDetail`
    try {
      commit(path, ObjectHelper.toLoadingStatus(state.campaignDetail))
      const response = await NewRequester.get<any>(data.url, { ...data })
      commit(path, ObjectHelper.toObjSuccessStatus(state.campaignDetail, response.data))
    } catch (e) {
      commit(path, ObjectHelper.toErrorStatus(state.campaignDetail, e.response?.data || e))
    }
  },
}
