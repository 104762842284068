import {BaseRepository} from '~/repositories/BaseRepository'
import {
  IBreadcrumbItemState,
  IDocMeta,
  IPageMeta, IReplaceState,
  ISidebarItemState, ITheme, IUpdateState, ReplaceState,
  SaveToState, SwitchPlatform,
  UpdateDocMeta,
  UpdatePageMeta, UpdateRoute
} from '~/store/types'
import * as queryString from 'query-string'
import {menu} from '~/constants/menu'
import {_get} from "~/utils/lodash";
import {THEMES} from "~/constants/theme";

export default class AppRepository extends BaseRepository {
  public static menu(platform: string) {
    return menu(platform)
  }

  public get pageNumber(): number {
    if (process.browser) {
      const parsed = queryString.parse(window.location.search)
      return +(parsed?.page || 1)
    } else {
      return +(this.state.route.query?.page || 1)
    }
  }

  public get query(): any {
    if (process.browser) {
      return queryString.parse(window.location.search)
    } else {
      return this.state.route.query
    }
  }

  public get platform(): ITheme {
    return this.getStateByKey('theme.alias')
  }

  public get platformName(): string {
    return this.getStateByKey('theme.name')
  }

  public get pageMeta(): IPageMeta {
    return this.getStateByKey('page')
  }

  public get docMeta(): IDocMeta {
    return this.getStateByKey('doc')
  }

  public get sidebar(): ISidebarItemState[] {
    return this.getStateByKey('sidebar')
  }

  public get breadcrumb(): IBreadcrumbItemState[] {
    return this.getStateByKey('breadcrumb')
  }

  public updateRoute = (route: any): void => {
    this.commit(new UpdateRoute(route))
  }

  public updatePageMeta = (data: IPageMeta): void => {
    this.commit(new UpdatePageMeta(data))
  }

  public updateDocMeta = (data: IDocMeta): void => {
    this.commit(new UpdateDocMeta(data))
  }

  public replaceState = (key: string, data: IReplaceState): void => {
    this.commit(new ReplaceState(key, data))
  }

  public saveToState = (key: string, data: IUpdateState | any): void => {
    this.commit(new SaveToState(key, data))
  }

  public switchToMarketing = (): void => {
    this.vuetify.theme.themes.light = THEMES.MARKETING.theme
    this.vuetify.theme.themes.dark = THEMES.MARKETING.theme
    this.commit(new SwitchPlatform(THEMES.MARKETING))
  }

  public switchToEcommerce = (): void => {
    this.vuetify.theme.themes.light = THEMES.ECOMMERCE.theme
    this.vuetify.theme.themes.dark = THEMES.ECOMMERCE.theme
    this.commit(new SwitchPlatform(THEMES.ECOMMERCE))
  }

  public switchToCoupon = (): void => {
    this.vuetify.theme.themes.light = THEMES.COUPON.theme
    this.vuetify.theme.themes.dark = THEMES.COUPON.theme
    this.commit(new SwitchPlatform(THEMES.COUPON))
  }
  
  public switchToAutopilot = (): void => {
    this.vuetify.theme.themes.light = THEMES.AUTOPILOT.theme
    this.vuetify.theme.themes.dark = THEMES.AUTOPILOT.theme
    this.commit(new SwitchPlatform(THEMES.AUTOPILOT))
  }

  public switchToDefault = (): void => {
    this.vuetify.theme.themes.light = THEMES.DEFAULT.theme
    this.vuetify.theme.themes.dark = THEMES.DEFAULT.theme
    this.commit(new SwitchPlatform(THEMES.DEFAULT))
  }

  public initialTheme = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (/^\/$/g.test(this.route.path)) {
        return resolve()
      }
      try {
        if (/^\/(marketing).*$/g.test(this.route.path)) {
          this.switchToMarketing()
        } else if (/^\/(ecom).*$/g.test(this.route.path)) {
          this.switchToEcommerce()
        } else if (/^\/(coupon).*$/g.test(this.route.path)) {
          this.switchToCoupon()
        } else if (/^\/(autopilot).*$/g.test(this.route.path)) {
          this.switchToAutopilot()
        } else {
          this.switchToDefault()
        }

        return resolve()
      } catch (e) {
        return reject(e)
      }
    })
  }
}
