
import { Component, Watch } from "vue-property-decorator";
import BaseInput from "~/core/BaseInput";
import { _castArray, _head } from "~/utils/lodash";

@Component
export default class Checkbox extends BaseInput {
  selected = this.value || this.getDefaultValue;

  get getDefaultValue() {
    const value = _head(this.options).value;
    switch (typeof value) {
      case "boolean":
        return this.value || false;
      default:
        return this.options.length > 1 ? this.value || [] : this.value || "";
    }
  }

  get theme() {
    return {
      "--primaryLighter": this.$vuetify.theme.themes.light.primaryLighter,
    };
  }

  @Watch("selected", { deep: true })
  onSelected(newValue) {
    this.innerValue = newValue;
  }
}
