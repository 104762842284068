
import { Component, Prop, Watch } from "vue-property-decorator";
import { IOption } from "~/components/form/FormTypes";
import BaseInput from "~/core/BaseInput";

@Component
export default class ButtonGroup extends BaseInput {
  @Prop({ default: true }) headerDisplayStatus!: boolean;
  @Prop({ default: true })
  isMandatory!: boolean; /* There will always have one value on button group. */
  @Prop({ default: false }) isIconLastOrder!: boolean;
  @Prop({ default: false }) isMultiple!: boolean; /* Multi-Selection */
  selected = this.value || "";

  get theme() {
    return {
      "--primaryLighter": this.$vuetify.theme.themes.light.primaryLighter,
    };
  }

  @Watch("value")
  public onValueChanged(val: any) {
    this.selected = val;
  }

  @Watch("selected", { deep: true })
  onSelected(newValue: any) {
    this.innerValue = newValue;
    this.$emit("change", newValue);
  }
}
