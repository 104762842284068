
import { Component, Prop, Watch } from 'vue-property-decorator'
import BaseInput from '~/core/BaseInput'

@Component
export default class ColorPickerS extends BaseInput {
  @Prop({default: '#FFFFFF'}) readonly defaultColor!: string
  @Prop({default: ''}) readonly description!: string
  @Prop(String) readonly fixedWidth!: string

  mounted() {
    this.onInput(this.defaultColor)
  }

  color = this.defaultColor

  get swatchStyle(): any {
      return {
        backgroundColor: this.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: '4px',
        border: '1px solid #AAB2B0',
        transition: 'border-radius 200ms ease-in-out'
      }
  }
}
