
import { Component, Watch } from "vue-property-decorator";
import { BaseApp } from "~/core/Base";
import { ISidebarItemState } from "~/store/types";
import { menuToSidebar } from "~/constants/sidebar";
import AppRepository from "~/repositories/AppRepository";
import PlatformSelection from "~/components/PlatformSelection.vue";
import { availableThemes } from "~/constants/theme";
import PermissionHelper from "~/utils/PermissionHelper";
import { MenuMarketing } from "~/constants/menuMarketing";
import { MenuEcom } from "~/constants/menuEcom";
import { MenuCoupon } from "~/constants/menuCoupon";
import { MenuAutopilot } from "~/constants/menuAutopilot";
import { _get, _set } from "~/utils/lodash";
import cloneDeep from "lodash/cloneDeep";
import { Core } from "~/core/Core";

@Component({
  components: {
    PlatformSelection,
  },
})
export default class Header extends BaseApp {
  selectPlatform = false;
  isMini = true;
  expandItems = {};

  app = new AppRepository(this as any);

  themeBZB() {
    if (Core.config.MARKETING_THEME == "BZB") {
      return true
    }
    return false
  }

  changeThemeColor() {
    if (this.themeBZB()) {
      return "black--text"
    }

    return "primary--text"
  }

  changeThemeLogo() {
    if (this.themeBZB()) {
      return "/images/logo-bzb.png"
    }

    return "/images/logo-profile-no-text.png"
  }

  changeStyleThemeLogo() {
    if (this.themeBZB()) {
      return "height: 42px;"
    }

    return "height: 36px; margin-top: -4px"
  }

  get changeUIThemeMenuLogo() : boolean {
    if (this.themeBZB()) {
      return true
    }

    return false
  }

  expandMenu(item) {
    this.expandItems = item;
  }

  closeExpand() {
    this.expandItems = {};
  }

  expandMenuRoute(to) {
    this.expandItems = {};
    this.$refs.navbar.isMouseover = false;
    if (to) {
      this.$router.push(to);
    }
  }

  set title(value) {
    this.$app.saveToState("doc", {
      platformName: value,
    });
  }

  set platform(value) {
    this.$app.saveToState("doc", {
      platform: value,
    });
  }

  get calculatePositionPlatform(): number {
    return 350 * (availableThemes().length / 3);
  }

  get title() {
    return this.$app.state.app.doc.platformName;
  }

  get platform() {
    return this.$app.state.app.doc.platform;
  }

  get admin() {
    return this.$app.state.app.me?.email;
  }

  @Watch("app.state.app.theme.name")
  onPlatformNameChange(value: any, oldValue: any) {
    this.title = value;
  }

  @Watch("app.state.app.theme.alias")
  onPlatformChange(value: any, oldValue: any) {
    this.platform = value;
  }

  getSideBar(): ISidebarItemState[] {
    let platform = this.platform;
    let rawMenuObj: object = {};
    let menuObj: object = {};

    switch (this.platform) {
      case "marketing":
        rawMenuObj = MenuMarketing;
        break;
      case "ecom":
        rawMenuObj = MenuEcom;
        break;
      case "coupon":
        rawMenuObj = MenuCoupon;
        break;
      case "autopilot":
        rawMenuObj = MenuAutopilot;
        break;
      default:
    }
    if (platform === "") {
      return [];
    }
    if (rawMenuObj === {} || !rawMenuObj) {
      return [];
    }

    // Old Version
    // this.$auth.sidebarPermission.forEach((perm: string) => {
    //   let [feature, key] = PermissionHelper.getSidebarKeys(perm)
    //   feature = feature.replaceAll('-', '_')
    //   key = key.replaceAll('-', '_')
    //   const isExist = _get(rawMenuObj, `${feature}`, false)
    //   if (isExist) {
    //     _set(menuObj, `${feature}`, _get(rawMenuObj, `${feature}`, {
    //       name: feature ?? '',
    //     }))
    //     _set(menuObj, `${feature}.children.${key}`, _get(rawMenuObj, `${feature}.children.${key}`, {}))
    //   }
    // })

    let permissionMap = {};
    this.$auth.sidebarPermission.forEach((perm: string) => {
      let [feature, key] = PermissionHelper.getSidebarKeys(perm);
      permissionMap[feature + "_" + key] = true;
    });

    for (const mainMenuKey in rawMenuObj) {
      const mainMenuItem = rawMenuObj[mainMenuKey];

      if (mainMenuKey === "tool") {
        let newMainMenu = cloneDeep(mainMenuItem);
        newMainMenu["hasExpand"] = false;

        let newSubMenu = {};
        if (
          mainMenuItem.expands &&
          Object.keys(mainMenuItem.expands).length > 0
        ) {
          for (const subMenuKey in mainMenuItem.expands) {
            let subMenuItem = mainMenuItem.expands[subMenuKey];
            if (
              permissionMap[subMenuItem["permission_key"]] ||
              !subMenuItem["permission_key"] ||
              subMenuItem["permission_key"].length == 0
            ) {
              _set(newSubMenu, subMenuKey, subMenuItem);
            }
          }
        }

        if (Object.keys(newSubMenu).length > 0) {
          newMainMenu["hasExpand"] = true;
          newMainMenu["expands"] = newSubMenu;
          _set(menuObj, mainMenuKey, newMainMenu);
        }
      } else {
        let newMainMenu = cloneDeep(mainMenuItem);
        newMainMenu["hasChildren"] = false;

        // Loop through sub-menu for validate permission in new menu
        let newSubMenu = {};
        if (
          mainMenuItem.children &&
          Object.keys(mainMenuItem.children).length > 0
        ) {
          for (const subMenuKey in mainMenuItem.children) {
            let subMenuItem = mainMenuItem.children[subMenuKey];
            if (
              permissionMap[subMenuItem["permission_key"]] ||
              !subMenuItem["permission_key"] ||
              subMenuItem["permission_key"].length == 0
            ) {
              _set(newSubMenu, subMenuKey, subMenuItem);
            }
          }
        }

        if (Object.keys(newSubMenu).length > 0) {
          newMainMenu["hasChildren"] = true;
          newMainMenu["children"] = newSubMenu;
          _set(menuObj, mainMenuKey, newMainMenu);
        } else {
          // if no children check permission on main menu
          if (permissionMap[mainMenuKey + "_" + mainMenuKey]) {
            _set(menuObj, mainMenuKey, mainMenuItem);
          }
        }
      }
    }
    return menuToSidebar(menuObj) ?? [];
  }

  onClickLogout() {
    this.$auth.logout(this);
  }

  mounted() {
    this.title = this.$app.state.app.theme?.name;
    this.platform = this.$app.state.app.theme?.alias;
  }

  onUpdateProfile() {
    let userID = this.$app.state.app.me?.user_id;
    this.$router.push({
      path: `/marketing/users/${userID}/edit`,
    });
  }
}
