export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const PlatformSelection = () => import('../../components/PlatformSelection.vue' /* webpackChunkName: "components/platform-selection" */).then(c => wrapFunctional(c.default || c))
export const StyleSheet = () => import('../../components/StyleSheet.vue' /* webpackChunkName: "components/style-sheet" */).then(c => wrapFunctional(c.default || c))
export const TransformScriptCodeHilight = () => import('../../components/TransformScriptCodeHilight.vue' /* webpackChunkName: "components/transform-script-code-hilight" */).then(c => wrapFunctional(c.default || c))
export const BaseAutoComplete = () => import('../../components/base/AutoComplete.vue' /* webpackChunkName: "components/base-auto-complete" */).then(c => wrapFunctional(c.default || c))
export const BaseBoxButton = () => import('../../components/base/BoxButton.vue' /* webpackChunkName: "components/base-box-button" */).then(c => wrapFunctional(c.default || c))
export const BaseBoxContainer = () => import('../../components/base/BoxContainer.vue' /* webpackChunkName: "components/base-box-container" */).then(c => wrapFunctional(c.default || c))
export const BaseBreadcrumbs = () => import('../../components/base/Breadcrumbs.vue' /* webpackChunkName: "components/base-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonDashed = () => import('../../components/base/ButtonDashed.vue' /* webpackChunkName: "components/base-button-dashed" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonError = () => import('../../components/base/ButtonError.vue' /* webpackChunkName: "components/base-button-error" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonGroup = () => import('../../components/base/ButtonGroup.vue' /* webpackChunkName: "components/base-button-group" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonLight = () => import('../../components/base/ButtonLight.vue' /* webpackChunkName: "components/base-button-light" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonPrimary = () => import('../../components/base/ButtonPrimary.vue' /* webpackChunkName: "components/base-button-primary" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonSecondary = () => import('../../components/base/ButtonSecondary.vue' /* webpackChunkName: "components/base-button-secondary" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonVerticalDots = () => import('../../components/base/ButtonVerticalDots.vue' /* webpackChunkName: "components/base-button-vertical-dots" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckbox = () => import('../../components/base/Checkbox.vue' /* webpackChunkName: "components/base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseCodeBlock = () => import('../../components/base/CodeBlock.vue' /* webpackChunkName: "components/base-code-block" */).then(c => wrapFunctional(c.default || c))
export const BaseColorPickerS = () => import('../../components/base/ColorPickerS.vue' /* webpackChunkName: "components/base-color-picker-s" */).then(c => wrapFunctional(c.default || c))
export const BaseComboBox = () => import('../../components/base/ComboBox.vue' /* webpackChunkName: "components/base-combo-box" */).then(c => wrapFunctional(c.default || c))
export const BaseCopyTextField = () => import('../../components/base/CopyTextField.vue' /* webpackChunkName: "components/base-copy-text-field" */).then(c => wrapFunctional(c.default || c))
export const BaseDatePicker = () => import('../../components/base/DatePicker.vue' /* webpackChunkName: "components/base-date-picker" */).then(c => wrapFunctional(c.default || c))
export const BaseDateRangePicker = () => import('../../components/base/DateRangePicker.vue' /* webpackChunkName: "components/base-date-range-picker" */).then(c => wrapFunctional(c.default || c))
export const BaseDateTimePicker = () => import('../../components/base/DateTimePicker.vue' /* webpackChunkName: "components/base-date-time-picker" */).then(c => wrapFunctional(c.default || c))
export const BaseFormContainer = () => import('../../components/base/FormContainer.vue' /* webpackChunkName: "components/base-form-container" */).then(c => wrapFunctional(c.default || c))
export const BaseHTMLEditor = () => import('../../components/base/HTMLEditor.vue' /* webpackChunkName: "components/base-h-t-m-l-editor" */).then(c => wrapFunctional(c.default || c))
export const BaseImageAssetDialog = () => import('../../components/base/ImageAssetDialog.vue' /* webpackChunkName: "components/base-image-asset-dialog" */).then(c => wrapFunctional(c.default || c))
export const BaseImageExternal = () => import('../../components/base/ImageExternal.vue' /* webpackChunkName: "components/base-image-external" */).then(c => wrapFunctional(c.default || c))
export const BaseImageFile = () => import('../../components/base/ImageFile.vue' /* webpackChunkName: "components/base-image-file" */).then(c => wrapFunctional(c.default || c))
export const BaseImageFileTemp = () => import('../../components/base/ImageFileTemp.vue' /* webpackChunkName: "components/base-image-file-temp" */).then(c => wrapFunctional(c.default || c))
export const BaseInputButtonGroup = () => import('../../components/base/InputButtonGroup.vue' /* webpackChunkName: "components/base-input-button-group" */).then(c => wrapFunctional(c.default || c))
export const BaseInputFile = () => import('../../components/base/InputFile.vue' /* webpackChunkName: "components/base-input-file" */).then(c => wrapFunctional(c.default || c))
export const BaseInputHidden = () => import('../../components/base/InputHidden.vue' /* webpackChunkName: "components/base-input-hidden" */).then(c => wrapFunctional(c.default || c))
export const BaseInputPassword = () => import('../../components/base/InputPassword.vue' /* webpackChunkName: "components/base-input-password" */).then(c => wrapFunctional(c.default || c))
export const BaseInputText = () => import('../../components/base/InputText.vue' /* webpackChunkName: "components/base-input-text" */).then(c => wrapFunctional(c.default || c))
export const BaseIsolateImageAssetsDialog = () => import('../../components/base/IsolateImageAssetsDialog.vue' /* webpackChunkName: "components/base-isolate-image-assets-dialog" */).then(c => wrapFunctional(c.default || c))
export const BaseJSONEditor = () => import('../../components/base/JSONEditor.vue' /* webpackChunkName: "components/base-j-s-o-n-editor" */).then(c => wrapFunctional(c.default || c))
export const BaseLabelAlias = () => import('../../components/base/LabelAlias.vue' /* webpackChunkName: "components/base-label-alias" */).then(c => wrapFunctional(c.default || c))
export const BaseLabelTag = () => import('../../components/base/LabelTag.vue' /* webpackChunkName: "components/base-label-tag" */).then(c => wrapFunctional(c.default || c))
export const BaseLoadingCircular = () => import('../../components/base/LoadingCircular.vue' /* webpackChunkName: "components/base-loading-circular" */).then(c => wrapFunctional(c.default || c))
export const BasePagination = () => import('../../components/base/Pagination.vue' /* webpackChunkName: "components/base-pagination" */).then(c => wrapFunctional(c.default || c))
export const BasePersonalize = () => import('../../components/base/Personalize.vue' /* webpackChunkName: "components/base-personalize" */).then(c => wrapFunctional(c.default || c))
export const BaseProgressLinear = () => import('../../components/base/ProgressLinear.vue' /* webpackChunkName: "components/base-progress-linear" */).then(c => wrapFunctional(c.default || c))
export const BaseRadio = () => import('../../components/base/Radio.vue' /* webpackChunkName: "components/base-radio" */).then(c => wrapFunctional(c.default || c))
export const BaseRandomTokenGenerator = () => import('../../components/base/RandomTokenGenerator.vue' /* webpackChunkName: "components/base-random-token-generator" */).then(c => wrapFunctional(c.default || c))
export const BaseSelect = () => import('../../components/base/Select.vue' /* webpackChunkName: "components/base-select" */).then(c => wrapFunctional(c.default || c))
export const BaseSelection = () => import('../../components/base/Selection.vue' /* webpackChunkName: "components/base-selection" */).then(c => wrapFunctional(c.default || c))
export const BaseStepperTypes = () => import('../../components/base/StepperTypes.ts' /* webpackChunkName: "components/base-stepper-types" */).then(c => wrapFunctional(c.default || c))
export const BaseSteppers = () => import('../../components/base/Steppers.vue' /* webpackChunkName: "components/base-steppers" */).then(c => wrapFunctional(c.default || c))
export const BaseSwitchToggle = () => import('../../components/base/SwitchToggle.vue' /* webpackChunkName: "components/base-switch-toggle" */).then(c => wrapFunctional(c.default || c))
export const BaseTableSimple = () => import('../../components/base/TableSimple.vue' /* webpackChunkName: "components/base-table-simple" */).then(c => wrapFunctional(c.default || c))
export const BaseTableTypes = () => import('../../components/base/TableTypes.ts' /* webpackChunkName: "components/base-table-types" */).then(c => wrapFunctional(c.default || c))
export const BaseTextarea = () => import('../../components/base/Textarea.vue' /* webpackChunkName: "components/base-textarea" */).then(c => wrapFunctional(c.default || c))
export const BaseTimePicker = () => import('../../components/base/TimePicker.vue' /* webpackChunkName: "components/base-time-picker" */).then(c => wrapFunctional(c.default || c))
export const BaseTransformScript = () => import('../../components/base/TransformScript.vue' /* webpackChunkName: "components/base-transform-script" */).then(c => wrapFunctional(c.default || c))
export const BaseUploadImage = () => import('../../components/base/UploadImage.vue' /* webpackChunkName: "components/base-upload-image" */).then(c => wrapFunctional(c.default || c))
export const BaseTypes = () => import('../../components/base/types.ts' /* webpackChunkName: "components/base-types" */).then(c => wrapFunctional(c.default || c))
export const FormField = () => import('../../components/form/Field.vue' /* webpackChunkName: "components/form-field" */).then(c => wrapFunctional(c.default || c))
export const FormFieldCondition = () => import('../../components/form/FieldCondition.vue' /* webpackChunkName: "components/form-field-condition" */).then(c => wrapFunctional(c.default || c))
export const FormTypes = () => import('../../components/form/FormTypes.ts' /* webpackChunkName: "components/form-types" */).then(c => wrapFunctional(c.default || c))
export const Tab = () => import('../../components/tab/Tab.vue' /* webpackChunkName: "components/tab" */).then(c => wrapFunctional(c.default || c))
export const TabNav = () => import('../../components/tab/TabNav.vue' /* webpackChunkName: "components/tab-nav" */).then(c => wrapFunctional(c.default || c))
export const TabTypes = () => import('../../components/tab/TabTypes.ts' /* webpackChunkName: "components/tab-types" */).then(c => wrapFunctional(c.default || c))
export const Table = () => import('../../components/table/Table.vue' /* webpackChunkName: "components/table" */).then(c => wrapFunctional(c.default || c))
export const TableCard = () => import('../../components/table/TableCard.vue' /* webpackChunkName: "components/table-card" */).then(c => wrapFunctional(c.default || c))
export const TableHelpers = () => import('../../components/table/TableHelpers.ts' /* webpackChunkName: "components/table-helpers" */).then(c => wrapFunctional(c.default || c))
export const TableTypes = () => import('../../components/table/TableTypes.ts' /* webpackChunkName: "components/table-types" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
