
import {Component} from 'vue-property-decorator'
import BaseInput from '~/core/BaseInput'

@Component
export default class UploadImage extends BaseInput {
  get theme () {
    return {
      '--primaryLight': this.$vuetify.theme.themes.light.primaryLight
    }
  }

  onUpload (event) {
    document.getElementById('output')!.setAttribute( 'src', URL.createObjectURL(event.target.files[0]))
    document.getElementById('upload')!.setAttribute( 'style', 'display: none;')
    this.innerValue = URL.createObjectURL(event.target.files[0])
  }
}
