import {CONFIG} from '~/constants/config'
const prefix = '/autopilot'


export const MenuAutopilot = {
  home: {
    name: 'Dashboard',
    icon: 'mdi-view-dashboard',
    order: 0,
    hasChildren: true,
    children: {
      datail: {
        name: 'Dashboard',
        icon: 'mdi-view-dashboard',    
        to: prefix,
        permission_key: 'home_dashboard'
      }
    }
  },
}
