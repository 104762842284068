import { Vue } from "vue-property-decorator";
import { commonObjectState } from "~/state/object";
import { IObjectState } from "~/state/types";
import { NewRequester } from "~/core/Requester";
import ObjectHelper from "~/utils/ObjectHelper";

interface IDynamicReportState {
  dashboardDetail: IObjectState<any>;
}

export const prefix = "visualization/dashboard";

export const state = (): IDynamicReportState => ({
  dashboardDetail: commonObjectState(),
});
export const getters = {
  getDetail: (state: any) => (): any => {
    return { ...(state.dashboardDetail.data || {}) };
  },
};

export const mutations = {
  updateDetail: (state: IDynamicReportState, data) => {
    Vue.set(state, "dashboardDetail", data);
  },
};

export const actions = {
  getDetail: async ({ commit, state }, data) => {
    const path = `updateDetail`;
    try {
      commit(path, ObjectHelper.toLoadingStatus(state.dashboardDetail));
      const response = await NewRequester.get<any>(data.url, { ...data });
      commit(
        path,
        ObjectHelper.toObjSuccessStatus(state.dashboardDetail, response.data)
      );
    } catch (e) {
      commit(
        path,
        ObjectHelper.toErrorStatus(state.dashboardDetail, e.response?.data || e)
      );
    }
  },
};
