import {Middleware} from '~/node_modules/@nuxt/types'
import { Core } from "~/core/Core"
import { TimeHelper } from "~/utils/TimeHelper";

const auth: Middleware = async (ctx) => {


  // let adToken = ctx.route.query.token;
  // let appID = ctx.route.query.application_id;
  // if (adToken) {
  //   try {
  //     console.log('application_id2', appID)
  //     console.log('adToken2', adToken)
  //     // await ctx.app.$auth.loginADTokenLoader(ctx, adToken, appID)
  //     // ctx.app.$auth.loginADTokenLoader1.$add({})
  //     // const response = await axios.post("https://stgx-mkt.pams.ai/api/backend/login-by-ad-token", {
  //     //   app_id: appID,
  //     //   ad_token: adToken,
  //     // }, {});
  //     let headers: { [key: string]: any } = {
  //       "x-timestamp": TimeHelper.toUTC(TimeHelper.getCurrentDateTime()),
  //       "x-device": "browser",
  //     };

  //     const response = await ctx.app.$auth.loginAD(ctx, { app_id: appID, ad_token: adToken})
  //     console.log('response', response)

  //     // const config = {
  //     //   headers: headers,
  //     // };

  //     // if (headers) {
  //     //   config["headers"] = headers
  //     // }

  //     // console.log('config', config)
  //     // const response = axios.post(`https://stgx-mkt.pams.ai/api/backend/login-by-ad-token`, { app_id: appID, ad_token: adToken,} ?? {}, config)
  //     // console.log('response', response)
  //     // ctx.app.$auth.loginADTokenLoader1.initial()
  //     //ctx.app.$auth.loginADTokenLoader1.$fetch()

  //     // console.log('res', res)
  //     // ctx.app.$cookies.set("me", "xddd")
  //     // console.log('ctx.app.$cookies', ctx.app.$cookies)

  //     // tokenCookie.value = tokenQueryString;

  //     // return;
  //   }
  //   catch (err) {
  //     console.error(err);
  //   }
  // }

  // console.log("auth")
  // console.log("ENABLED_SKIP_PAGE_PLATFORM_SELECTION", Core.config.ENABLED_SKIP_PAGE_PLATFORM_SELECTION)
  // console.log("ctx.route.query.token", ctx.route.query.token)
  // console.log("ctx.route.query.application_id", ctx.route.query.application_id)
  if (Core.config.ENABLED_SKIP_PAGE_PLATFORM_SELECTION) {
    let adToken = ctx.route.query.token;
    let appID = ctx.route.query.application_id;
    let passParam = {}

    if (adToken) {
      passParam = {...passParam, token: adToken}
    }
    if (appID) {
      passParam = {...passParam, application_id: appID}
    }

    // console.log("ctx.route.path", ctx.route.path)
    // console.log("passParam", passParam)
    if (ctx.route.path == '/') {
      return ctx.redirect(`/marketing`, passParam)
    }
  }

  // console.log("ctx.route.path 2", ctx.route.path)
  if (ctx.app.$auth.isNotAuth && !ctx.app.$cookies.get('me')?.token) {

    if (ctx.app.$auth.enabledFeatureLoginADToken) {
      let adToken = ctx.route.query.token;
      let appID = ctx.route.query.application_id;
      let passParam = {}
      if (adToken) {
        passParam = {...passParam, token: adToken}
      }
      if (appID) {
        passParam = {...passParam, application_id: appID}
      }

      if (Core.config.ENABLED_SKIP_PAGE_PLATFORM_SELECTION) {
        if (ctx.route.path == '/') {
          return ctx.redirect(`/marketing`, passParam)
        }
      }
      return

    }

    return ctx.redirect(`/login`, {
      ...(ctx.route.path && ctx.route.path !== '/' && {redirect: ctx.route.path})
    })
  }
}

export default auth
