
import { Component, Prop, Watch } from "vue-property-decorator";
import BaseInput from "~/core/BaseInput";

@Component
export default class SwitchToggle extends BaseInput {
  @Prop({ default: false }) readonly isActive!: boolean;
  @Prop({ default: false }) readonly isInForm!: boolean;
  @Prop({ default: () => false }) readonly showPrefix!: boolean;
  @Prop() subText?: string;

  selected = this.isActive;

  @Watch("selected")
  onSelected() {
    this.innerValue = this.selected;
  }

  @Watch("isActive")
  onActiveChanged() {
    this.selected = this.isActive;
  }

  mounted() {
    this.innerValue = this.selected;
  }

  get getSelectedClasses() {
    return {
      "green--text text--darken-3": this.selected,
      "red--text text--darken-3": !this.selected,
    };
  }
}
