import { Vue } from "vue-property-decorator";
import { commonObjectState } from "~/state/object";
import { IObjectState } from "~/state/types";
import { NewRequester } from "~/core/Requester";
import ObjectHelper from "~/utils/ObjectHelper";

interface IShortURLState {
  shortURLDetail: IObjectState<any>;
}

export const prefix = "short-url/short-url";

export const state = (): IShortURLState => ({
  shortURLDetail: commonObjectState(),
});
export const getters = {
  getDetail: (state: any) => (): any => {
    return { ...(state.shortURLDetail.data || {}) };
  },
};

export const mutations = {
  updateDetail: (state: IShortURLState, data) => {
    Vue.set(state, "shortURLDetail", data);
  },
};

export const actions = {
  getDetail: async ({ commit, state }, data) => {
    const path = `updateDetail`;
    try {
      commit(path, ObjectHelper.toLoadingStatus(state.shortURLDetail));
      const response = await NewRequester.get<any>(data.url, { ...data });
      commit(
        path,
        ObjectHelper.toObjSuccessStatus(state.shortURLDetail, response.data)
      );
    } catch (e:any) {
      commit(
        path,
        ObjectHelper.toErrorStatus(
          state.shortURLDetail,
          e.response?.data || e
        )
      );
    }
  },
};
