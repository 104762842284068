import { MenuMarketing } from '~/constants/menuMarketing'
import { MenuEcom } from '~/constants/menuEcom'
import { MenuCoupon } from '~/constants/menuCoupon'

export const menu = (platform: string) => {
  if (platform==='marketing')
  return MenuMarketing
  else if (platform==='ecom')
  return MenuEcom
  else if (platform==='coupon')
  return MenuCoupon
}
