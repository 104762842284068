import {IOption} from "~/components/form/FormTypes";

export interface IPermissionItem {
  permission_id: string
  permission_key: string
  is_enabled: boolean
  created_date: string
  updated_date: string
  deleted_date: string
}

export interface IPermissionForm {
  permission_all: string
  permission_keys: IOption[]
}

export const CustomerDatabaseFormPermissionOption = {
  VIEW: 'view',
  EDIT: 'edit',
  EXPORT: 'export',
  DELETE: 'delete',
}

export interface ICustomerDatabaseFormPermissionOption {
  title: string
  view: boolean
  edit: boolean
  export: boolean
  delete: boolean
}

export interface ICustomerDatabaseFormPermission {
  [key: string]: ICustomerDatabaseFormPermissionOption
}

export interface ICustomerDatabaseForm {
  title: string
  alias: string
  data_permission: ICustomerDatabaseFormPermission
  media_permission: ICustomerDatabaseFormPermission
  demographic_permission: ICustomerDatabaseFormPermission
}

export const PERMISSION_PREFIX = {
  DATABASE: 'customer-database',
  SIDEBAR: 'sidebar-permission'
}

export const PERMISSION_TYPE = {
  DATA_PERMISSION: 'data-permission',
  MEDIA_PERMISSION: 'media-permission',
  DEMOGRAPHIC_PERMISSION: 'demographic-permission'
}

export declare type CustomerDatabaseType = 'IMPORT' | 'LOGIN' | 'PUBLIC'

export interface IMediaSettingOptionItem {
  is_key: boolean
  is_used: boolean
  is_encrypted: boolean
}

export interface IMediaSettingItem {
  email: IMediaSettingOptionItem
  sms: IMediaSettingOptionItem
  line: IMediaSettingOptionItem
  facebook: IMediaSettingOptionItem
  web_notification: IMediaSettingOptionItem
  customer: IMediaSettingOptionItem
}

export interface ICustomerDemoGraphicItem {
  id: string
  name: string
  alias: string
  description: string
  is_enabled: string
  view_permission_stat: number
  edit_permission_stat: number
  delete_permission_stat: number
  created_at: string
  updated_at: string
  default_value: string
  is_required: boolean
  exportable?: boolean
}

export interface ICustomerDatabaseItem {
  id: string
  alias: string
  name: string
  description: string
  type: CustomerDatabaseType
  attributes: ICustomerDemoGraphicItem[]
  media_setting: IMediaSettingItem
  created_date: string
  updated_date: string
  deleted_date: string
}

export interface IMediaSetting {
  api_key: string
  api_secret: string
  welcome_message: string
}

export interface IMediaType {
  id: string
  name: string
  alias: string
  description: string
  is_enabled: boolean
  created_at: string
  updated_at: string
  delete_at: string
}

export interface IMediaItem {
  id: string
  name: string
  alias: string
  description: string
  is_enabled: boolean
  is_public_update_allow: boolean
  is_public_update_confirm: boolean
  is_require_token_public_update: boolean
  media_account: any
  media_account_id: string
  media_type: IMediaType
  setting: IMediaSetting
  created_at: string
  updated_at: string
  delete_at: string
}

export const permissionMockItems: IPermissionItem[] = [
  {
    permission_id: 'p01',
    permission_key: 'sidebar-permission_customer_customers_view',
    is_enabled: true,
    created_date: '2021-01-19 18:30:45',
    updated_date: '2021-01-19 18:39:45',
    deleted_date: '',
  },
  {
    permission_id: 'p05',
    permission_key: 'sidebar-permission_segment_segments_view',
    is_enabled: true,
    created_date: '2021-01-19 18:30:45',
    updated_date: '2021-01-19 18:39:45',
    deleted_date: '',
  },
  {
    permission_id: 'p06',
    permission_key: 'sidebar-permission_campaign_campaigns_view',
    is_enabled: true,
    created_date: '2021-01-19 18:30:45',
    updated_date: '2021-01-19 18:39:45',
    deleted_date: '',
  },
  {
    permission_id: 'p07',
    permission_key: 'sidebar-permission_report_reports_view',
    is_enabled: true,
    created_date: '2021-01-19 18:30:45',
    updated_date: '2021-01-19 18:39:45',
    deleted_date: '',
  },
  {
    permission_id: 'p08',
    permission_key: 'sidebar-permission_pdpa_pdpas_view',
    is_enabled: true,
    created_date: '2021-01-19 18:30:45',
    updated_date: '2021-01-19 18:39:45',
    deleted_date: '',
  }
]

export const customerDatabaseMockItems: ICustomerDatabaseItem[] = [
  {
    id: 'c01',
    alias: 'c01-alias',
    name: 'Boodabest CRM',
    description: '',
    type: 'IMPORT',
    attributes: [],
    media_setting: {
      email: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
      sms: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
      line: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
      facebook: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
      web_notification: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
      customer: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
    },
    created_date: '2021-01-20 13:00:00',
    updated_date: '2021-01-20 13:00:00',
    deleted_date: '',
  },
  {
    id: 'c02',
    alias: 'c02-alias',
    name: 'Boodabest Login',
    description: '',
    attributes: [],
    type: 'IMPORT',
    media_setting: {
      email: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
      sms: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
      line: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
      facebook: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
      web_notification: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
      customer: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
    },
    created_date: '2021-01-20 13:00:00',
    updated_date: '2021-01-20 13:00:00',
    deleted_date: '',
  },
  {
    id: 'c03',
    alias: 'c03-alias',
    name: 'Boodabest Form Submit',
    description: '',
    attributes: [],
    type: 'IMPORT',
    media_setting: {
      email: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
      sms: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
      line: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
      facebook: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
      web_notification: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
      customer: {
        is_key: true,
        is_used: true,
        is_encrypted: true,
      },
    },
    created_date: '2021-01-20 13:00:00',
    updated_date: '2021-01-20 13:00:00',
    deleted_date: '',
  },
]
