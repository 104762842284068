
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { CONFIG } from "~/constants/config";
import { convertValueToInt } from "~/utils/utils";

@Component
export default class Pagination extends Vue {
  @Prop() readonly totalItems!: number;
  @Prop() readonly currentPage!: number | string;
  @Prop({ default: CONFIG.LIMIT_PER_PAGE }) readonly limitPerPage!: number;

  page = 1;

  @Watch("currentPage")
  onCurrentPageChange() {
    const tempP = convertValueToInt(this.currentPage);
    this.page = tempP > 0 ? tempP : 1;
  }

  @Watch("page")
  onPageChange() {
    this.$emit("change-page", this.page);
  }

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.limitPerPage);
  }

  get summaryPaginate(): string {
    return (
      "Showing " +
      this.startItemOfPage +
      "-" +
      this.endItemOfPage +
      " of " +
      this.totalItems +
      " item(s)."
    );
  }

  get startItemOfPage(): number {
    let cp: number = 1;
    if (typeof this.currentPage === "number") {
      cp = this.currentPage;
    } else {
      cp = parseInt(this.currentPage);
    }
    return (cp - 1) * this.limitPerPage + 1;
  }

  get endItemOfPage(): number {
    let cp: number = 1;
    if (typeof this.currentPage === "number") {
      cp = this.currentPage;
    } else {
      cp = parseInt(this.currentPage);
    }
    let end = cp * this.limitPerPage;
    return end > this.totalItems ? this.totalItems : end;
  }
}
