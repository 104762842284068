
import { Component, Prop } from "vue-property-decorator";
import { Base } from "~/core/Base";

@Component
export default class CodeBlock extends Base {
  @Prop({ default: "" }) readonly codeText!: string;
  @Prop({ default: false }) readonly usePre!: boolean;
  @Prop({ default: [] }) readonly hilight!: string[];

  get code() {
    let txt = this.codeText;

    this.hilight.forEach((str) => {
      var regex = new RegExp(str, "gi");
      txt = txt.replace(regex, function (matched) {
        return '<span class="hilight">' + matched + "</span>";
      });
    });

    return txt;
  }
}
