export enum PermissionPrefix {
  CUSTOMER_DATABASE = 'customer-database',
  SIDEBAR_PERMISSION = 'sidebar-permission'
}

export enum PermissionType {
  DATA_PERMISSION = 'data-permission',
  MEDIA_PERMISSION = 'media-permission',
  DEMOGRAPHIC_PERMISSION = 'demographic-permission',
}

export enum PermissionAction {
  VIEW = 'view',
  EDIT = 'edit',
  DELETE = 'delete',
  EXPORT = 'export'
}
