import Vue from "vue";
import * as Msal from "@azure/msal-browser";
import { Core } from "~/core/Core";
import { LocalStorageHelper } from "~/utils/LocalStorageHelper";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
const msalConfig = {
  auth: {
    clientId: Core.config.MSAL_CLIENT_ID,
    authority: Core.config.MSAL_AUTHORITY,
    redirectUri: Core.config.MSAL_REDIRECT_URI,
  },
  cache: {
    // cacheLocation: "sessionStorage", // This configures where your cache will be stored
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    secureCookies: false,
  },
  system: {
    allowRedirectInIframe: true,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case Msal.LogLevel.Error:
            console.error(message);
            return;
          case Msal.LogLevel.Info:
            console.info(message);
            return;
          case Msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case Msal.LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const loginRequest = {
  scopes: ["user.read"],
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
const tokenRequest = {
  // scopes: ["User.Read", "Mail.Read", "Group.Read.All", "GroupMember.Read.All"],
  scopes: [
    "Team.ReadBasic.All",
    "TeamSettings.Read.All",
    "TeamSettings.ReadWrite.All",
    "User.Read.All",
    "Directory.Read.All",
    "User.ReadWrite.All",
    "Directory.ReadWrite.All",
  ],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

const myMSALObj = new Msal.PublicClientApplication(msalConfig, Vue);

Vue.use(Msal, msalConfig);

// export default ({ app, error, $axios }, inject) => {
//     inject('msal', new msal.PublicClientApplication(
//         msalConfig, Vue /* [optional] should be passed as an argument if you want to the framework.globalMixin option*/
//     ))
//     signIn()
//   }

// export default ({ app, error, $axios }, inject) => {
//     inject('msal', myMSALObj /* [optional] should be passed as an argument if you want to the framework.globalMixin option*/
//     ),
//     signIn
//   }

// export default (ctx: any, inject: any) => {
//     inject('msal', myMSALObj),
// }

async function selectAccount() {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */

  let currentAccounts = await myMSALObj.getAllAccounts();
  if (currentAccounts.length === 0) {
  } else if (currentAccounts.length > 1) {
    // Add choose account code here
    console.warn("Multiple accounts detected.");
  } else if (currentAccounts.length === 1) {
    // username = currentAccounts[0].username;
    return currentAccounts[0];
  }
}

async function signIn() {
  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */

  let res: any = {};
  await myMSALObj
    .loginPopup(loginRequest)
    .then(function (authResponse) {
      if (authResponse !== null) {
        console.log("authResponse", authResponse);
        res = {
          accessToken: authResponse.accessToken,
          account: authResponse.account,
        };
        return res;
      }
    })
    .catch(function (error) {
      //login failure
      console.log(error);
    });

  return res;
}

async function signOut() {
  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */

  const me = LocalStorageHelper.get("me");
  if (me?.email) {
    let account = await myMSALObj.getAccountByUsername(me.email);
    if (account !== null) {
      let logoutRequest = {
        account: account,
        // postLogoutRedirectUri: msalConfig.auth.redirectUri,
        // mainWindowRedirectUri: msalConfig.auth.redirectUri
      };

      myMSALObj.logoutPopup(logoutRequest);
    }
  }

  // myMSALObj.logoutRedirect({
  //     onRedirectNavigate: (url) => {
  //         // Return false if you would like to stop navigation after local logout
  //         return false;
  //     }
  // });

  // await myMSALObj.logoutRedirect({
  //     account: account,
  //     postLogoutRedirectUri: "https://contoso.com/loggedOut"
  // });

  // Automatically on page load
  // myMSALObj.logoutRedirect({
  //     onRedirectNavigate: () => {
  //         // Return false to stop navigation after local logout
  //         return false;
  //     }
  // });
}

export default {
  signIn,
  selectAccount,
  signOut,
};
