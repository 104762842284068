
import {Component, Prop} from 'vue-property-decorator'
import {Base} from '~/core/Base'

@Component
export default class LabelAlias extends Base {
  @Prop(String) readonly className!: string

  copy() {
    let copyText = this.$slots!.default![0].text
    const el = document.createElement('textarea')
    el.value = copyText || ''
    document.body.appendChild(el)
    el.select();
    document.execCommand('copy')
    document.body.removeChild(el)
    this.$toast.show('Copied: ' + copyText, {
      icon: 'assignment_turned_in',
      duration: 1000
    })
  }
}
