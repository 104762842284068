
import { EAllowDateType } from "~/components/form/FormTypes";
import { _join } from "~/utils/lodash";
import { Component, Prop, Watch } from "vue-property-decorator";
import BaseInput from "~/core/BaseInput";
import { TimeHelper, DATETIMEFORMAT } from "~/utils/TimeHelper";

const TIMEFORMAT = "HH:mm";

@Component
export default class DateTimePicker extends BaseInput {
  @Prop({ default: undefined }) max!: string;
  @Prop({ default: undefined }) min!: string;

  menu = false;
  date: any = TimeHelper.isDateTimeValid(this.value) ? this.value : "";
  displayText = this.displayDateTime(this.date);

  get attributes() {
    return [
      {
        key: "today",
        bar: true,
        dates: new Date(),
      },
    ];
  }

  get modelConfig() {
    return {
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD HH:mm:ss",
      },
    };
  }

  async onBlur(e, updateF) {
    this.date = await updateF(e.target.value);
    this.displayText = this.displayDateTime(this.date);
  }

  displayDateTime(datetime) {
    if (datetime) {
      return TimeHelper.getDateTimeFromDateTime(datetime);
    }
    return "";
  }

  onFormatInput(datetime) {
    const dateTimeFormat = this.displayDateTime(datetime);
    this.displayText = dateTimeFormat;
    this.onInput(dateTimeFormat);
  }

  onKeydown(e, updateF) {
    if (e.keyCode === 13) {
      this.onBlur(e, updateF);
    }
  }

  @Watch("value")
  onValueChange() {
    if (!this.value) {
      this.displayText = "";
    }
  }
}
