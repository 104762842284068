const prefix = '/ecom'

export const MenuEcom = {
  home: {
    name: 'Home',
    icon: 'mdi-home',
    to: prefix
  },
  products: {
    name: 'Products',
    icon: 'mdi-package',
    to: prefix+'/products'
  },
  member: {
    name: 'Members',
    icon: 'mdi-account',
    hasChildren: true,
    order: 1,
    children: {
      members: {
        name: 'All Members',
        icon: 'mdi-account-group',
        to: prefix + '/members',
        // permission_key: 'ecom_members'
        permission_key: 'campaign-journey_campaign-journeys'
      },
      import: {
        name: 'Import',
        icon: 'mdi-account-arrow-left',
        to: prefix + '/members/import',
        // permission_key: 'ecom_member_import'
        permission_key: 'customer_import'
      }
    }
  },
  edit_member: {
    name: 'Edit Member',
    icon: 'mdi-account-group',
    isHide: true
  },
  voucher: {
    name: 'Vouchers',
    icon: 'mdi-ticket',
    order: 2,
    hasChildren: true,
    children: {
      vouchers: {
        name: 'Vouchers',
        icon: 'mdi-ticket',    
        to: prefix + '/vouchers',
        // permission_key: 'ecom_vouchers'
        permission_key: 'campaign-journey_campaign-journeys'
      },
    }
  },
  create_voucher: {
    name: 'Create Voucher',
    icon: 'mdi-ticket',
    isHide: true
  },
  edit_voucher: {
    name: 'Edit Voucher',
    icon: 'mdi-ticket',
    isHide: true
  },
}