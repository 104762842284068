import {TimeHelper} from '~/utils/TimeHelper';
import {Vue} from 'vue-property-decorator';
import {CookieHelper} from "~/utils/CookieHelper";

export class LocalStorageHelper {
  static set(key: string, data: any) {
    const client = LocalStorageHelper.getLocalClient()
    if (client) {
      client.setItem(key, JSON.stringify({
        ...data,
        local_storage_expire: TimeHelper.addSecondToDateTime(TimeHelper.getCurrentDateTime(), 60 * 60 * 24 * 7)
      }))
    }
  }

  static get(key: string) {
    const client = LocalStorageHelper.getLocalClient()
    if (client) {
      const data = JSON.parse(client.getItem(key) || '{}')
      if (TimeHelper.isAfter(data.local_storage_expire, TimeHelper.getCurrentDateTime())) {
        Vue.delete(data, 'local_storage_expire')
        return data
      } else {
        if (data.local_storage_expire) {
          client.removeItem(key)
        }
      }
      return JSON.parse('{}')
    }
    return null
  }

  private static getLocalClient(): Storage | null {
    return window?.localStorage
  }
}
