import { ISidebarItemState } from "~/store/types";
import ObjectHelper from "~/utils/ObjectHelper";
import { _sortBy } from "~/utils/lodash";

export const menuToSidebar = (menuItem: object): ISidebarItemState[] => {
  let sidebarItems: ISidebarItemState[] = [];

  if (!menuItem) {
    return [];
  }

  for (let [, value] of Object.entries(menuItem!)) {
    let menu: ISidebarItemState = value;

    // If menu has one child and parent's name and child's name are same ( 's' is not included ) then we will merge to one object
    if (menu.hasChildren && ObjectHelper.getLength(menu.children) === 1) {
      for (const [, value] of Object.entries(menu?.children || {})) {
        if (!!value && value?.name?.includes(menu?.name)) {
          menu = {
            ...value,
            order: menu.order,
          };
        }
      }
    }

    menu = {
      ...menu,
      children: ObjectHelper.filter(menu.children, (o) => !!o?.to && !!o?.name),
    };

    sidebarItems.push({
      ...menu,
    });
  }

  return _sortBy(sidebarItems, (o) => o.order);
};
