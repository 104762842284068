import { EventInput } from "~/core/BaseInput"

export const INPUT_TYPES = {
  BUTTON_GROUP: "BUTTON_GROUP",
  INPUT_BUTTON_GROUP: "INPUT_BUTTON_GROUP",
  TEXT: "TEXT",
  FILE: "FILE",
  IMAGE: "IMAGE",
  IMAGE_EXTERNAL: "IMAGE_EXTERNAL",
  ALIAS: "ALIAS",
  PASSWORD: "PASSWORD",
  TEXTAREA: "TEXTAREA",
  JSONEDITOR: "JSONEDITOR",
  SELECT: "SELECT",
  AUTOCOMPLETE: "AUTOCOMPLETE",
  COMBOBOX: "COMBOBOX",
  CHECKBOOK: "CHECKBOX",
  RADIO: "RADIO",
  SWITCH_TOGGLE: "SWTICH_TOGGLE",
  DATEPICKER: "DATEPICKER",
  DATETIMEPICKER: "DATETIMEPICKER",
  TIMEPICKER: "TIMEPICKER",
  UPLOAD_IMAGE: "UPLOAD_IMAGE",
  COLOR_PICKER_S: "COLOR_PICKER_S",
  HTMLEDITOR: "HTMLEDITOR",
  SELECTION: "SELECTION",
  COPY_TEXT_FIELD: "COPY_TEXT_FIELD",
  COMPONENT: "COMPONENT",
  RANDOM_TOKEN_STRING_GENERATOR: "RANDOM_TOKEN_STRING_GENERATOR",
  TRANSFORM_SCRIPT: "TRANSFORM_SCRIPT",
  DIVIDER: "DIVIDER",
  HIDDEN: "HIDDEN",
}

export interface IOption {
  label?: string
  value: any
  header?: string
  subHeader?: string
  disabled?: boolean
  divider?: boolean
  icon?: string
}

export enum EAllowDateType {
  ALL = "ALL",
  CURRENT = "CURRENT",
}

export interface FunctionMap {
  [key: string]: Function
}

export interface Direction  { 
  row?: boolean
  column?: boolean 
}

export interface IFormOption {
  type: string
  component?: any
  description?: string
  className?: string
  col?: string
  offset?: string
  isHide?: boolean
  children?: IFormOption[]
  permissionKey?: string
  props?: {
    key?: string
    rules?: Function[]
    className?: string
    name: string
    transform?: (event: EventInput | any, oldValue: any) => any
    options?: IOption[] | any[]
    multiple?: boolean // auto complete
    direction?: Direction // radio
    range?: boolean // date picker
    allowDate?: EAllowDateType.ALL | EAllowDateType.CURRENT | string // date picker (string format. YYYY-MM-DD)
    defaultValue?: any
    label?: string
    placeholder?: string
    disabled?: boolean
    isActive?: boolean
    isLoading?: boolean
    height?: number
    on?: FunctionMap

    [key: string]: any
  }
}

export default {}
