
import { Component, Prop } from "vue-property-decorator";
import { BaseApp } from "~/core/Base";
import { ALL_THEME, ACTIVE_THEMES } from "~/constants/theme";
import { parseScript } from "~/transform-script-engine";
@Component
export default class TransformScriptCodeHilight extends BaseApp {
  @Prop({ type: String, default: () => "" }) readonly code!: string;

  get codeHilight() {
    const stack = parseScript(this.code);

    let css = `<style>
        .function-name {
            color: #000;
        }
        .string-param {
            color: #7CFF89;
        }
        .bool-param {
            color: #0500FF;
        }
        .ref-param{
            color: #BB108B;
        }
        .number-param{
            color: #fff;
        }
        .pipe{
            color: #f00;
        }
</style>`;

    let resultHTML: string[] = [];

    stack.forEach((t) => {
      let params: string[] = [];

      t.param.forEach((p) => {
        if (p.type === "string") {
          params.push(
            `<span class="string-param">"${p.value.replaceAll(
              '"',
              '\\"'
            )}"</span>`
          );
        } else if (p.type === "boolean") {
          params.push(`<span class="bool-param">${p.value}</span>`);
        } else if (p.type === "reference") {
          params.push(`<span class="ref-param">$${p.value}</span>`);
        } else {
          params.push(`<span class="number-param">${p.value}</span>`);
        }
      });

      let defaultVal = "";
      if (t.defaultValue) {
        if (t.defaultValue.type === "string") {
          defaultVal = `?:<span class="string-param">"${t.defaultValue.value}"</span>`;
        } else if (t.defaultValue.type === "boolean") {
          defaultVal = `?:<span class="bool-param">${t.defaultValue.value}</span>`;
        } else if (t.defaultValue.type === "reference") {
          defaultVal = `?:<span class="ref-param">$${t.defaultValue.value}</span>`;
        } else {
          defaultVal = `?:<span class="number-param">${t.defaultValue.value}</span>`;
        }
      }

      resultHTML.push(
        `<span class="function-name">${t.functionName}</span>(${params.join(
          ", "
        )})${defaultVal}`
      );
    });
    return css + resultHTML.join(` <span class="pipe">|</span> <br /> `);
  }
}
