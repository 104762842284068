import {commonObjectState} from "~/state/object";
import {IObjectState} from "~/state/types";
import {Vue} from 'vue-property-decorator'
import ObjectHelper from "~/utils/ObjectHelper";
import {NewRequester} from "~/core/Requester";

interface ICampaignJourneyStatState {
    campaignJourneyStatDetail: IObjectState<any>
}

export const prefix = 'campaignJourney/campaignJourneyStat'

export const state = (): ICampaignJourneyStatState => ({
    campaignJourneyStatDetail: commonObjectState(),
})

export const getters = {
    getStatDetail: (state: any) => (): any => {
        return { ...(state.campaignJourneyStatDetail.data || {}) }
    },
}

export const mutations = {
    updateStatDetail: (state: ICampaignJourneyStatState, data) => {
        Vue.set(state, 'campaignJourneyStatDetail', data)
    },
}

export const actions = {
    getStatDetail: async ({ commit, state }, data) => {
        const path = `updateStatDetail`
        try {
            commit(path, ObjectHelper.toLoadingStatus(state.campaignJourneyStatDetail))
            const response = await NewRequester.get<any>(data.url, { ...data })
            commit(path, ObjectHelper.toObjSuccessStatus(state.campaignJourneyStatDetail, response.data))
        } catch (e) {
            commit(path, ObjectHelper.toErrorStatus(state.campaignJourneyStatDetail, e.response?.data || e))
        }
    },
}
