export enum types {
  TypeString = "string",
  TypeInt = "int",
  TypeFloat = "float",
  TypeBoolean = "boolean",
  TypeReference = "reference",
  TypeUnknow = "unknow",
}

export interface TransformFunctionParam {
  value: string;
  type: string;
}

export interface TransformFunctionStatement {
  functionName: string;
  param: TransformFunctionParam[];
  defaultValue?: TransformFunctionParam;
}

export interface TransformFunctionExecutor {
  getFunctionName(): string;
  getFunctionParams(): TransformFunctionParam[];
  getDefaultValue(): TransformFunctionParam | undefined;
  validate(): boolean;
  invoke(input: string, allValues: { [key: string]: string }): string;
}
