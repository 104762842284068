
import {Component, Prop, Watch} from 'vue-property-decorator'
import { Base } from '~/core/Base'
import { IStepperItem, IStepperOptions } from './StepperTypes'
import { Size, XPositionType } from './types'

@Component
export default class Steppers extends Base {
  @Prop() readonly steppers!: IStepperItem[]
  @Prop() current!: number
  @Prop() options?: IStepperOptions
  @Prop({ default: true }) readonly showCompletedIcon!: boolean

  get componentOptions(): object {
    let opts = {}
    if(this.options) {
      // Positioning
      if(this.options['x-posiiton'] === XPositionType.CENTER) opts = {...opts, 'ma-auto': true}
      else if(this.options['x-posiiton'] === XPositionType.LEFT) opts = {...opts, 'ml-auto': true}
      else if(this.options['x-posiiton'] === XPositionType.RIGHT) opts = {...opts, 'mr-auto': true}

      if(this.options.size === Size.EXLG) opts = {...opts, 'width-100': true}
      else if(this.options.size === Size.LG) opts = {...opts, 'width-75': true}
      else if(this.options.size === Size.MD) opts = {...opts, 'width-50': true}
      else if(this.options.size === (Size.EXSM || Size.SM)) opts = {...opts, 'width-25': true}
      
    }
    return opts
  }

  textColorPrimary(value: number): object {
    return {
      'primary--text': this.current >= value
    }
  }

  get theme () {
    return {
      '--primaryLighter': this.$vuetify.theme.themes.light.primaryLighter,
      '--primaryLight': this.$vuetify.theme.themes.light.primaryLight
    }
  }

  eventClick(v) {
    this.$emit('change', v)
  }
}
