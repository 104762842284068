import {Middleware} from '@nuxt/types'
import {SwitchPlatform} from "~/store/types";
import {THEMES} from "~/constants/theme";

const route: Middleware = async (ctx: any) => {

  ctx.app.store.commit(new SwitchPlatform(THEMES.DEFAULT))
  if (/^\/(marketing).*$/g.test(ctx.route.path)) {
    ctx.app.store.commit(new SwitchPlatform(THEMES.MARKETING))
  } else if (/^\/(ecom).*$/g.test(ctx.route.path)) {
    ctx.app.store.commit(new SwitchPlatform(THEMES.ECOMMERCE))
  } else if (/^\/(coupon).*$/g.test(ctx.route.path)) {
    ctx.app.store.commit(new SwitchPlatform(THEMES.COUPON))
  } else if (/^\/(autopilot).*$/g.test(ctx.route.path)) {
    ctx.app.store.commit(new SwitchPlatform(THEMES.AUTOPILOT))
  }
}

export default route
