import {Vue} from 'vue-property-decorator'
import {commonObjectState} from '~/state/object'
import {IObjectState} from '~/state/types'
import {NewRequester} from '~/core/Requester'
import ObjectHelper from '~/utils/ObjectHelper'

interface IUserState {
  userDetail: IObjectState<any>
}

export const prefix = 'user/user'

export const state = (): IUserState => ({
  userDetail: commonObjectState(),
})
export const getters = {
  getDetail: (state: any) => (): any => {
    return { ...(state.userDetail.data || {}) }
  },
}

export const mutations = {
  updateDetail: (state: IUserState, data) => {
    Vue.set(state, 'userDetail', data)
  },
}

export const actions = {
  getDetail: async ({commit, state}, data) => {
    const path = `updateDetail`
    try {
      commit(path, ObjectHelper.toLoadingStatus(state.userDetail))
      const response = await NewRequester.get<any>(data.url, {...data})
      commit(path, ObjectHelper.toObjSuccessStatus(state.userDetail, response.data))
    }catch(e) {
      commit(path, ObjectHelper.toErrorStatus(state.userDetail, e.response?.data || e))
    }
  },
}
