import {Vue} from 'vue-property-decorator'
import {commonObjectState} from '~/state/object'
import {IObjectState} from '~/state/types'
import {NewRequester} from '~/core/Requester'
import ObjectHelper from '~/utils/ObjectHelper'

interface IConsentMessageState {
  consentMessageDetail: IObjectState<any>
}

export const prefix = 'consent-message/consent-message'

export const state = (): IConsentMessageState => ({
  consentMessageDetail: commonObjectState(),
})
export const getters = {
  getDetail: (state: any) => (): any => {
    return { ...(state.consentMessageDetail.data || {}) }
  },
}

export const mutations = {
  updateDetail: (state: IConsentMessageState, data) => {
    Vue.set(state, 'consentMessageDetail', data)
  },
}

export const actions = {
  getDetail: async ({commit, state}, data) => {
    const path = `updateDetail`
    try {
      commit(path, ObjectHelper.toLoadingStatus(state.consentMessageDetail))
      const response = await NewRequester.get<any>(data.url, {...data})
      commit(path, ObjectHelper.toObjSuccessStatus(state.consentMessageDetail, response.data))
    }catch(e) {
      commit(path, ObjectHelper.toErrorStatus(state.consentMessageDetail, e.response?.data || e))
    }
  },
}
