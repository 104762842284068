
import { Component, Prop, Watch } from 'vue-property-decorator'
import BaseInput from '~/core/BaseInput'

@Component
export default class Selection extends BaseInput {

  @Prop() settings!: any[]

  selected = this.defaultValue || ''

  @Watch('selected')
  onSelected () {
    this.innerValue = this.selected
    this.$emit('change', this.selected)
  }

  mounted() {
    this.innerValue = this.selected
  }

  uncheck(i: number) {
    const curVal = this.$refs['inner_radio'][i].value
    if (this.multiple === true) {
      const i = this.selected.indexOf(curVal)
      if (i>=0) {
        const deselected = this.selected.filter((value)=>{
          return value !== curVal
        })
        setTimeout(()=>{this.selected = deselected}, 100)
      }
    }
  }

  radioItemIconClass(item: any): string {
    if (item.description != undefined && item.description.length > 0) {
      return 'mt-2'
    }
    return ''
  }

  radioItemIconName(item: any): string {
    if (this.value === null) {
      return item.inactiveIcon
    }

    // If multiple, this.value is array
    // Otherwise, this.value is string
    if (this.multiple) {
      const i = this.value.indexOf(item.value)
      if (i >= 0) {
        return item.activeIcon
      }
    } else {
      if (item.value == this.value) {
        return item.activeIcon
      }
    }
    
    return item.inactiveIcon
  }

  radioItemClassName(item: any): string {
    const activeClass = 'radio-item'
    const inactiveClass = 'radio-item background'
    if (this.value === null) {
      return inactiveClass
    }

    // If multiple, this.value is array
    // Otherwise, this.value is string
    if (this.multiple) {
      const i = this.value.indexOf(item.value)
      if (i >= 0) {
        return activeClass
      }
    } else {
      if (item.value == this.value) {
        return activeClass
      }
    }

    return inactiveClass
  }

}
