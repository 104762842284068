import axios from "axios";

export interface APIResponse {
  error: boolean;
  errorMessage?: string;
  errorCode?: string;
  data?: any;
}
export class SeamanHTTP {
  async postAndResolveRef(
    url: string,
    jsonBody: { [key: string]: any } | undefined = undefined,
    headers: { [key: string]: any } | undefined = undefined
  ): Promise<APIResponse> {
    const config = {
      headers: headers,
    };

    if (headers) {
      config["headers"] = headers;
    }

    try {
      const response = await axios.post(url, jsonBody ?? {}, config);

      //Resolving REF
      if (response.data.code === "REF") {
        let finalResponse: any;

        const refRequestHeader = {
          headers: {
            ...headers,
            ref: response.data.ref,
          },
        };

        for (let i = 0; i <= 500; i++) {
          const responseRef = await axios.get(url, refRequestHeader);

          if (responseRef.data.code === "PROCESSING") {
            await this.delay(1000);
            continue;
          }
          finalResponse = responseRef;
          break;
        }
        if (finalResponse) {
          return finalResponse;
        }
        return {
          error: true,
          errorMessage: "Request Timeout",
          errorCode: "TIMEOUT",
        };
      } else {
        return {
          error: false,
          data: response.data,
        };
      }
    } catch (err: any) {
      return {
        error: true,
        errorMessage: err.response.data.message,
        errorCode: err.response.data.code,
      };
    }
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
