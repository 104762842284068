
import { Component, Prop } from "vue-property-decorator";
import {
  XPositionType,
  xPositionType,
  yPositionType,
} from "~/components/base/types";
import { Base } from "~/core/Base";

@Component
export default class BoxContainer extends Base {
  @Prop({ default: "" }) title!: string;
  @Prop({ default: "" }) title2?: string;
  @Prop({ default: "" }) descriptionInfo!: string;
  @Prop({ default: "mdi-cog" }) icon!: string;
  @Prop({ default: true }) isIcon!: boolean;
  @Prop({ default: true }) isTitleHeader!: boolean;
  @Prop({ default: true }) isBoxShow!: boolean;
  @Prop({ default: false }) isHover!: string;
  @Prop({ default: false }) isShowCloseBtn!: boolean;
  @Prop({ default: true }) isExpand!: boolean;
  @Prop() xPosition?: xPositionType;
  @Prop() yPosition?: yPositionType;
  @Prop({ default: false }) enableOverayBtn!: boolean;
  @Prop({ default: true }) initShowOverayBtn!: boolean;

  expand = this.isExpand;
  absolute = true;
  showOverayBtn = this.initShowOverayBtn;

  xPositionClass(pos: xPositionType | undefined): string {
    let positionClass = "";
    switch (pos) {
      case XPositionType.CENTER:
        positionClass = "justify-center";
        break;
      case XPositionType.LEFT:
        positionClass = "justify-start";
        break;
      case XPositionType.RIGHT:
        positionClass = "justify-end";
        break;
    }
    return positionClass;
  }

  yPositionClass(pos: yPositionType | undefined): string {
    let positionClass = "";
    switch (pos) {
      case XPositionType.CENTER:
        positionClass = "align-center";
        break;
      case XPositionType.LEFT:
        positionClass = "align-left";
        break;
      case XPositionType.RIGHT:
        positionClass = "align-right";
        break;
    }
    return positionClass;
  }

  get classes() {
    let cls = {
      "hover-text": this.isHover,
    };
    const xPos = this.xPositionClass(this.xPosition);
    cls[xPos] = true;
    const yPos = this.yPositionClass(this.yPosition);
    cls[yPos] = true;
    return cls;
  }

  onClick() {
    if (this.isHover) {
      this.expand = !this.expand;
    }
  }

  onEnable() {
    this.showOverayBtn = false;
    this.$emit("onEnable");
  }

  onClickClose() {
    if (this.enableOverayBtn) {
      this.showOverayBtn = true;
    }
    this.$emit("remove");
  }
}
