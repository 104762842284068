import {commonObjectState} from "~/state/object";
import {IObjectState} from "~/state/types";
import {Vue} from 'vue-property-decorator'
import ObjectHelper from "~/utils/ObjectHelper";
import {NewRequester} from "~/core/Requester";

interface ICustomerJourneyState {
    customerJourneyDetail: IObjectState<any>
}

export const prefix = 'customerJourney/customerJourney'

export const state = (): ICustomerJourneyState => ({
    customerJourneyDetail: commonObjectState(),
})

export const getters = {
    getDetail: (state: any) => (): any => {
        return { ...(state.customerJourneyDetail.data || {}) }
    },
}

export const mutations = {
    updateDetail: (state: ICustomerJourneyState, data) => {
        Vue.set(state, 'customerJourneyDetail', data)
    },
}

export const actions = {
    getDetail: async ({ commit, state }, data) => {
        const path = `updateDetail`
        try {
            commit(path, ObjectHelper.toLoadingStatus(state.customerJourneyDetail))
            const response = await NewRequester.get<any>(data.url, { ...data })
            commit(path, ObjectHelper.toObjSuccessStatus(state.customerJourneyDetail, response.data))
        } catch (e) {
            commit(path, ObjectHelper.toErrorStatus(state.customerJourneyDetail, e.response?.data || e))
        }
    },
}
