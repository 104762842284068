import Vue from "vue"
import Field from "~/components/form/Field.vue"
import CodeBlock from "~/components/base/CodeBlock.vue"
import ButtonPrimary from "~/components/base/ButtonPrimary.vue"
import ButtonSecondary from "~/components/base/ButtonSecondary.vue"
import ButtonLight from "~/components/base/ButtonLight.vue"
import ButtonError from "~/components/base/ButtonError.vue"
import ButtonDashed from "~/components/base/ButtonDashed.vue"
import ButtonVerticalDots from "~/components/base/ButtonVerticalDots.vue"
import ButtonGroup from "~/components/base/ButtonGroup.vue"
import InputText from "~/components/base/InputText.vue"
import InputFile from "~/components/base/InputFile.vue"
import InputPassword from "~/components/base/InputPassword.vue"
import Textarea from "~/components/base/Textarea.vue"
import Select from "~/components/base/Select.vue"
import AutoComplete from "~/components/base/AutoComplete.vue"
import Checkbox from "~/components/base/Checkbox.vue"
import Radio from "~/components/base/Radio.vue"
import SwitchToggle from "~/components/base/SwitchToggle.vue"
import DatePicker from "~/components/base/DatePicker.vue"
import DateTimePicker from "~/components/base/DateTimePicker.vue"
import DateRangePicker from "~/components/base/DateRangePicker.vue"
import LabelTag from "~/components/base/LabelTag.vue"
import LabelAlias from "~/components/base/LabelAlias.vue"
import Breadcrumbs from "~/components/base/Breadcrumbs.vue"
import TimePicker from "~/components/base/TimePicker.vue"
import UploadImage from "~/components/base/UploadImage.vue"
import ColorPickerS from "~/components/base/ColorPickerS.vue"
import HTMLEditor from "~/components/base/HTMLEditor.vue"
import Table from "~/components/table/Table.vue"
import TableCard from "~/components/table/TableCard.vue"
import Pagination from "~/components/base/Pagination.vue"
import LoadingCircular from "~/components/base/LoadingCircular.vue"
import BoxContainer from "~/components/base/BoxContainer.vue"
import BoxButton from "~/components/base/BoxButton.vue"
import ComboBox from "~/components/base/ComboBox.vue"
import Personalize from "~/components/base/Personalize.vue"
import ImageExternal from "~/components/base/ImageExternal.vue"
import ImageFile from "~/components/base/ImageFile.vue"
import ImageAssetDialog from "~/components/base/ImageAssetDialog.vue"
import InputButtonGroup from "~/components/base/InputButtonGroup.vue"
import Steppers from "~/components/base/Steppers.vue"
import Selection from "~/components/base/Selection.vue"
import ProgressLinear from "~/components/base/ProgressLinear.vue"
import CopyTextField from "~/components/base/CopyTextField.vue"
import RandomTokenGenerator from "~/components/base/RandomTokenGenerator.vue"
import TransformScript from "~/components/base/TransformScript.vue"
import InputHidden from "~/components/base/InputHidden.vue"
import JSONEditor from "~/components/base/JSONEditor.vue"

Vue.component("RandomTokenGenerator", RandomTokenGenerator)
Vue.component("CopyTextField", CopyTextField)
Vue.component("Field", Field)
Vue.component("CodeBlock", CodeBlock)
Vue.component("ButtonPrimary", ButtonPrimary)
Vue.component("ButtonSecondary", ButtonSecondary)
Vue.component("ButtonLight", ButtonLight)
Vue.component("ButtonError", ButtonError)
Vue.component("ButtonDashed", ButtonDashed)
Vue.component("ButtonGroup", ButtonGroup)
Vue.component("ButtonVerticalDots", ButtonVerticalDots)
Vue.component("InputText", InputText)
Vue.component("InputFile", InputFile)
Vue.component("InputPassword", InputPassword)
Vue.component("Textarea", Textarea)
Vue.component("Select", Select)
Vue.component("AutoComplete", AutoComplete)
Vue.component("Checkbox", Checkbox)
Vue.component("Radio", Radio)
Vue.component("SwitchToggle", SwitchToggle)
Vue.component("DatePicker", DatePicker)
Vue.component("DateRangePicker", DateRangePicker)
Vue.component("DateTimePicker", DateTimePicker)
Vue.component("LabelTag", LabelTag)
Vue.component("LabelAlias", LabelAlias)
Vue.component("Breadcrumbs", Breadcrumbs)
Vue.component("TimePicker", TimePicker)
Vue.component("UploadImage", UploadImage)
Vue.component("ColorPickerS", ColorPickerS)
Vue.component("HTMLEditor", HTMLEditor)
Vue.component("Table", Table)
Vue.component("TableCard", TableCard)
Vue.component("Pagination", Pagination)
Vue.component("LoadingCircular", LoadingCircular)
Vue.component("BoxContainer", BoxContainer)
Vue.component("BoxButton", BoxButton)
Vue.component("ComboBox", ComboBox)
Vue.component("Personalize", Personalize)
Vue.component("ImageExternal", ImageExternal)
Vue.component("ImageFile", ImageFile)
Vue.component("ImageAssetDialog", ImageAssetDialog)
Vue.component("InputButtonGroup", InputButtonGroup)
Vue.component("Steppers", Steppers)
Vue.component("Selection", Selection)
Vue.component("ProgressLinear", ProgressLinear)
Vue.component("TransformScript", TransformScript)
Vue.component("InputHidden", InputHidden)
Vue.component("JSONEditor", JSONEditor)