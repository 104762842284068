const middleware = {}

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['notAuth'] = require('../middleware/notAuth.ts')
middleware['notAuth'] = middleware['notAuth'].default || middleware['notAuth']

middleware['route'] = require('../middleware/route.ts')
middleware['route'] = middleware['route'].default || middleware['route']

export default middleware
