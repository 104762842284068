
import {Component} from 'vue-property-decorator'
import {BaseApp} from '~/core/Base'
import { ALL_THEME, ACTIVE_THEMES } from "~/constants/theme";
import { CookieHelper } from "~/utils/CookieHelper";

@Component
export default class PlatformSelection extends BaseApp {
  get activePlatforms(): string {
    return ACTIVE_THEMES
  }

  get allPlatforms(): any[] {
    return ALL_THEME
  }

  redirect(to) {
    try {
      const urlObj = new URL(to);
      const token = JSON.parse(CookieHelper.get("me") || "{}")?.["token"];
      urlObj.searchParams.set("token", token);
      return urlObj.toString();
    }
    catch (_) {
      return "/marketing"
    }
  }
}
