
import { Component, Prop, Watch } from "vue-property-decorator";
import BaseInput from "~/core/BaseInput";
import { TimeHelper, DATEFORMAT } from "~/utils/TimeHelper";

@Component
export default class DatePicker extends BaseInput {
  @Prop({ default: undefined }) max!: string;
  @Prop({ default: undefined }) min!: string;
  @Prop({ default: false }) deselectable!: boolean;

  menu = false;
  date: any = this.value;
  displayText = this.displayDate(this.date);

  get masks() {
    return {
      date: new Date(),
      masks: {
        input: "HH:mm",
      },
    };
  }

  get attributes() {
    return [
      {
        key: "today",
        bar: true,
        dates: new Date(),
      },
    ];
  }

  get modelConfig() {
    return {
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
    };
  }

  async onBlur(e, updateF) {
    this.date = await updateF(e.target.value);
    this.displayText = this.displayDate(this.date);
  }

  displayDate(date) {
    if (date) {
      return TimeHelper.getDateFromTime(date, DATEFORMAT);
    }
    return "";
  }

  onFormatInput(date) {
    const dateFormat = this.displayDate(date);
    this.displayText = dateFormat;
    this.onInput(dateFormat);
  }

  onKeydown(e, updateF) {
    if (e.keyCode === 13) {
      this.onBlur(e, updateF);
    }
  }

  @Watch("value")
  onValueChange() {
    if (!this.value) {
      this.date = "";
      this.displayText = "";
    }
  }
}
