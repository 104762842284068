import { IStatus } from "~/state/types";

export interface IParam {
  limit?: number;

  [key: string]: any;
}

export interface IFetchOption {
  params?: IParam;
  options?: object;
}

export interface IFindOption {
  params?: IParam;
}

export interface ILoaderOption {
  apiType?: string;
  params?: IParam;
  headers?: object;
  callback?: object;

  baseURL: string;
  baseAdd?: string;
  baseFind?: string;
  baseUpdate?: string;
  baseDelete?: string;

  transformFetch?: (data: any[]) => any[];
  transformGet?: (data: any) => any;

  stateKey?: string;
  appendKey?: string;
  cookieKey?: string;

  mockFindItem?: any;
  mockFetchItems?: any[];

  onAddSuccess?: (status: IStatus, response?: any) => void;
  onAddError?: (status: IStatus) => void;
  onFetchSuccess?: (status: IStatus) => void;
  onFetchError?: (status: IStatus) => void;
  onFindSuccess?: (status: IStatus) => void;
  onFindError?: (status: IStatus) => void;
  onUpdateSuccess?: (status: IStatus, options?: IParam) => void;
  onUpdateError?: (status: IStatus) => void;
  onDeleteSuccess?: (status: IStatus) => void;
  onDeleteError?: (status: IStatus) => void;
}

declare class IParamData {
  public params: object;
}

export declare class IAddData {
  public options: IParam;
}

export declare class IFetchData extends IParamData {
  public q: string;
  public options: IFetchOption;
}

export declare class IFetchInfiniteData extends IParamData {
  public page: string;
  public q: string;
  public options: IFetchOption;
}

export declare class IFindData extends IParamData {
  public id: string;
  public options: IFindOption;
}

export declare class IUpdateData {
  public id: string;
  public options: IParam;
}

export declare class IDeleteData {
  public id: string;
}

export class LoaderAddData implements IAddData {
  constructor(public options: IParam = {}) {}
}

export class LoaderFetchData implements IFetchData {
  public params: object = {};
  public opts: object = {};

  constructor(public q: string = "", public options: IFetchOption = {}) {
    this.params = {
      q,
      ...options.params,
    };
    this.opts = {
      ...options.options,
    };
  }
}

export class LoaderFetchInfiniteData implements IFetchInfiniteData {
  public params: object = {};

  constructor(
    public page: string = "",
    public q: string = "",
    public options: IFetchOption = {}
  ) {
    this.params = {
      page,
      q,
      ...options.params,
    };
  }
}

export class LoaderFindData implements IFindData {
  public params: object = {};

  constructor(public id: string, public options: IFindOption = {}) {
    this.params = {
      ...options.params,
    };
  }
}

export class LoaderUpdateData implements IUpdateData {
  constructor(public id: string, public options: IParam = {}) {}
}

export class LoaderDeleteData implements IDeleteData {
  constructor(public id: string) {
    this.id = id;
  }
}

export declare type LoaderData =
  | LoaderAddData
  | LoaderFetchData
  | LoaderFetchInfiniteData
  | LoaderFindData
  | LoaderUpdateData
  | LoaderDeleteData;
