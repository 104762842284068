
import { Component, Prop } from 'vue-property-decorator'
import {Base} from '~/core/Base'

@Component
export default class JSONBlock extends Base {
  @Prop({ default: null }) item!: any;
  @Prop({ default: 0 }) level!: number;

  checkType(obj: any) {
    if (obj === null) {
      return 'null'
    }

    const type = typeof obj;

    if (type === 'object') {
      if (Array.isArray(obj)) {
        return 'array'
      }
      try {
        JSON.stringify(obj)
        return 'JSON'
      } catch (e) {
        return 'object'
      }
    }

    return type;
  }

}
