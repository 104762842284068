import { CONFIG } from "~/constants/config";
const prefix = "/marketing";

export const MenuMarketing = {
  home: {
    name: "Dashboard",
    icon: "mdi-view-dashboard",
    order: 0,
    hasChildren: true,
    children: {
      campaigns: {
        name: "Dashboard",
        icon: "mdi-view-dashboard",
        to: prefix,
        permission_key: "home_dashboard",
      },
    },
  },

  customer: {
    name: "Customers",
    icon: "mdi-account",
    to: prefix + "/customers",
    group: prefix + "/customers",
    hasChildren: true,
    order: 1,
    children: {
      customers: {
        name: "All Customers",
        icon: "mdi-account-group",
        to: prefix + "/customers",
        permission_key: "customer_customers",
      },
      linked_customers: {
        name: "Linked Customers",
        icon: "mdi-link-variant",
        to: prefix + "/customers/linked",
        permission_key: "customer_customers",
      },
      databases: {
        name: "Customer Databases",
        icon: "mdi-database",
        to: prefix + "/customers/database",
        permission_key: "customer_databases",
      },
      import: {
        name: "Import",
        icon: "mdi-account-arrow-left",
        to: prefix + "/customers/import",
        permission_key: "customer_import",
      },
    },
  },
  create_customer: {
    name: "Create Customer",
    icon: "mdi-account",
    isHide: true,
  },
  edit_customer: {
    name: "Edit Customer",
    icon: "mdi-account",
    isHide: true,
  },
  import_customer: {
    name: "Import Customers",
    icon: "mdi-account",
    to: prefix + "/customers/import",
    isHide: true,
  },
  tag_customer: {
    name: "Customers Tags",
    icon: "mdi-account",
    to: prefix + "/customers/tags",
    isHide: true,
  },
  attribute_customer: {
    name: "Customer Attributes",
    icon: "mdi-account",
    to: prefix + "/customers/attributes",
    isHide: true,
  },
  customer_databases: {
    name: "Customer Databases",
    icon: "mdi-database",
    to: prefix + "/customers/database",
    isHide: true,
  },
  create_customer_database: {
    name: "Create Customer Database",
    icon: "mdi-database",
    to: prefix + "/customers/database/create",
    isHide: true,
  },
  edit_customer_database: {
    name: "Edit Customer Database",
    icon: "mdi-database",
    isHide: true,
  },
  edit_customer_database_demographic: {
    name: "Edit Customer Demographic",
    icon: "mdi-database",
    isHide: true,
  },

  master_data: {
    name: "Master Data",
    icon: "mdi-database-sync",
    to: prefix + "/master-data",
    group: prefix + "/master-data",
    hasChildren: true,
    order: 2,
    children: {
      master_data: {
        name: "All Data",
        icon: "mdi-file-table-box-multiple",
        to: prefix + "/master-data",
        permission_key: "customer_customers",
      },
      master_data_databases: {
        name: "Master Data Databases",
        icon: "mdi-database",
        to: prefix + "/master-data/database",
        permission_key: "customer_databases",
      },
      import: {
        name: "Import",
        icon: "mdi-database-arrow-left",
        to: prefix + "/master-data/import",
        permission_key: "customer_import",
      },
    },
  },
  create_master_data: {
    name: "Create Master Data",
    icon: "mdi-databese-sync",
    isHide: true,
  },
  edit_master_data: {
    name: "Edit Master Data",
    icon: "mdi-account",
    isHide: true,
  },
  import_master_data: {
    name: "Import Master Data",
    icon: "mdi-account",
    to: prefix + "/master-data/import",
    isHide: true,
  },
  tag_master_data: {
    name: "Master Data Tags",
    icon: "mdi-account",
    to: prefix + "/master-data/tags",
    isHide: true,
  },
  attribute_master_data: {
    name: "Master Data Attributes",
    icon: "mdi-account",
    to: prefix + "/master-data/attributes",
    isHide: true,
  },
  master_data_databases: {
    name: "Customer Databases",
    icon: "mdi-database",
    to: prefix + "/master-data/database",
    isHide: true,
  },
  create_master_data_database: {
    name: "Create Customer Database",
    icon: "mdi-database",
    to: prefix + "/master-data/database/create",
    isHide: true,
  },
  edit_master_data_database: {
    name: "Master Data Database",
    icon: "mdi-database",
    isHide: true,
  },
  // edit_customer_database_demographic: {
  //   name: "Edit Customer Demographic",
  //   icon: "mdi-database",
  //   isHide: true,
  // },

  import: {
    name: "Migration",
    icon: "mdi-file-import",
    to: prefix + "/import",
    group: prefix + "/import",
    hasChildren: true,
    order: 3,
    children: {
      import_demographic: {
        name: "Import Demographic",
        icon: "mdi-account-arrow-left",
        to: prefix + "/migration/demographic",
        permission_key: "import_demographic",
      },
      import_tracker: {
        name: "Import Tracker",
        icon: "mdi-shoe-print",
        to: prefix + "/migration/tracker",
        permission_key: "import_tracker",
      },
      import_consent_permission: {
        name: "Import Consent",
        icon: "mdi-shield-plus",
        to: prefix + "/migration/consent",
        permission_key: "import_consent",
      },
      data_flow: {
        name: "Data Flow",
        icon: "mdi-connection",
        to: prefix + "/migration/data-flow",
        permission_key: "import_data-adapter",
      },
    },
  },
  import_demographic: {
    name: "Import Demographic",
    icon: "mdi-account-arrow-left",
    to: prefix + "/migration/demographic",
    isHide: true,
  },
  import_tracker: {
    name: "Import Tracker",
    icon: "mdi-shoe-print",
    to: prefix + "/migration/tracker",
    isHide: true,
  },
  import_consent_permission: {
    name: "Import Consent Permission",
    icon: "mdi-shield-plus",
    to: prefix + "/migration/consent",
    isHide: true,
  },
  data_flow: {
    name: "Data Flow",
    icon: "mdi-connection",
    to: prefix + "/migration/data-flow",
    isHide: true,
  },
  edit_data_flow: {
    name: "Edit Data Flow",
    icon: "mdi-connection",
    isHide: true,
  },
  data_flow_logs: {
    name: "Data Flow Logs",
    icon: "mdi-file-eye",
    isHide: true,
  },

  segment: {
    name: "Segments",
    icon: "mdi-chart-pie",
    order: 3,
    hasChildren: true,
    children: {
      campaigns: {
        name: "Segments",
        icon: "mdi-chart-pie",
        to: prefix + "/segments",
        permission_key: "segment_segments",
      },
    },
  },
  create_segment: {
    name: "Create Segments",
    icon: "mdi-chart-pie",
    isHide: true,
  },
  edit_segment: {
    name: "Edit Segments",
    icon: "mdi-chart-pie",
    isHide: true,
  },
  segment_insight: {
    name: "Segment Insight",
    icon: "mdi-chart-pie",
    isHide: true,
  },

  campaign: {
    name: "Campaigns",
    icon: "mdi-flag",
    hasChildren: true,
    order: 4,
    children: {
      campaigns: {
        name: "Campaigns",
        icon: "mdi-flag",
        to: prefix + "/campaigns",
        permission_key: "campaign_campaigns",
      },
    },
  },
  create_campaign: {
    name: "Create Campaign",
    icon: "mdi-flag",
    isHide: true,
  },
  edit_campaign: {
    name: "Edit Campaign",
    icon: "mdi-flag",
    isHide: true,
  },
  insight_campaign: {
    name: "Insight Campaign",
    icon: "mdi-flag",
    isHide: true,
  },

  // campaign_journey: {
  //   name: "Campaign Journeys",
  //   icon: "mdi-layers-triple",
  //   hasChildren: true,
  //   order: 5,
  //   children: {
  //     campaign_journeys: {
  //       name: "Campaign Journeys",
  //       icon: "mdi-layers-triple",
  //       to: prefix + "/campaign-journey",
  //       permission_key: "campaign-journey_campaign-journeys",
  //     },
  //   },
  // },
  // create_campaign_journey: {
  //   name: "Create Campaign Journey",
  //   icon: "mdi-layers-triple",
  //   isHide: true,
  // },
  // edit_campaign_journey: {
  //   name: "Edit Campaign Journey",
  //   icon: "mdi-layers-triple",
  //   isHide: true,
  // },
  // insights_campaign_journey: {
  //   name: "Campaign Journey Insight",
  //   icon: "mdi-layers-triple",
  //   isHide: true,
  // },

  customer_journey: {
    name: "Customer Journeys",
    icon: "mdi-layers-triple",
    hasChildren: true,
    order: 5,
    children: {
      customer_journeys: {
        name: "Customer Journeys",
        icon: "mdi-layers-triple",
        to: prefix + "/customer-journey",
        permission_key: "campaign-journey_campaign-journeys",
      },
    },
  },
  create_customer_journey: {
    name: "Create Customer Journey",
    icon: "mdi-layers-triple",
    isHide: true,
  },
  edit_customer_journey: {
    name: "Edit Customer Journey",
    icon: "mdi-layers-triple",
    isHide: true,
  },
  insights_customer_journey: {
    name: "Customer Journey Insight",
    icon: "mdi-layers-triple",
    isHide: true,
  },

  consents: {
    name: "Consents",
    icon: "mdi-shield-account",
    to: prefix + "/consents/consent-message",
    hasChildren: true,
    order: 6,
    children: {
      consent_message: {
        name: "Consent Message",
        icon: "mdi-account-group",
        to: prefix + "/consents/consent-message",
        permission_key: "pdpa_consent-message",
      },
    },
  },
  create_consent_message: {
    name: "Create Consent Message",
    icon: "mdi-shield-account",
    to: prefix + "/consent/consent-message/create",
    isHide: true,
  },
  edit_consent_message: {
    name: "Edit Consent Message",
    icon: "mdi-shield-account",
    isHide: true,
  },
  version_history_consent_message: {
    name: "Consent Message Version History",
    icon: "mdi-history",
    isHide: true,
  },
  history_detail_consent_message: {
    name: "Consent Message History Detail",
    icon: "mdi-history",
    isHide: true,
  },

  visualizations: {
    name: "Visualizations",
    icon: "mdi-chart-bar",
    to: prefix + "/visualizations/segments-funnel",
    isHide: !CONFIG.ENABLED_FEATURE_VISUALIZATIONS_FUNNELS,
    hasChildren: true,
    order: 7,
    children: {
      segments_funnel: {
        name: "Segments Funnel",
        icon: "mdi-chart-sankey-variant",
        to: prefix + "/visualizations/segments-funnel",
        permission_key: "visualizations_segments-funnel",
      },
      dynamic_report: {
        name: "Dynamic Report",
        icon: "mdi-file-chart",
        to: prefix + "/visualizations/dynamic-report",
        permission_key: "visualizations_dynamic-report",
      },
    },
  },
  segments_funnel: {
    name: "Segments Funnel",
    icon: "mdi-chart-sankey-variant",
    to: prefix + "/visualizations/segments-funnel",
    isHide: true,
  },
  create_segments_funnel: {
    name: "Create Funnel",
    icon: "mdi-chart-sankey-variant",
    isHide: true,
  },
  edit_segments_funnel: {
    name: "Edit Funnel",
    icon: "mdi-chart-sankey-variant",
    isHide: true,
  },
  dynamic_report: {
    name: "Dynamic Report",
    icon: "mdi-file-chart",
    to: prefix + "/visualizations/dynamic-report",
    isHide: true,
  },
  create_dynamic_report: {
    name: "Create Dynamic Report",
    icon: "mdi-file-chart",
    isHide: true,
  },
  create_dashboard: {
    name: "Create Dashboard",
    icon: "mdi-file-chart",
    isHide: true,
  },
  edit_dynamic_report: {
    name: "Edit Dynamic Report",
    icon: "mdi-file-chart",
    isHide: true,
  },

  affiliate: {
    name: "Affiliate",
    icon: "mdi-account-voice",
    isHide: !CONFIG.ENABLED_FEATURE_AFFILIATE,
    hasChildren: true,
    order: 8,
    children: {
      affiliate: {
        name: "Affiliate",
        icon: "mdi-account-voice",
        to: prefix + "/affiliates",
        permission_key: "tool_short-url",
      },
    },
  },

  landingpage: {
    name: "Landing Page",
    icon: "mdi-location-enter",
    isHide: !CONFIG.ENABLED_FEATURE_LANDINGPAGE,
    hasChildren: true,
    to: prefix + "/landingpages",
    group: prefix + "/landingpages",
    order: 8,
    children: {
      landingpage_landingpage: {
        name: "Landing Page",
        icon: "mdi-location-enter",
        to: prefix + "/landingpages",
      },
      landingpage_theme: {
        name: "Theme",
        icon: "mdi-palette",
        to: prefix + "/landingpages/themes",
      }
    },
  },
  landingpage_landingpage: {
    name: "Landing Page",
    icon: "mdi-location-enter",
    to: prefix + "/landingpages",
    isHide: true,
  },
  landingpage_theme: {
    name: "Landing Page Theme",
    icon: "mdi-palette",
    to: prefix + "/landingpages/themes",
    isHide: true,
  },
  create_landingpage_theme: {
    name: "Landing Page Theme",
    icon: "mdi-palette",
    isHide: true,
  },

  email_templates: {
    name: "Email Templates",
    icon: "mdi-email",
    to: prefix + "/email/templates",
    isHide: true,
  },
  create_email_template: {
    name: "Create Email Template",
    icon: "mdi-email",
    isHide: true,
  },
  edit_email_template: {
    name: "Edit Email Template",
    icon: "mdi-email",
    isHide: true,
  },
  attention_templates: {
    name: "Attention Templates",
    icon: "mdi-application",
    to: prefix + "/attention/templates",
    isHide: true,
  },
  create_attention_template: {
    name: "Create Attention Template",
    icon: "mdi-application",
    isHide: true,
  },
  edit_attention_template: {
    name: "Edit Attention Template",
    icon: "mdi-application",
    isHide: true,
  },
  roles: {
    name: "Roles",
    icon: "mdi-account-lock",
    to: prefix + "/roles",
    isHide: true,
  },
  create_role: {
    name: "Create Role",
    icon: "mdi-account-lock",
    isHide: true,
  },
  edit_role: {
    name: "Edit Role",
    icon: "mdi-account-lock",
    isHide: true,
  },
  edit_permission: {
    name: "Edit Permission",
    icon: "mdi-account-lock",
    isHide: true,
  },
  general_setting: {
    name: "General",
    icon: "mdi-shield-account",
    isHide: true,
  },
  domain_setting: {
    name: "Domain",
    icon: "mdi-shield-account",
    isHide: true,
  },
  tool: {
    name: "More Tools",
    icon: "mdi-menu",
    hasExpand: true,
    order: 8,
    expands: {
      users: {
        name: "Users",
        icon: "mdi-account-tie",
        to: prefix + "/users",
        permission_key: "tool_users",
      },
      templates: {
        name: "Email Templates",
        icon: "mdi-email",
        to: prefix + "/email/templates",
        permission_key: "tool_templates",
      },
      attention_templates: {
        name: "Attention Templates",
        icon: "mdi-application",
        to: prefix + "/attention/templates",
        permission_key: "tool_attention-templates",
      },
      event_parameters: {
        name: "Event Parameters",
        icon: "mdi-table-arrow-left",
        to: prefix + "/events/parameters",
        permission_key: "tool_templates",
      },
      master_language: {
        name: "Language",
        icon: "mdi-web",
        to: prefix + "/master/languages",
        permission_key: "tool_languages",
      },
      roles: {
        name: "Roles",
        icon: "mdi-account-lock",
        to: prefix + "/roles",
        permission_key: "tool_roles",
      },
      external_api: {
        name: "External API",
        icon: "mdi-application-cog",
        to: prefix + "/external-api",
        permission_key: "tool_external-api",
      },
      script_gallery: {
        name: "Query Plugin",
        icon: "mdi-toy-brick-plus",
        to: prefix + "/script-gallery",
        permission_key: "tool_external-api",
      },
      data_plugin: {
        name: "Data Plugin",
        icon: "mdi-toy-brick-plus-outline",
        to: prefix + "/data-plugins",
        permission_key: "tool_external-api",
      },
      tasks: {
        name: "Tasks Viewer",
        icon: "mdi-magnify-expand",
        to: prefix + "/tasks",
        permission_key: "",
      },
      tasks_graph: {
        name: "Tasks Graph",
        icon: "mdi-chart-timeline",
        to: prefix + "/tasks-graph",
        permission_key: "",
      },
      caches: {
        name: "Caches Viewer",
        icon: "mdi-cube-scan",
        to: prefix + "/caches",
        permission_key: "",
      },
      metrics: {
        name: "Metrics",
        icon: "mdi-chart-line",
        to: prefix + "/metrics",
        permission_key: "tool_settings",
      },
      pamtag: {
        name: "Tracking Script",
        icon: "mdi-file-code",
        to: prefix + "/pamtag",
        permission_key: "",
      },
      settings: {
        name: "Settings",
        icon: "mdi-cog",
        to: prefix + "/settings",
        permission_key: "tool_settings",
      },
    },
  },
  create_external_api: {
    name: "Create External API",
    icon: "mdi-application-cog",
    isHide: true,
  },
  edit_external_api: {
    name: "Edit External API",
    icon: "mdi-application-cog",
    isHide: true,
  },
  create_affiliate: {
    name: "Create Affiliate",
    icon: "mdi-account-tie",
    isHide: true,
  },
  edit_affiliate: {
    name: "Edit Affiliate URL",
    icon: "mdi-account-tie",
    isHide: true,
  },
  create_personalize_shorten_url: {
    name: "Create Personalize Shprten URL",
    icon: "mdi-application-cog",
    isHide: true,
  },
  edit_personalize_shorten_url: {
    name: "Edit Personalize Shorten URL",
    icon: "mdi-application-cog",
    isHide: true,
  },
  create_landingpage: {
    name: "Create Landing Page",
    icon: "mdi-account-tie",
    isHide: true,
  },
  edit_landingpage: {
    name: "Edit Landing Page",
    icon: "mdi-account-tie",
    isHide: true,
  },
  create_script_gallery: {
    name: "Create Script",
    icon: "mdi-toy-brick-plus",
    isHide: true,
  },
  edit_script_gallery: {
    name: "Edit Plugin",
    icon: "mdi-toy-brick-plus",
    isHide: true,
  },
  view_task: {
    name: "View Task",
    icon: "mdi-magnify-expand",
    isHide: true,
  },

  // suits: {
  //   name: "Suits",
  //   icon: "mdi-arrow-right-bold",
  //   to: prefix + "",
  //   group: prefix + "",
  //   hasChildren: true,
  //   order: 1,
  //   children: {
  //     cdp: {
  //       name: "CDP",
  //       icon: "",
  //       to: prefix + "/",
  //     },
  //     crm: {
  //       name: "CRM",
  //       icon: "",
  //       to: prefix + "/",
  //     },
  //     chat_center: {
  //       name: "Chat Center",
  //       icon: "",
  //       to: prefix + "/",
  //     },
  //     survey: {
  //       name: "Survey",
  //       icon: "",
  //       to: CONFIG.PLATFORM_SURVEY,
  //     },
  //   },
  // },
};
