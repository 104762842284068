import {_get} from "~/utils/lodash";

export default class PermissionHelper {
  static getSidebarKeys(permission: string): [string, string] {
    if (!permission) {
      return ['', '']
    }

    const keys = permission.split('_')
    const feature = _get(keys, '[1]', '')
    const menu = _get(keys, '[2]', '')

    if (feature === '' || menu === '') {
      return ['', '']
    }

    return [feature, menu]
  }
}
