
import {Component, Prop, Watch} from 'vue-property-decorator'
import {Base} from '~/core/Base'

@Component
export default class FormDialog extends Base {
  @Prop(Boolean) readonly value!: boolean
  @Prop(String) readonly dialogTitle!: string
  @Prop(String) readonly dialogCaption!: string
  @Prop(String) readonly className!: string
  @Prop({ default: '' }) readonly widthDialog!: string
  @Prop() readonly form!: any
  @Prop() readonly formProps!: any

  dialog = false

  @Watch('dialog')
  public onDialogChanged (val: boolean) {
    this.$emit('input', val)
  }

  @Watch('value')
  public onValueChanged (val: boolean) {
    this.dialog = val
  }
}
