
import { Component, Prop } from 'vue-property-decorator'
import {Base} from '~/core/Base'

@Component
export default class CSVPreview extends Base {
  @Prop() csv!: string[][]

  get columnNames(): string[] {
    if (!this.csv.length) {
      return []
    }
    return this.csv[0]
  }

  get rows(): string[][] {
    if (this.csv.length <= 1) {
      return []
    }
    return this.csv.slice(1)
  }
}
