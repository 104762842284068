import {Vue} from 'vue-property-decorator'
import AppRepository from '~/repositories/AppRepository'
import AuthRepository from '~/repositories/AuthRepository';
import {ArrayHelper} from '~/utils/ArrayHelper';
import {StringHelper} from '~/utils/StringHelper';
import {TimeHelper} from '~/utils/TimeHelper';
import { _get } from '~/utils/lodash';

export class Base extends Vue {
  $store!: any
  $refs!: any
  $auth!: AuthRepository
  $app!: AppRepository
  $cookies!: any
  $router!: any
  $vuetify!: any
  $toast!: any
  $papa!: any
  $msal!: any

  get $array() {
    return ArrayHelper
  }

  get $string() {
    return StringHelper
  }

  get $time() {
    return TimeHelper
  }

  capitalizedText(text: any): string {
    if (!text) {
      return ''
    }
    const str = `${text}`
    if (str.length > 2) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    }
    if (str.length > 1) {
      return str.charAt(0).toUpperCase()
    }
    return str
  }

  getAny(o:any, key: string): any {
    return _get(o, key, undefined)
  }

  getString(o:any, key: string): string {
    return _get(o, key, '')
  }
}

export class BaseApp extends Base {
}
