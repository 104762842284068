
import { Component, Prop } from "vue-property-decorator";
import BaseInput from "~/core/BaseInput";
import { _isEmpty, _isUndefined } from "~/utils/lodash";
import { StringHelper } from "~/utils/StringHelper";

@Component
export default class InputHidden extends BaseInput {
  onEmitEvent(ev) {
    this.$emit("event", ev);
  }
  onBlur(ev) {
    this.$emit("blur", ev);
  }
}
