
import { Component, Prop } from 'vue-property-decorator'
import BaseInput from '~/core/BaseInput'

@Component
export default class HTMLEditor extends BaseInput {
  @Prop({default: true}) headerDisplayStatus!: boolean
  @Prop({default: false}) isOptional!: boolean
  @Prop({default: 'snow'}) theme!: string
    
  get editorOption(): object {
    return {
      modules: {
        toolbar: [
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'color': [] }],
          ['bold', 'italic', 'underline'],
          [{ 'align': [] }],
          ['link'],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],         
        ],
      },
      placeholder: this.placeholder ? this.placeholder : '',
      theme: this.theme,
    }
  }
  
  onEmitEvent(ev: any) {
    this.$emit('event', ev)
  }
}
