
import {Base} from '~/core/Base'
import {Component, Watch} from 'vue-property-decorator'

@Component
export default class AppLoading extends Base {
  loadingDotTime: number | any = null
  loadingDotInterval: any = null

  get loadingDot(): string {
    let dots = ''
    for (let i = 0; i < this.loadingDotTime; i++) {
      dots += '.'
    }
    return dots
  }

  mounted() {
    this.loadingDotInterval = setInterval(() => {
      this.loadingDotTime++
    }, 500)
  }

  beforeDestroy() {
    if (this.loadingDotInterval) {
      clearInterval(this.loadingDotInterval)
    }
  }

  @Watch('loadingDotTime')
  onLoadingTimeChange(newValue: number) {
    if (this.loadingDotTime === 4) {
      this.loadingDotTime = 0
    }
  }
}
