import {commonObjectState} from "~/state/object";
import {IObjectState} from "~/state/types";
import {Vue} from 'vue-property-decorator'
import ObjectHelper from "~/utils/ObjectHelper";
import {NewRequester} from "~/core/Requester";

interface ICampaignJourneyState {
    campaignJourneyDetail: IObjectState<any>
    // sharedProperty: any;
}

export const prefix = 'campaignJourney/campaignJourney'

export const state = (): ICampaignJourneyState => ({
    campaignJourneyDetail: commonObjectState(),
    // sharedProperty: null,
})

export const getters = {
    getDetail: (state: any) => (): any => {
        return { ...(state.campaignJourneyDetail.data || {}) }
    },
    // getSharedProperty: (state) => state.sharedProperty,
}

export const mutations = {
    updateDetail: (state: ICampaignJourneyState, data) => {
        Vue.set(state, 'campaignJourneyDetail', data)
    },
    // setSharedProperty: (state, value) => {
    //     state.sharedProperty = value;
    // },
}

export const actions = {
    getDetail: async ({ commit, state }, data) => {
        const path = `updateDetail`
        try {
            commit(path, ObjectHelper.toLoadingStatus(state.campaignJourneyDetail))
            const response = await NewRequester.get<any>(data.url, { ...data })
            commit(path, ObjectHelper.toObjSuccessStatus(state.campaignJourneyDetail, response.data))
        } catch (e) {
            commit(path, ObjectHelper.toErrorStatus(state.campaignJourneyDetail, e.response?.data || e))
        }
    },
    // updateSharedProperty: ({ commit }, value) => {
    //     commit('setSharedProperty', value);
    // },
}
