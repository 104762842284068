import { Middleware } from '~/node_modules/@nuxt/types'

const notAuth: Middleware = (ctx) => {
  console.log("noAuth")
  if (ctx.app.$auth.isAuth && ctx.app.$cookies.get('me')?.token) {
    return ctx.redirect('/')
  }
}

export default notAuth
