
import { Component, Prop } from "vue-property-decorator";
import BaseInput from "~/core/BaseInput";
import { _isEmpty, _isUndefined } from "~/utils/lodash";
import { StringHelper } from "~/utils/StringHelper";

@Component
export default class InputText extends BaseInput {
  @Prop() isLoading?: boolean;
  @Prop() appnedOuterText?: string;
  @Prop() prependOuterText?: string;
  @Prop({ type: Object }) personalize?: {
    text?: string;
    pos?: number;
    databases?: string[];
    disabled?: boolean;
  };
  @Prop({ default: false }) isDeletable?: boolean;
  @Prop({ type: Object }) tooltip?: {
    text?: string;
    alignment?: number;
    iconColor?: string;
    tooltipColor?: string;
  };
  @Prop({ default: false }) readonly isReadonly!: boolean;
  @Prop({ default: false }) readonly enableCopyBtn!: boolean;

  copyAlert = false;
  alertTimeout = 1000;

  get isPersonalize(): boolean {
    return !(_isUndefined(this.personalize) || _isEmpty(this.personalize));
  }

  get isTooltip(): boolean {
    return (
      this.tooltip !== undefined &&
      this.tooltip.text != undefined &&
      this.tooltip.text.length > 0
    );
  }

  onEmitEvent(ev) {
    this.$emit("event", ev);
  }
  onBlur(ev) {
    this.$emit("blur", ev);
  }

  async clickCopy() {
    await StringHelper.copyToClipBoard(this.$props.value);
    this.copyAlert = true;
  }
}
