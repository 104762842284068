
import {Component, Watch, Prop} from 'vue-property-decorator'
import BaseInput from '~/core/BaseInput'
import {v4 as uuidV4} from 'uuid';
import { API_TYPE } from '~/constants/apiTypes'
import { Core } from '~/core/Core'
import { _debounce, _isEmpty, _isUndefined } from '~/utils/lodash'
import { ObjectLoader } from '~/loader/object'

@Component
export default class ImageExternal extends BaseInput {
  @Prop(Boolean) readonly disabled!: boolean
  @Prop() readonly keyID!: string

  created() {
    this.imageLoader.initial()
  }

  progress = 0
  imageURL = this.value
  id = uuidV4()
  localImageURL = ''

  get classes() {
    return {
      'image-showing': !!this.imageURL,
      'disabled': this.disabled,
      'active-box-border': !this.disabled
    }
  }

  get boxClasses() {
    return {
      'box-inside-border': this.isDisplayInput,
    }
  }

  get theme () {
    return {
      '--primary': this.$vuetify.theme.themes.light.primary,
    }
  }

  onClick(e) {
    this.progress = 0
  }

  get isDisplayInput() {
    return !this.imageURL
  }

  onSelfInput(file) {
    this.localImageURL = URL.createObjectURL(file)
    this.onUploadFile(file)
  }

  onInputClick() {
    document.getElementById(this.id)?.click()
  }

  onUploadFile(file) {
    let formData = new FormData()
    formData.append('file', file, file?.name)
    this.imageLoader.$add(formData, {onUploadProgress: this.onUploadProgress})
  }

  onUploadProgress(e: any) {
    this.progress = Math.round(100 * e.loaded / e.total)
  }

  @Watch('value')
  onUpdateImageURL() {
    this.imageURL = this.value
  }

  get stateKey(): string {
    return `external_images_upload_${this.keyID}`
  } 

  get imageLoader(): ObjectLoader {
    return new ObjectLoader(this, (data: any) => ({
      stateKey: this.stateKey,
      apiType: API_TYPE.MKT,
      baseURL: Core.MKTAPI(`/`),
      baseAdd: Core.MKTAPI(`/external-api/uploader`),
      callback: {
        onUploadProgress: data?.options?.onUploadProgress
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onAddSuccess: (newValue) => {
        const url = this.imageLoader.addItem.url
        this.imageURL = url
        this.onInput(url)
      },
    }))
  }
}
