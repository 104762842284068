
import {Component, Watch} from 'vue-property-decorator'
import {BaseApp} from '~/core/Base'
import AppRepository from '~/repositories/AppRepository'
import Header from './components/header.vue'
import {ObjectLoader} from '~/loader/object';
import {ILoaderOption} from '~/loader/types';
import {API_TYPE} from '~/constants/apiTypes';
import {Core} from '~/core/Core';
import AppLoading from '~/layouts/components/AppLoading.vue';
import {LocalStorageHelper} from '~/utils/LocalStorageHelper';
import ADToken from "~/plugins/ad-token";

@Component({
  middleware: ['auth'],
  components: {AppLoading, Header},
  head() {
    const app = new AppRepository(this as any)
    return {
      title: app.state.app.doc.title
    }
  }
})
export default class App extends BaseApp {
  isInitial = false

  async created() {
    this.authLoader.initial()

  }

  async mounted() {
    // Initial app
    this.isInitial = false

    if (this.$auth.enabledFeatureLoginADToken && this.$auth.isNotAuth && !this.$cookies.get('me')?.token) {
      await ADToken.loginADToken(this)
    }

    const me = LocalStorageHelper.get('me')
    if (me?.token) {
      this.$app.saveToState('me', me)
      await this.authLoader.$get('')
    } else {
      this.$auth.logout(this);
    }

    await new Promise(resolve => setTimeout(resolve, 400))
    this.isInitial = true
  }

  get authLoader(): ObjectLoader<{ permissions: string[], token: string }> {
    return new ObjectLoader(this, (data?: any): ILoaderOption => ({
      apiType: API_TYPE.ECOM,
      baseURL: Core.MKTBACKENDAPI('/me'),
      baseFind: Core.MKTBACKENDAPI('/me'),
      stateKey: 'me',

      onFindSuccess: (newValue) => {
        const permissions: object = {}
        for (let i = 0; i < this.authLoader.findItem.permissions.length; i++) {
          permissions[this.authLoader.findItem.permissions[i]] = true
        }
        this.$app.saveToState('me', {
          permissions: permissions
        })
        LocalStorageHelper.set('me', {
          ...LocalStorageHelper.get('me'),
          ...this.authLoader.findItem
        })
      },
      onFindError: newValue => {
        this.$router.push({
          path: '/login',
        })
      },
    }))
  }

  get theme() {
    return {
      '--primary-color': this.$vuetify.theme.themes.light.primary,
      '--secondary-color': this.$vuetify.theme.themes.light.secondary,
      '--background-color': this.$vuetify.theme.themes.light.background,
      '--border-color': this.$vuetify.theme.themes.light.border,
      '--accent-color': this.$vuetify.theme.themes.light.accent,
    }
  }

  platformClass(): string {
    return this.$app.state.app.doc.platform + '-platform'
  }

  @Watch('$app.state.app.doc.platform')
  onPlatformChange() {
    this.$app.initialTheme()
  }
}
