
import { Base } from "~/core/Base";
import { Component, Prop, Provide } from "vue-property-decorator";
import { IFormOption, INPUT_TYPES, FunctionMap } from "~/components/form/FormTypes";

@Component
export default class FieldCondition extends Base {
  @Prop(Object) readonly option!: IFormOption;
  @Prop(Object) readonly value!: any;

  get componentValue(): any {
    return this.value[this.option.props?.name || '']
  }

  get componentEventHandlers(): FunctionMap {
    return { ...this.option.props?.on }
  }

  isDivider(): boolean {
    return this.option.type === INPUT_TYPES.DIVIDER
  }

  isHiddenField(): boolean {
    return this.option.type === INPUT_TYPES.HIDDEN
  }

  isTransformScript(): boolean {
    return this.option.type === INPUT_TYPES.TRANSFORM_SCRIPT
  }

  isRandomTokenGenderator(): boolean {
    return this.option.type === INPUT_TYPES.RANDOM_TOKEN_STRING_GENERATOR
  }

  isButtonGroup(): boolean {
    return this.option.type === INPUT_TYPES.BUTTON_GROUP
  }

  isInputButtonGroup(): boolean {
    return this.option.type === INPUT_TYPES.INPUT_BUTTON_GROUP
  }

  isTextField(): boolean {
    return this.option.type === INPUT_TYPES.TEXT
  }

  isFileField(): boolean {
    return this.option.type === INPUT_TYPES.FILE
  }

  isImageFileField(): boolean {
    return this.option.type === INPUT_TYPES.IMAGE
  }

  isImageExternalField(): boolean {
    return this.option.type === INPUT_TYPES.IMAGE_EXTERNAL
  }

  isAliasField(): boolean {
    return this.option.type === INPUT_TYPES.ALIAS
  }

  isPasswordField(): boolean {
    return this.option.type === INPUT_TYPES.PASSWORD
  }

  isTextarea(): boolean {
    return this.option.type === INPUT_TYPES.TEXTAREA
  }

  isJSONEditor(): boolean {
    return this.option.type === INPUT_TYPES.JSONEDITOR
  }

  isSelect(): boolean {
    return this.option.type === INPUT_TYPES.SELECT
  }

  isAutoComplete(): boolean {
    return this.option.type === INPUT_TYPES.AUTOCOMPLETE
  }

  isComboBox(): boolean {
    return this.option.type === INPUT_TYPES.COMBOBOX
  }

  isCheckbox(): boolean {
    return this.option.type === INPUT_TYPES.CHECKBOOK
  }

  isRadio(): boolean {
    return this.option.type === INPUT_TYPES.RADIO
  }

  isSwitchToggle(): boolean {
    return this.option.type === INPUT_TYPES.SWITCH_TOGGLE
  }

  isDatePicker(): boolean {
    return this.option.type === INPUT_TYPES.DATEPICKER
  }

  isDateTimePicker(): boolean {
    return this.option.type === INPUT_TYPES.DATETIMEPICKER
  }

  isTimePicker(): boolean {
    return this.option.type === INPUT_TYPES.TIMEPICKER
  }

  isUploadImage(): boolean {
    return this.option.type === INPUT_TYPES.UPLOAD_IMAGE
  }

  isColorPickerS(): boolean {
    return this.option.type === INPUT_TYPES.COLOR_PICKER_S
  }

  isHTMLEditor(): boolean {
    return this.option.type === INPUT_TYPES.HTMLEDITOR
  }

  isSelection(): boolean {
    return this.option.type === INPUT_TYPES.SELECTION
  }

  isCopyTextField(): boolean {
    return this.option.type === INPUT_TYPES.COPY_TEXT_FIELD
  }

  isComponent(): boolean {
    return this.option.type === INPUT_TYPES.COMPONENT
  }
}
