
import { Component, Prop, Watch } from "vue-property-decorator";
import BaseInput from "~/core/BaseInput";

@Component
export default class ComboBox extends BaseInput {
  @Prop() isLoading?: boolean;
  @Prop({
    default() {
      return (values) => {
        // If multiple is enabled, the values is an array type.
        // On the other hand, the single item will become in string or object form.
        // New input value is string. Option value is object.
        if (typeof values === "string") {
          return values;
        } else if (typeof values === "object" && !Array.isArray(values)) {
          return values?.value;
        } else {
          return values?.map((v) =>
            typeof v === "string" ? { label: v, value: v } : v
          );
        }
      };
    },
  })
  transformF!: any;

  onSelfInput(value) {
    const newValues = this.transformF(value);
    this.onInput(newValues);
  }
}
