
import {Component, Prop} from 'vue-property-decorator'
import {Base} from '~/core/Base'

@Component
export default class PageHeader extends Base {
  @Prop() readonly headers!: any[]
  @Prop() readonly classContainer!: string

  getColsClasses(cols): string {
    return cols === 0 ? '' : cols
  }

  alignClass(align) {
    if (align === undefined) {
      return 'text-left'
    }
    return 'text-' + align
  }

  addNewClass(newClass) {
    if (newClass === undefined) {
      return ''
    }
    return newClass
  }
}
